import {arrayMove} from '@dnd-kit/sortable'

export function handleDragOver(event: any, setTasks: any) {
  const {active, over} = event
  if (!over) return

  const activeId = active.id
  const overId = over.id

  if (activeId === overId) return

  const isActiveATask = active.data.current?.type === 'Task'
  const isOverATask = over.data.current?.type === 'Task'

  if (!isActiveATask) return

  if (isActiveATask && isOverATask) {
    setTasks((tasks: any) => {
      const activeIndex = tasks.findIndex((t: any) => t.ticketID === activeId)
      const overIndex = tasks.findIndex((t: any) => t.ticketID === overId)

      if (tasks[activeIndex].columnID !== tasks[overIndex].columnID) {
        tasks[activeIndex].columnID = tasks[overIndex].columnID
        return arrayMove(tasks, activeIndex, overIndex - 1)
      }

      return arrayMove(tasks, activeIndex, overIndex)
    })
  }

  const isOverAColumn = over.data.current?.type === 'Column'

  if (isActiveATask && isOverAColumn) {
    setTasks((tasks: any) => {
      const activeIndex = tasks.findIndex((t: any) => t.ticketID === activeId)
      const newColumnId = overId
      tasks[activeIndex].columnID = newColumnId
      return arrayMove(tasks, activeIndex, activeIndex)
    })
  }
}
