import {useContext, useState} from 'react'
import CustomFieldsModal from '../modal/customFields/CustomFieldsModal'
import ProjectsSettingsSearchComponent from './ProjectsSettingsSearchComponent'
import { ProjectsCustomFieldsPageContext } from '../../context'
import usePermission from '../../../../hook/usePermission'

const ProjectsCustomFieldsHeader = () => {
  const {hasPermission} = usePermission()
  const [openCustomFieldModal, setOpenCustomFieldModal] = useState(false)
  const {onSearch, isLoadingGlobalCustomFieldOperation} = useContext(ProjectsCustomFieldsPageContext)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div className='d-flex align-items-center'>
        <ProjectsSettingsSearchComponent onSearch={onSearch} />
        {isLoadingGlobalCustomFieldOperation && (
          <button className='p-3 border-0 outline-0 h-100 d-flex justify-content-center align-items-center bg-light rounded rounded-2'>
            <span className='text-primary spinner-border spinner-border-sm align-middle'></span>
          </button>
        )}
      </div>
      {hasPermission('projects_settings', 'write') && (
        <button
          type='button'
          onClick={() => setOpenCustomFieldModal(true)}
          className='btn btn-primary'
        >
          <i className='fa-solid fa-plus'></i>Add Field
        </button>
      )}
      <CustomFieldsModal
        show={openCustomFieldModal}
        customFieldData={''}
        onClose={() => setOpenCustomFieldModal(false)}
      />
    </div>
  )
}

export default ProjectsCustomFieldsHeader
