import {useContext, useState} from 'react'
import Loading from '../../../../loading'
import ModuleActions from '../../actionsOverlayDropdowns/ModuleActionsOverlay'
import DndTable from '../../../../../components/dndTable/DndTable'
import ModuleModal from '../../modal/boardSettings/modules/ModuleModal'
import {BoardSettingsPageContext} from '../../../context'
import usePermission from '../../../../../hook/usePermission'

export default function ModulesTable() {
  const {hasPermission} = usePermission()
  const [openModuleModal, setOpenModuleModal] = useState(false)
  const {modulesList, isLoadingModuleListing, updateModuleTableSorting} =
    useContext(BoardSettingsPageContext)

  const handleUpdatedTableRows = (tableData: any) => {
    const moduleTableRowOrder = {
      module_ids: tableData.map((item: any) => item.id),
    }
    updateModuleTableSorting(moduleTableRowOrder)
  }

  const formattedData = modulesList?.map((module: any) => {
    const tableData = [
      {
        columnName: 'NAME',
        headerClass: 'w-100 min-w-120px',
        columnData: module.moduleName
      },
      {
        columnName: 'CARDS',
        headerClass: 'min-w-100px',
        columnData: module.cards,
      },
    ]

    if (hasPermission('projects_all projects', 'write')) {
      tableData.push({
        columnName: 'ACTION',
        headerClass: 'w-80px text-center',
        columnData: <ModuleActions moduleId={module.moduleId} moduleData={module} />,
      });
    }

    return {
      id: module.moduleId,
      tableData: tableData,
    };
  })

  return (
    <div className='position-relative'>
      {isLoadingModuleListing && <Loading />}
      <DndTable
        data={formattedData}
        isLoading={isLoadingModuleListing}
        handleUpdatedTableRows={handleUpdatedTableRows}
        isDraggable= {hasPermission('projects_all projects', 'write')}
      />
      {hasPermission('projects_all projects', 'write') && (
        <div className='d-flex justify-content-end'>
          {!isLoadingModuleListing && (
            <button
              type='button'
              onClick={() => setOpenModuleModal(true)}
              className='btn btn-primary'
            >
              <i className='fa-solid fa-plus'></i>Add Module
            </button>
          )}
          <ModuleModal
            show={openModuleModal}
            moduleData={''}
            onClose={() => setOpenModuleModal(false)}
          />
        </div>
      )}
    </div>
  )
}
