import {useState} from 'react'
import useToastify from '../../../../hook/useToastify'
import {useParams} from 'react-router-dom'
import useGetAdditionalInfoCFieldsList from './useGetAdditionalInfoCFieldsList'
import {updateTicketCFieldValue} from '../../service'

function useAdditionalInfoCFiendOperations() {
  const params = useParams()
  const projectId = params?.['projectId'] as string
  const cardId = params?.['cardId'] as string
  const {toastMessage} = useToastify()
  const {refetchAdditionalInfoCFieldsList} = useGetAdditionalInfoCFieldsList()
  const [isLoadingTicketCFieldOperation, setIsLoadingTicketCFieldOperation] = useState(false)

  async function editTicketCFieldValue(ticketCFieldData: any) {
    setIsLoadingTicketCFieldOperation(true)
    const res: any = await updateTicketCFieldValue(projectId, cardId, ticketCFieldData)
    if (res.status === 200) {
      toastMessage('success', 'Ticket updated successfully')
      setIsLoadingTicketCFieldOperation(false)
      refetchAdditionalInfoCFieldsList()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoadingTicketCFieldOperation(false)
    }
  }

  return {
    editTicketCFieldValue,
    isLoadingTicketCFieldOperation,
  }
}

export default useAdditionalInfoCFiendOperations
