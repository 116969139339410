import useToastify from '../../../../hook/useToastify'
import {addModule, editModule, updateTableSorting, removeModule} from '../../service'
import {useAppSelector} from '../../../../redux/useTypedSelector'
import {ProjectsMainPageContext} from '../../context'
import {useContext, useState} from 'react'
import useGetModuleListing from './useGetModuleListing'

function useModulesOperations() {
  const {toastMessage} = useToastify()
  const {user} = useAppSelector((state) => state.auth)
  const {projectId} = useContext(ProjectsMainPageContext)
  const {refetchModules} = useGetModuleListing()
  const [isLoading, setIsLoading] = useState(false)

  async function createModule(data: any) {
    setIsLoading(true)
    const res: any = await addModule(projectId, {
      module_name: data['module_name'],
      is_archived: false,
    })
    if (res.status === 200) {
      toastMessage('success', `Module created successfully`)
      setIsLoading(false)
      refetchModules()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function updateModule(moduleId: string, data: any) {
    setIsLoading(true)
    const res: any = await editModule(projectId, moduleId, {
      name: data['module_name'],
      updated_by: user['username'],
    })
    if (res.status === 200) {
      toastMessage('success', `Updated successfully`)
      setIsLoading(false)
      refetchModules()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function updateModuleTableSorting(moduleTableRowOrder: any) {
    setIsLoading(true)
    const tableName = 'modules'
    const res: any = await updateTableSorting(projectId, tableName, moduleTableRowOrder)
    if (res.status === 200) {
      toastMessage('success', `Module updated successfully`)
      setIsLoading(false)
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function deleteModule(moduleId: string) {
    setIsLoading(true)
    const res: any = await removeModule(projectId, moduleId)
    if (res.status === 200) {
      toastMessage('success', `Module deleted successfully`)
      setIsLoading(false)
      refetchModules()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  return {
    createModule,
    updateModule,
    updateModuleTableSorting,
    deleteModule,
    isLoadingModulesOperation: isLoading,
  }
}

export default useModulesOperations
