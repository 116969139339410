import {capitalizeFirstLetter} from '../../../utils/string'

export const dataToColumnsListing = (data: any) => {
  if (!data) {
    return []
  }

  return data.map((column: any) => {
    return {
      columnId: column['column_id'],
      columnName: capitalizeFirstLetter(column['name']) || '-',
      cards: column['cards'] || 0,
      description: column['description']
    }
  })
}
