import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useContext} from 'react'
import {HideProductPagesContext, HideVariantPagesContext} from '../../contexts'

const DeleteUserConfirmationModal = ({show, onClose, sku, is_product}: any) => {
  const {deleteVariantRule, isLoading: isOperationLoading} = useContext(HideVariantPagesContext)
  const {deleteProductRule, isLoading} = useContext(HideProductPagesContext)
  if (!show) {
    return null
  }
  const handleDelete = async () => {
    if (is_product) {
      const res: any = await deleteProductRule(sku)
      if (res?.status === 200) {
        onClose()
      }
    } else {
      const res: any = await deleteVariantRule(sku)
      if (res?.status === 200) {
        onClose()
      }
    }

    onClose()
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_trigger_build'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
      onHide={onClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>Are you sure ?</h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <p>
            Do you really want to delete this? <br></br>This action is irreversible.
          </p>
        </div>

        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-outline btn-active-light-primary'
            onClick={onClose}
          >
            No
          </button>
          <button type='button' className='btn btn-primary'>
            {(!isLoading && !isOperationLoading) && (
              <span className='indicator-label' onClick={handleDelete}>
                Yes
              </span>
            )}

            {(isLoading || isOperationLoading) && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export {DeleteUserConfirmationModal}
