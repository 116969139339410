import {useState} from 'react'
import useToastify from '../../../../hook/useToastify'
import {addComment, removeComment, updateComment} from '../../service'
import {useParams} from 'react-router-dom'
import useGetCommentsList from './useGetCommentsList'

function useTicketCommentOperations() {
  const params = useParams()
  const cardId = params?.['cardId'] as string
  const {toastMessage} = useToastify()
  const {refetchCommentsListData} = useGetCommentsList()
  const [isLoadingCommentOperation, setIsLoadingCommentOperation] = useState(false)

  async function createComment(commentData: any) {
    setIsLoadingCommentOperation(true)
    const res: any = await addComment(cardId, commentData)
    if (res.status === 200) {
      toastMessage('success', 'Comment added successfully')
      setIsLoadingCommentOperation(false)
      refetchCommentsListData()
    } else {
      toastMessage('error', `${res.data.message}`)
    }
    return res
  }

  async function editComment(commentId: any, commentData: any) {
    setIsLoadingCommentOperation(true)
    const res: any = await updateComment(cardId, commentId, commentData)
    if (res.status === 200) {
      toastMessage('success', 'Comment updated successfully')
      setIsLoadingCommentOperation(false)
      refetchCommentsListData()
    } else {
      toastMessage('error', `${res.data.message}`)
    }
  }

  async function deleteComment(commentId: any) {
    setIsLoadingCommentOperation(true)
    const res: any = await removeComment(cardId, commentId)
    if (res.status === 200) {
      toastMessage('success', 'Comment deleted successfully')
      setIsLoadingCommentOperation(false)
      refetchCommentsListData()
    } else {
      toastMessage('error', `${res.data.message}`)
    }
  }
  return {
    createComment,
    editComment,
    deleteComment,
    isLoadingCommentOperation,
  }
}

export default useTicketCommentOperations
