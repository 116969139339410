import {useEffect} from 'react'
import useFilters from '../../../../hook/useFilters'
import {useQuery} from 'react-query'
import {getAllColumns} from '../../service'
import {dataToColumnsListing} from '../../utils/column'
import { useParams } from 'react-router-dom'

const initialFilters = {}

const useGetAllColumns = () => {

  const params = useParams()
  const projectId = params?.['projectId'] as string
  const {filters} = useFilters(initialFilters)

  const {
    data: columnList,
    isFetching,
    refetch,
  } = useQuery(`get-all-columns`, () => {
    return getAllColumns(projectId, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  return {
    columns: columnList?.['data'] ? dataToColumnsListing(columnList['data']) : [],
    isLoadingColumns: isFetching,
    refetchColumns: refetch,
  }
}

export default useGetAllColumns
