import {useQuery} from 'react-query'
import {getTicketPriorities} from '../../service'
import {dataToCardPriorityOptions} from '../../utils/agile-board'

const useGetTicketPriorites = () => {
  const {
    data: ticketPriorities,
    isFetching,
    refetch,
  } = useQuery(`get-ticket-priority`, () => {
    return getTicketPriorities()
  })

  return {
    ticketPriorities: ticketPriorities?.['data']
      ? dataToCardPriorityOptions(ticketPriorities['data'])
      : [],
    isLoadingTicketPriorities: isFetching,
    refetchTicketPriorities: refetch,
  }
}

export default useGetTicketPriorites
