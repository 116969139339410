import {createContext} from 'react'

export const ProjectsCustomFieldsPageContext = createContext({
  globalCustomFieldsList: [],
  isLoadingGlobalCustomFieldList: false,
  refetchGlobalCustomFieldsList: () => {},
  onSearch: (e: string) => {},

  createGlobalCustomField: (customFieldDetails: any) => {},
  updateGlobalCustomField: (cFieldId: string, customFieldDetails: any) => {},
  updateGlobalCustomFieldTableSorting: (customFieldsTableRowOrder: any) => {},
  deleteGlobalCustomField: (cFieldId: string) => {},
  isLoadingGlobalCustomFieldOperation: false,
})


export const ProjectsDefaultColumnsPageContext = createContext({
  globalDefaultColumnsList: [],
  isLoadingGlobalDefaultColumnsList: false,
  refetchGlobalDefaultColumnsList: () => {},
  onSearch: (e: string) => {},

  createGlobalDefaultColumn: (defaultColumnDetails: any) => {},
  updateGlobalDefaultColumn: (defaultColumnId: string, defaultColumnDetails: any) => {},
  updateGlobalDefaultColumnsTableSorting: (defaultColumnsTableRowOrder: any) => {},
  deleteGlobalDefaultColumn:(defaultColumnId: string) => {},
  isLoadingGlobalDefaultColumnOperation: false,
})


export const ProjectsMembersPageContext = createContext({
  globalProjectsMembersDropdownList: [],
  isLoadingGlobalProjectsMembersDropdownList: false,
  refetchProjectMembersDropdownList: () => {},

  globalProjectsMembersList: [],
  isLoadingGlobalProjectsMembersList: false,
  refetchGlobalProjectsMembersList: () => {},
  onSearch: (e: string) => {},

  addGlobalProjectMember: (globalProjectMemberDetails:any) => {},
  removeGlobalProjectMember: (globalMemberId: string) => {},
  isLoadingGlobalProjectsMembersOperation: false,
})

