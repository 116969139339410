import {useQuery} from 'react-query'
import {getAllAdditionalInfoCFieldsList} from '../../service'
import {useParams} from 'react-router-dom'

const useGetAdditionalInfoCFieldsList = () => {
  const params = useParams<any>()
  const projectId: any = params['projectId']
  const cardId: any = params['cardId']

  const {
    data: AdditionalInfoCFieldsList,
    isFetching,
    refetch,
  } = useQuery(`get-additional-info-custom-fields`, () => {
    return getAllAdditionalInfoCFieldsList(projectId, cardId)
  })

  return {
    additionalInfoCFieldsList: AdditionalInfoCFieldsList?.['data']
      ? AdditionalInfoCFieldsList['data']
      : [],
    isLoadingAdditionalInfoCFieldsList: isFetching,
    refetchAdditionalInfoCFieldsList: refetch,
  }
}

export default useGetAdditionalInfoCFieldsList
