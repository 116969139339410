const TableRow = ({row, keys, actionComponent, register, checkBoxComponent}: any) => {
  return (
    <tr key={row?.id}>
      {checkBoxComponent && <td>{checkBoxComponent(row)}</td>}
      {keys?.map((key: any, index: any) => (
        <td key={index}>
          {row?.hasOwnProperty(key) ? (row[key] !== '' ? row[key] ?? '-' : '-') : '-'}
        </td>
      ))}
      {actionComponent && <td>{actionComponent(row, register)}</td>}
    </tr>
  )
}

export default TableRow
