export const calculateTotalWidth = (div:any) => {
    // Content Width
    const contentWidth = div.offsetWidth;
  
    // Padding
    const padding =
      parseInt(window.getComputedStyle(div).paddingLeft) +
      parseInt(window.getComputedStyle(div).paddingRight);
    const totalWidthWithPadding = contentWidth + padding;
  
    // Border
    const border =
      parseInt(window.getComputedStyle(div).borderLeftWidth) +
      parseInt(window.getComputedStyle(div).borderRightWidth);
    const totalWidthWithBorder = totalWidthWithPadding + border;
  
    // Overflow
    const overflow = totalWidthWithBorder - div.clientWidth;
    const totalWidthWithOverflow = totalWidthWithBorder + overflow;
  
    return totalWidthWithOverflow;
  };