import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useContext, useEffect, useState} from 'react'
import Select from '../../../../components/Select'
import {useForm} from 'react-hook-form'
import {ProjectsListPageContext} from '../../context'
import {getModifiedInputFields} from '../../utils/getModifiedInputFields'
import {inputFieldsValidation} from '../../utils/inputFieldsValidation'
import {extractProjectId} from '../../utils/extractProjectId'

interface CreateProjectDialog {
  show: boolean
  onClose: () => void
  modalTitle: string
  projectModalDetails: any
}

const ProjectsDetailsModal = ({
  show,
  onClose,
  modalTitle,
  projectModalDetails,
}: CreateProjectDialog) => {
  const {handleSubmit, reset} = useForm()
  const {
    isLoading,
    isCreating,
    isUpdating,
    updateProjectDetails,
    createNewProjectDetails,
    businessUnitsList,
  } = useContext(ProjectsListPageContext)

  const initialFieldValues = {
    name: '',
    description: '',
    bu_id: '',
    project_initials: '',
  }

  useEffect(() => {
    if (isLoading) {
      onClose()
      reset(initialFieldValues)
    }
    //eslint-disable-next-line
  }, [isLoading, onClose, reset])

  const [fieldValues, setFieldValues] = useState(initialFieldValues)
  const [selectedOption, setSelectedOption] = useState('')
  const [isModified, setIsModified] = useState(false)
  const [isProjectIdValid, setIsProjectIdValid] = useState(true)
  const [projectIdError, setProjectIdError] = useState('')

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value)
    setIsModified(true)
  }

  const handleInputChange = (field: string, value: string) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [field]: field === 'project_initials' ? value.toUpperCase() : value,
    }))
    setIsModified(true)
    if (field === 'project_initials') {
      if (value.length >= 2 && value.length <= 4) {
        setProjectIdError('')
      } else {
        setProjectIdError('Project ID must be between 2 and 4 characters.')
      }
    }
  }

  const handleInputBlur = () => {
    if (fieldValues.name) {
      const projectId = extractProjectId(fieldValues.name)
      setFieldValues((prevValues) => ({
        ...prevValues,
        project_initials: projectId,
      }))
    } else {
      setFieldValues((prevValues) => ({
        ...prevValues,
        project_initials: '',
      }))
    }
  }

  useEffect(() => {
    setFieldValues({
      name: projectModalDetails ? projectModalDetails.name : '',
      description: projectModalDetails ? projectModalDetails.description : '',
      bu_id: projectModalDetails ? projectModalDetails.business_unit_id : '',
      project_initials: projectModalDetails ? projectModalDetails.project_initials : '',
    })
    setSelectedOption(projectModalDetails ? projectModalDetails.business_unit_id : '')
    setIsModified(false)
  }, [projectModalDetails, show])

  const handleUpdateDetails = () => {
    if (isModified) {
      if (fieldValues.project_initials.length < 2 || fieldValues.project_initials.length > 4) {
        setIsProjectIdValid(false)
        return
      }

      const modifiedFields = getModifiedInputFields(initialFieldValues, fieldValues, selectedOption)
      if (projectModalDetails) {
        updateProjectDetails(modifiedFields, projectModalDetails.id)
      } else {
        createNewProjectDetails(modifiedFields)
      }
    }
  }

  const isSaveButtonDisabled = inputFieldsValidation(fieldValues, selectedOption, isModified)

  if (!show) {
    return null
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_stacked_1'
      data-backdrop='static'
      tabIndex={-1}
      show={show}
      dialogClassName='modal-1'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{modalTitle}</h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <form onSubmit={handleSubmit(handleUpdateDetails)} className='modal-body'>
          <div className='mb-10'>
            <label htmlFor={`project_name`} className='required form-label'>
              Project Name
            </label>
            <input
              id={`project_name`}
              type='text'
              className='form-control'
              placeholder='Enter Name'
              value={fieldValues.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              maxLength={64}
              onBlur={handleInputBlur}
            />
            <div
              className={`d-flex justify-content-end me-1 mt-2 ${
                fieldValues.name.length >= 40 ? 'd-flex' : 'd-none'
              } ${fieldValues.name.length === 64 ? 'text-danger' : 'text-muted'}`}
            >
              {`${fieldValues.name.length}/64`}
            </div>
          </div>
          <div className='mb-10'>
            <label htmlFor={`description`} className='required form-label'>
              Description
            </label>
            <textarea
              id={`description`}
              className='form-control resize-none h-75px'
              placeholder='Enter Description'
              onChange={(e) => handleInputChange('description', e.target.value)}
              value={fieldValues.description}
              maxLength={156}
            />
            <div
              className={`d-flex justify-content-end me-1 mt-2 ${
                fieldValues.description.length >= 120 ? 'd-flex' : 'd-none'
              } ${fieldValues.description.length === 156 ? 'text-danger' : 'text-muted'}`}
            >
              {`${fieldValues.description.length}/156`}
            </div>
          </div>
          <div className='mb-10'>
            <Select
              name='business-unit'
              value={selectedOption}
              data-placeholder='Select option'
              label='Business Unit'
              options={[
                {label: 'Select business unit', value: ''},
                ...businessUnitsList.map((businessUnit: any) => ({
                  label: businessUnit.business_unit_name,
                  value: businessUnit.id,
                })),
              ]}
              isRequired={true}
              onChange={handleOptionChange}
            />
          </div>
          <div className='mb-10'>
            <label htmlFor={`project_initials`} className='required form-label'>
              Project ID (Min Two Characters)
            </label>
            <input
              id={`project_initials`}
              type='text'
              className='form-control'
              value={fieldValues.project_initials}
              onChange={(e) => {
                handleInputChange('project_initials', e.target.value)
              }}
              placeholder='Enter project ID'
              disabled={projectModalDetails}
            />
            {!isProjectIdValid && <div className='text-danger'>{projectIdError}</div>}
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-outline btn-active-light-primary'
              onClick={() => {
                onClose()
                reset(initialFieldValues)
              }}
            >
              Cancel
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              disabled={isSaveButtonDisabled}
              onClick={() => reset(initialFieldValues)}
            >
              {projectModalDetails ? (
                <span className='indicator-label'>
                  {!isUpdating && <span className='indicator-label'>Update</span>}

                  {isUpdating && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Updating...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </span>
              ) : (
                <span className='indicator-label'>
                  {!isCreating && <span className='indicator-label'>Create</span>}

                  {isCreating && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Creating...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ProjectsDetailsModal
