import {useQuery} from 'react-query'
import {getAllModules} from '../../service'
import {dataToModuleOptions} from '../../utils/agile-board'
import {useParams} from 'react-router-dom'

const useGetAllModuleOptions = () => {
  const params = useParams<any>()
  const projectId: any = params['projectId']

  const {
    data: modules,
    isFetching,
    refetch,
  } = useQuery(`get-all-modules-options`, () => {
    return getAllModules(projectId, {})
  })

  return {
    modulesOptions: modules?.['data'] ? dataToModuleOptions(modules['data']) : [],
    isLoadingAllModuleOptions: isFetching,
    refetchAllModuleOptions: refetch,
  }
}

export default useGetAllModuleOptions
