import {createContext} from 'react'

export const ProjectsMainPageContext = createContext({
  projectStatus: '',
  projectName: '',
  projectId: '',
})

export const AgileBoardPageContext = createContext({
  agileBoardData: [],
  isLoadingBoardData: false,
  refetchBoardData: () => {},

  agileBoardStructure: [],
  isLoadingBoardStructure: false,
  refetchBoardStructure: () => {},

  createTicket: (moduleId: string, data: any) => {},
  updateTicket: (data: any) => {},
  updateTicketDescription: (data: any) => {},
  isLoadingTicketOperation: false,

  updateAgileBoard: (updatedTaskDetails: any) => {},
  isUpdatingAgileBoard: false,
})

export const projectMembersPageContext = createContext({
  AddNewProjectMember: (memberDetails: any) => {},
  isAddingProjectMember: false,

  projectMembersDropdownList: [],
  isLoadingProjectMembersDropdownList: false, 
  refetchProjectMembersDropdownList: () => {},

  projectMembersList: [],
  isLoadingMemberDetails: false,
  filters: {},
  onSearchMember: (e: string) => {},
  refetchMembersList: () => {},

  removeProjectMember: (memberID: number) => {},
  isRemovingProjectMember: false,
})

export const BoardSettingsPageContext = createContext({
  createColumn: (data: any) => {},
  updateColumn: (moduleId: string, data: any) => {},
  updateColumnTableSorting: (columnTableRowOrder: any) => {},
  deleteColumn: (moduleId: string) => {},
  isLoadingColumnOperation: false,

  createCustomField: (customFieldDetails: any) => {},
  updateCustomField: (cFieldId: string, customFieldDetails: any) => {},
  updateCustomFieldTableSorting: (customFieldsTableRowOrder: any) => {},
  deleteCustomField: (cFieldId: string) => {},
  isLoadingCustomFieldOperation: false,

  columns: [],
  isLoadingColumns: false,
  refetchColumns: () => {},

  customFieldsList: [],
  isLoadingCustomFieldList: false,
  refetchCustomFieldsList: () => {},

  modulesList: [],
  isLoadingModuleListing: false,
  refetchModules: () => {},

  createModule: (data: any) => {},
  updateModule: (moduleId: string, data: any) => {},
  updateModuleTableSorting: (moduleTableRowOrder: any) => {},
  deleteModule: (moduleId: string) => {},
  isLoadingModulesOperation: false,
})

export const TicketDetailsPageContext = createContext({
  projectStatus: '',
  projectName: '',
  projectId: '',
  moduleId: '',
  cardId: '',

  modulesOptions: [],
  isLoadingAllModuleOptions: false,
  refetchAllModuleOptions: () => {},

  kanbanstages: [],
  isLoadingKanbanStage: false,
  refetchKanbanStage: () => {},

  ticketDetails: [],
  isLoadingTicketDetails: false,
  refetchTicketDetails: () => {},

  ticketPriorities: [],
  isLoadingTicketPriorities: false,
  refetchTicketPriorities: () => {},

  ticketStatus: [],
  isLoadingTicketStatus: false,
  refetchTicketStatus: () => {},

  createComment: (commentData: any) => {},
  editComment: (commentId: any, data: any) => {},
  deleteComment: (commentId: any) => {},
  isLoadingCommentOperation: false,

  additionalInfoCFieldsList: [],
  isLoadingAdditionalInfoCFieldsList: false,
  refetchAdditionalInfoCFieldsList: () => {},

  commentsListData: [],
  isLoadingCommentsListData: false,
  refetchCommentsListData: () => {},

  editTicketCFieldValue: (ticketCFieldData: any) => {},
  isLoadingTicketCFieldOperation: false,
})
