export function extractProjectId(value:any) {
    const trimmedValue = value.trim();
    const words = trimmedValue.split(' ');
    let projectId = '';
 
    if (words.length === 1 && words[0].length > 0) {
      projectId = words[0].slice(0, 4).toUpperCase();
    } else if (words.length >= 2) {
      if (words[1].length === 1) {
        projectId = `${words[0].slice(0, 3)}${words[1].charAt(0)}`.toUpperCase();
      } else {
        projectId = `${words[0].slice(0, 2)}${words[1].slice(0, 2)}`.toUpperCase();
      }
    }
  
    return projectId;
  }
  