import {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {hideProductValidationSchema} from '../../validations'
import {yupResolver} from '@hookform/resolvers/yup'
import useProductRules from '../../hooks/useHideProducts'
import MultipleSelect from '../../../../../components/MultiSelect'
import {dataToMultiSelectOptions} from '../../../../analyticsReplenishment/utils'
import {dataToMultiSelectCategory} from '../../utils'
import {formDataToSave} from '../../utils'
import 'flatpickr/dist/themes/material_green.css'
import Flatpickr from 'react-flatpickr'

const ProductRuleForm = ({
  onClose,
  isEdit,
  sku,
  thresholdQty,
  promotion_id,
  promotion_disable_date,
  hide_out_of_stock_days,
  hide_category_id,
  hide_classification,
  hide_product,
  prefix,
  modalData,
  customer_rep,
  disable_promotion,
}: any) => {
  const [isChecked, setIsChecked] = useState(hide_product === 'False' ? false : true)
  const [disablePromotion, setDisablePromotion] = useState(
    disable_promotion === 'False' ? false : true
  )
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedRepresentative, setSelectedRepresentative] = useState([])

  const defaultValues = {
    product_sku: sku || '',
    promotion_threshold_quantity: thresholdQty || '',
    promotion_id: promotion_id || '',
    promotion_disable_date: promotion_disable_date || '',
    hide_product: isChecked,
    hide_customer_rep: selectedRepresentative,
    hide_out_of_stock_days: hide_out_of_stock_days || '',
    hide_product_name_prefix: prefix || '',
    hide_category_id: selectedCategories,
    hide_classification: hide_classification || '',
    disable_promotion: disablePromotion,
  }

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
  } = useForm({defaultValues, resolver: yupResolver(hideProductValidationSchema)})

  const {addProductRule, updateProductRule, isLoading} = useProductRules()

  const onSubmit = async (data: any) => {
    const formatedData = formDataToSave(data)
    if (isEdit) {
      await updateProductRule(formatedData)
    } else {
      await addProductRule(formatedData)
    }
    onClose()
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const handlePromotionHide = () => {
    setDisablePromotion(!disablePromotion)
  }

  useEffect(() => {
    if (hide_category_id && modalData?.categories) {
      const hideCategories = hide_category_id.split(';')
      const categoriesToSelect = modalData?.categories
        .filter((category: any) => hideCategories.includes(category.id.toString()))
        .map((category: any) => ({value: category.id, label: category.name}))
      setSelectedCategories(categoriesToSelect)
    }
    if (customer_rep && modalData?.admin_user) {
      const custoreRep = customer_rep.split(';')
      const repsToSelect = modalData?.admin_user
        .filter((user: any) => custoreRep.includes(user.toString()))
        .map((user: any) => ({value: user, label: user}))
      setSelectedRepresentative(repsToSelect)
    }
  }, [hide_category_id, modalData?.categories, customer_rep, modalData?.admin_user])
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <>
            <div>
              <div className='mb-8'>
                <label className='form-label required'>Parent Product SKU</label>
                <input
                  type='text'
                  id='product_sku'
                  defaultValue={sku || ''}
                  disabled={isEdit}
                  className={`form-control ${errors?.product_sku?.message ? 'is-invalid' : ''}`}
                  {...register('product_sku')}
                />
                <div className='form-error invalid-feedback'>
                  {errors?.product_sku?.message && <>{errors?.product_sku?.message}</>}
                </div>
              </div>
            </div>
            <div className='mt-5 mb-5'>
              <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='disable_promotion'
                  value=''
                  checked={disablePromotion}
                  {...register('disable_promotion')}
                  onChange={handlePromotionHide}
                />
                <label className='form-label form-label ms-3 mb-0'>Disable Promotion</label>
              </div>
            </div>
            {disablePromotion ? (
              <>
                <div>
                  <div className='mb-8'>
                    <label className='form-label'>Stock Threshold (Available qty)</label>
                    <input
                      type='number'
                      id='promotion_threshold_quantity'
                      className={`form-control ${
                        errors?.promotion_threshold_quantity?.message ? 'is-invalid' : ''
                      }`}
                      {...register('promotion_threshold_quantity')}
                    />
                    <div className='form-error invalid-feedback'>
                      {errors?.promotion_threshold_quantity?.message && (
                        <>{errors?.promotion_threshold_quantity?.message}</>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='mb-8'>
                    <label className='form-label'>Disable Promotion(BigCommerce)</label>
                    <Controller
                      control={control}
                      name='promotion_id'
                      render={({field: {name, value, onChange}}) => (
                        <select
                          className={`form-select ${errors?.promotion_id ? 'is-invalid' : ''}`}
                          data-kt-select2='true'
                          data-placeholder='Select Promotion'
                          data-allow-clear='true'
                          defaultValue={''}
                          name={name}
                          value={value}
                          onChange={(e: any) => {
                            onChange(e?.target?.value)
                          }}
                        >
                          <option value=''>Select promotion</option>
                          {modalData &&
                            modalData?.['promotions'] &&
                            modalData?.['promotions'].map((promotion: any) => (
                              <option value={promotion['id']}>{promotion['name']}</option>
                            ))}
                        </select>
                      )}
                    />

                    <div className='form-error invalid-feedback'>
                      {errors?.promotion_id && <span>{String(errors?.promotion_id?.message)}</span>}{' '}
                      {/* Update this line */}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='mb-8'>
                    <label className='form-label'>Date</label>
                    <Controller
                      control={control}
                      name='promotion_disable_date'
                      render={({field: {name, value, onChange}}) => (
                        <Flatpickr
                          className={`form-control`}
                          name={name}
                          value={value}
                          onChange={([date]: any) => {
                            onChange(date)
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div className='mb-5'>
              <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='hide_product'
                  value=''
                  // checked={watch('hide_product')}
                  checked={isChecked}
                  {...register('hide_product')}
                  onChange={handleCheckboxChange}
                />
                <label className='form-label form-label ms-3 mb-0'>Hide Product</label>
              </div>
            </div>
            {isChecked ? (
              <div>
                <div className='mb-5'>
                  <h4 className='fw-bold text-dark'>Notify Sales Representative</h4>
                </div>
                <div className='mb-8'>
                  <label className='form-label'>Representative Name</label>
                  <Controller
                    name='hide_customer_rep'
                    control={control}
                    defaultValue={selectedRepresentative}
                    render={({field: {value, onChange}}) => (
                      <MultipleSelect
                        options={dataToMultiSelectOptions(modalData, 'admin_user')}
                        handleSelected={onChange}
                        defaultValues={selectedRepresentative}
                      />
                    )}
                  />
                </div>

                <div className='mb-8'>
                  <label className='form-label'>Out of Stock Days</label>
                  <input
                    type='number'
                    id='hide_out_of_stock_days'
                    className={`form-control ${
                      errors?.hide_out_of_stock_days?.message ? 'is-invalid' : ''
                    }`}
                    {...register('hide_out_of_stock_days')}
                  />
                  {/* <div className='form-error invalid-feedback'>
                      {errors?.hide_out_of_stock_days?.message && <>{errors?.hide_out_of_stock_days?.message}</>}                
                    </div> */}
                </div>
                <div className='mb-8'>
                  <label className='form-label '>Update Category</label>
                  <Controller
                    name='hide_category_id'
                    control={control}
                    defaultValue={selectedCategories}
                    render={({field: {value, onChange}}) => (
                      <MultipleSelect
                        options={dataToMultiSelectCategory(modalData, 'categories')}
                        handleSelected={onChange}
                        defaultValues={selectedCategories}
                      />
                    )}
                  />
                </div>
                <div className='mb-5'>
                  <h4 className='fw-bold text-dark'>SKU Vault Setting</h4>
                </div>
                <div className='mb-8'>
                  <label className='form-label'>Classification</label>
                  <Controller
                    control={control}
                    name='hide_classification'
                    render={({field: {name, value, onChange}}) => (
                      <select
                        className='form-select'
                        data-kt-select2='true'
                        data-placeholder='Select Classification'
                        data-allow-clear='true'
                        defaultValue={''}
                        name={name}
                        value={value}
                        onChange={(e: any) => {
                          onChange(e?.target?.value)
                        }}
                      >
                        <option value=''>Select Classification</option>
                        {modalData &&
                          modalData?.['classification'] &&
                          modalData?.['classification'].map((classification: any) => (
                            <option value={classification}>{classification}</option>
                          ))}
                      </select>
                    )}
                  />
                </div>
                <div>
                  <label className='form-label'>Product Name Prefix</label>
                  <input
                    type='text'
                    id='hide_product_name_prefix'
                    className={`form-control ${
                      errors?.hide_product_name_prefix?.message ? 'is-invalid' : ''
                    }`}
                    {...register('hide_product_name_prefix')}
                  />
                </div>
              </div>
            ) : null}
          </>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-outline btn-active-light-primary'
            onClick={onClose}
          >
            Cancel
          </button>
          <button type='submit' className='btn btn-primary'>
            {isLoading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : isEdit ? (
              <span className='indicator-label'>update</span>
            ) : (
              <span className='indicator-label'>Save</span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default ProductRuleForm
