import { useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import ProjectsDetailsModal from './ProjectsDetailsModal'
import {useLocation} from 'react-router-dom'
import ProjectArchiveConfirmationModal from './ProjectArchiveConfirmationModal'

const OverlayModal = ({projectDetails}: any) => {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [ModalProjectDetails, setModalProjectDetails] = useState(null)
  const location = useLocation()

  const isPathActive = location.pathname === '/projects/active'

  const handleEditClick = () => {
    setIsModalOpen(true)
    document.body.click()
  }

  const handleArchiveproject = (projectDetails:any) => {
    setIsDeleteModalOpen(true)
    setModalProjectDetails(projectDetails)
    document.body.click()
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <div className=' d-flex align-items-start flex-column'>
            <button className='btn btn-active-text-primary' onClick={handleEditClick}>
              Edit
            </button>
            <button
              onClick={() => handleArchiveproject(projectDetails)}
              className={`btn btn-active-text-${isPathActive ? 'danger' : 'primary'}`}
            >
              {isPathActive ? 'Archive' : 'Unarchive'}
            </button>
          </div>
        }
      />

      {isModalOpen && (
        <ProjectsDetailsModal
          modalTitle={'Edit Project Details'}
          show={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          projectModalDetails={projectDetails}
        />
      )}
      
      <ProjectArchiveConfirmationModal
        show={isDeleteModalOpen}
        onClose={() => {setIsDeleteModalOpen(false); setModalProjectDetails(null)}}
        projectDetails={ModalProjectDetails}
      />
    </>
  )
}

export default OverlayModal
