import {useContext} from 'react'
import {projectMembersPageContext} from '../../../context'
import MemberPageTableRow from './MemberPageTableRow'
import Loading from '../../../../loading'
import NoDataFound from '../../../../../components/NoDataFound'
import usePermission from '../../../../../hook/usePermission'

export default function MemberPageTable() {
  const {hasPermission} = usePermission()
  const {projectMembersList, isLoadingMemberDetails} = useContext(projectMembersPageContext)

  return (
    <div className='card-body p-0'>
      <div className='table-responsive position-relative'>
        <div className='table-loader-wrapper'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-15'>
            <thead className='table-row-bordered'>
              <tr className='fs-6 fw-semibold text-muted'>
                <th className='min-w-250px'>{'NAME'}</th>
                <th className='min-w-150px'>{'SYSTEM ROLE'}</th>
                <th className='min-w-200px'>{'CREATED AT'}</th>
                {hasPermission('projects_all projects', 'write') && (
                  <th className='w-80px'>{'ACTION'}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {projectMembersList.map((row: any) => (
                <MemberPageTableRow key={row['member_id']} row={row} />
              ))}
              {!isLoadingMemberDetails && projectMembersList.length === 0 && (
                <NoDataFound colspan={4} />
              )}
            </tbody>
          </table>
          {isLoadingMemberDetails && <Loading />}
        </div>
      </div>
    </div>
  )
}
