import useToastify from '../../../../hook/useToastify'
import {useState} from 'react'
import useGetGlobalCustomFieldsList from './useGetGlobalCustomFieldsList'
import {
  addGlobalCustomField,
  editGlobalCustomField,
  removeGlobalCustomField,
  updateCustomFieldTableSorting,
} from '../../services'

function useGlobalCustomFieldsOperations() {
  const {toastMessage} = useToastify()
  const {refetchGlobalCustomFieldsList} = useGetGlobalCustomFieldsList()
  const [isLoading, setIsLoading] = useState(false)

  async function createGlobalCustomField(customFieldDetails: any) {
    setIsLoading(true)
    const res: any = await addGlobalCustomField(customFieldDetails)
    if (res.status === 200) {
      toastMessage('success', `Custom Field created successfully`)
      refetchGlobalCustomFieldsList()
      setIsLoading(false)
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function updateGlobalCustomField(cFieldId: string, customFieldDetails: any) {
    setIsLoading(true)
    const res: any = await editGlobalCustomField(cFieldId, customFieldDetails)
    if (res.status === 200) {
      toastMessage('success', `Custom Field updated successfully`)
      setIsLoading(false)
      refetchGlobalCustomFieldsList()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function updateGlobalCustomFieldTableSorting(customFieldsTableRowOrder: any) {
    setIsLoading(true)
    const tableName = 'custom-fields'
    const res: any = await updateCustomFieldTableSorting(tableName, customFieldsTableRowOrder)
    if (res.status === 200) {
      toastMessage('success', `Updated successfully`)
      setIsLoading(false)
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function deleteGlobalCustomField(cFieldId: string) {
    setIsLoading(true)
    const res: any = await removeGlobalCustomField(cFieldId)
    if (res.status === 200) {
      toastMessage('success', `Custom Field deleted successfully`)
      setIsLoading(false)
      refetchGlobalCustomFieldsList()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  return {
    createGlobalCustomField,
    updateGlobalCustomField,
    updateGlobalCustomFieldTableSorting,
    deleteGlobalCustomField,
    isLoadingGlobalCustomFieldOperation: isLoading,
  }
}

export default useGlobalCustomFieldsOperations
