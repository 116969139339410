import {useEffect} from 'react'
import useFilters from '../../../../hook/useFilters'
import {useQuery} from 'react-query'
import {getAllDefaultColumns} from '../../services'

const initialFilters = {
  page: 1,
  limit: 10,
  search_value: '',
}

const useGetGlobalDefaultColumnsList = () => {
  const {filters, setSingleFilter, setMultipleFilters} = useFilters(initialFilters)

  const {
    data: globalDefaultColumnsList,
    isFetching,
    refetch,
  } = useQuery(`get-all-global-default-columns`, () => {
    return getAllDefaultColumns(filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onSearch = (e: string) => {
    setMultipleFilters({
      search_value: e,
      page: 1,
    })
  }

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  return {
    globalDefaultColumnsList: globalDefaultColumnsList?.['data']
      ? globalDefaultColumnsList['data']
      : [],
    isLoadingGlobalDefaultColumnsList: isFetching,
    refetchGlobalDefaultColumnsList: refetch,
    pagination: globalDefaultColumnsList?.['meta']?.['pagination'] || {},
    onSearch,
    onPageChange,
  }
}

export default useGetGlobalDefaultColumnsList
