import {useContext} from 'react'
import Search from '../../../../components/Search'
import {projectMembersPageContext} from '../../context'

const MemberPageSearchComponent = () => {
  const {onSearchMember} = useContext(projectMembersPageContext)

  return (
    <div>
      <Search
        onSearch={(searchTerm: string) => {
          onSearchMember && onSearchMember(searchTerm)
        }}
      />
    </div>
  )
}

export {MemberPageSearchComponent}
