import {useContext} from 'react'
import {ActiveProjectsSearchComponent} from './ActiveProjectsSearchComponent'
import { ProjectsListPageContext } from '../../context'

const ArchivePageHeader = () => {
  const {isAdding} = useContext(ProjectsListPageContext)

  return (
      <div className='d-flex align-items-center mb-10'>
        <ActiveProjectsSearchComponent />
        {isAdding && (
          <button className='p-3 border-0 outline-0 h-100 d-flex justify-content-center align-items-center bg-light rounded rounded-2'>
            <span className='text-primary spinner-border spinner-border-sm align-middle'></span>
          </button>
        )}
      </div>
  )
}

export {ArchivePageHeader}
