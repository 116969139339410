import {Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {useContext, useEffect} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ProjectsDefaultColumnsPageContext} from '../../../context'

interface DefaultColumnsModalTypes {
  show: boolean
  editId?: number | string | undefined | null
  onClose: () => void
  defaultColumnDetails: any
}

const DefaultColumnsModal = ({
  show,
  onClose,
  editId,
  defaultColumnDetails,
}: DefaultColumnsModalTypes) => {
  const {
    isLoadingGlobalDefaultColumnsList,
    createGlobalDefaultColumn,
    updateGlobalDefaultColumn,
    isLoadingGlobalDefaultColumnOperation,
  } = useContext(ProjectsDefaultColumnsPageContext)

  useEffect(() => {
    if (isLoadingGlobalDefaultColumnsList) {
      onClose()
    }
  }, [isLoadingGlobalDefaultColumnsList, onClose])

  const defaultValues = {
    name: defaultColumnDetails ? defaultColumnDetails.name : '',
    description: defaultColumnDetails ? defaultColumnDetails.description : '',
  }

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm({
    defaultValues: defaultValues,
  })

  if (!show) {
    return null
  }

  const onSubmit = async (data: any) => {
    const defaultColumnDetailsPayload = {
      ...data,
      is_archived: false,
    }
    if (editId) {
      await updateGlobalDefaultColumn(`${editId}`, data)
    } else {
      await createGlobalDefaultColumn(defaultColumnDetailsPayload)
      reset(defaultValues)
    }
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_stacked_1'
      data-backdrop='static'
      tabIndex={-1}
      show={show}
      dialogClassName='modal-1'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{editId ? 'Edit' : 'Add'} Default Column</h4>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => {
              onClose()
              reset(defaultValues)
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-body'>
            <div className='mb-8'>
              <label htmlFor={`default-column-name`} className='required form-label'>
                Column Name
              </label>
              <input
                id={'default-column-name'}
                type='text'
                className='form-control'
                placeholder='Type Column Name'
                {...register('name', {required: true})}
              />
              {errors.name && <span className='text-danger'>Column name is required</span>}
            </div>

            <div>
              <label htmlFor={`default-column-name`} className='required form-label'>
                Column Description
              </label>
              <input
                id={'module-description'}
                type='text'
                className='form-control'
                placeholder='Type Description'
                {...register('description', {required: true})}
              />
              {errors.description && <span className='text-danger'>Description is required</span>}
            </div>
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-outline btn-active-light-primary'
              onClick={() => {
                onClose()
                reset(defaultValues)
              }}
            >
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              {!isLoadingGlobalDefaultColumnOperation && (
                <span className='indicator-label'>{editId ? 'Update' : 'Add'}</span>
              )}

              {isLoadingGlobalDefaultColumnOperation && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {editId ? 'Updating...' : 'Adding...'}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default DefaultColumnsModal
