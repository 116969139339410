import useToastify from '../../../hook/useToastify'
import {getProjectMembersList} from '../service'
import {useEffect, useState} from 'react'

const useGetProjectMembersList = (projectId: string) => {
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const {toastMessage} = useToastify()

  useEffect(() => {
    const fetchData = async (projectId: any) => {
      try {
        setLoading(true)
        if (projectId) {
          const apiResponse = await getProjectMembersList(`agile/projects/${projectId}/members`)

          if (apiResponse.status === 200) {
            setData(apiResponse?.['data']?.['data'] || [])
            setLoading(false)
          }

          if (apiResponse.status !== 200) {
            toastMessage('error', `${apiResponse.data.message}`)
            setLoading(false)
          }
        }
      } catch (error) {
        setLoading(false)
      }
    }

    fetchData(projectId)
    //eslint-disable-next-line
  }, [projectId])

  return {
    projectMembersList: data,
    isLoadingMemberDetails: isLoading,
  }
}

export default useGetProjectMembersList
