import {KTCard} from '../../../_metronic/helpers'
import UserTable from '../sections/UserTable'
import {UserContext} from '../context'
import useUsers from '../hooks/useUsers'
import useGetUserList from '../hooks/useGetUserList'
import useMeta from '../../../hook/useMeta'

export default function UserCreate() {
  useMeta('Users')

  const {addUser, deleteUser, updateUser, isLoading} = useUsers()
  const {users, onSearch, isFetchingUsers, pagination, onPageChange,onSortingChange,filters} = useGetUserList(false)

  const contextValue = {
    users,
    isLoading,
    isFetchingUsers,
    addUser,
    updateUser,
    deleteUser,
    onSearch,
    pagination,
    onPageChange,
    onSortingChange,
    filters
  }

  return (
    <UserContext.Provider value={contextValue}>
      <KTCard>
        <UserTable />
      </KTCard>
    </UserContext.Provider>
  )
}
