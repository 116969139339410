import {useContext, useState} from 'react'
import useToastify from '../../../../hook/useToastify'
import {submitUpdatedAgileBoardDetails} from '../../service'
import {ProjectsMainPageContext} from '../../context'

export default function useUpdateAgileBoard() {
  const {projectId} = useContext(ProjectsMainPageContext)

  const {toastMessage} = useToastify()
  const [isLoading, setLoading] = useState(false)

  async function updateAgileBoard(updatedTaskDetails: any) {
    const taskId = updatedTaskDetails.activeTaskId
    const updatedAgileBoardDetails = {
      module_id: updatedTaskDetails.newModuleId,
      current_column_id: updatedTaskDetails.newColumnId,
    }

    try {
      setLoading(true)
      const response = await submitUpdatedAgileBoardDetails(
        `/agile/projects/${projectId}/cards/${taskId}`,
        updatedAgileBoardDetails
      )
      if (response.status === 200) {
        toastMessage('success', `Updated successfully`)
        setLoading(false)
      } else {
        toastMessage('error', `No changes`)
        setLoading(false)
      }
      return response
    } catch (error: any) {}
  }

  return {
    updateAgileBoard,
    isUpdatingAgileBoard: isLoading,
  }
}
