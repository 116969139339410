import Select from 'react-select'
import {dataToAdminUserOptions} from '../../../../../utils/adminuser'
import {Controller, useFormContext} from 'react-hook-form'
import {useContext, useEffect, useState} from 'react'
import {TicketDetailsPageContext} from '../../../context'
import useTicketOperations from '../../../hooks/agileBoard/useTicketOperations'
import { useGetProjectMembersDropdownList } from '../../../hooks/members/useGetProjectMembersDropdownList'

function AssigneeListDropdown() {
  const {projectMembersDropdownList, isLoadingProjectMembersDropdownList} = useGetProjectMembersDropdownList()
  const formMethods = useFormContext()
  const adminUserOptions = dataToAdminUserOptions(projectMembersDropdownList)
  const {updateTicket, isLoadingTicketOperation} = useTicketOperations()
  const {ticketDetails, isLoadingTicketDetails}: any = useContext(TicketDetailsPageContext)
  const [defaultOption, setDefaultOption] = useState()
  const [isDefaultOptionLoading, setIsDefaultOptionLoading] = useState(true)

  useEffect(() => {
    if (!isLoadingProjectMembersDropdownList && !isLoadingTicketDetails) {
      const defaultModuleOption =
        adminUserOptions.find((option: any) => option.value === ticketDetails['assignee']) ??
        undefined
      setDefaultOption(defaultModuleOption)
      setIsDefaultOptionLoading(false)
    }
    // eslint-disable-next-line
  }, [isLoadingProjectMembersDropdownList, isLoadingTicketDetails])

  const handleChange = (selectedOption: any) => {
    if (selectedOption && ticketDetails?.length !== 0) {
      updateTicket({assigned_to: selectedOption.value})
    }
  }

  return !isLoadingProjectMembersDropdownList && !isLoadingTicketDetails && !isDefaultOptionLoading ? (
    <Controller
      name='assignee'
      control={formMethods.control}
      defaultValue={defaultOption}
      render={({field}) => (
        <Select
          {...field}
          className={`react-select-styled`}
          options={adminUserOptions}
          isLoading={isLoadingTicketOperation}
          placeholder='Select assignee'
          onChange={(selectedOption) => {
            field.onChange(selectedOption)
            handleChange(selectedOption)
          }}
        />
      )}
    />
  ) : (
    <Select
      placeholder='Select assignee'
      isLoading={!adminUserOptions?.length || isLoadingProjectMembersDropdownList}
    />
  )
}

export default AssigneeListDropdown
