import {Pagination} from '../../../utils/pagination'
import MemberPageTable from '../components/table/memberTables/MemberPageTable'
import {useGetMembersList} from '../hooks/members/useGetMembersList'
import {projectMembersPageContext} from '../context/index'
import {MemberPageHeader} from '../components/header/MemberPageHeader'
import useAddNewProjectMember from '../hooks/members/useAddNewProjectMember'
import useRemoveProjectMember from '../hooks/members/useRemoveProjectMember'
import { useGetProjectMembersDropdownList } from '../hooks/members/useGetProjectMembersDropdownList'

function Members() {
  const {
    projectMembersList,
    isLoadingMemberDetails,
    pagination,
    onSearchMember,
    onPageChange,
    filters,
    refetchMembersList,
  }: any = useGetMembersList()
  const {AddNewProjectMember, isAddingProjectMember}: any = useAddNewProjectMember()
  const {removeProjectMember, isRemovingProjectMember}: any = useRemoveProjectMember()
  const {projectMembersDropdownList, isLoadingProjectMembersDropdownList, refetchProjectMembersDropdownList} = useGetProjectMembersDropdownList()

  const contextValue = {
    AddNewProjectMember,
    isAddingProjectMember,

    projectMembersDropdownList, 
    isLoadingProjectMembersDropdownList, 
    refetchProjectMembersDropdownList,

    projectMembersList,
    isLoadingMemberDetails,
    filters,
    onSearchMember,
    refetchMembersList,

    removeProjectMember,
    isRemovingProjectMember,
  }

  return (
    <>
      <projectMembersPageContext.Provider value={contextValue}>
        <MemberPageHeader />
        <MemberPageTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </projectMembersPageContext.Provider>
    </>
  )
}

export default Members
