import useToastify from '../../../../hook/useToastify'
import {createCard, updateCard} from '../../service'
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useGetAgileBoardStructure} from './useGetAgileBoardStructure'
import {useGetAgileBoardData} from './useGetAgileBoardData'

export default function useTicketOperations() {
  const params: any = useParams<any>()
  const projectId: any = params?.projectId
  const cardId: any = params?.cardId
  const {refetchBoardStructure} = useGetAgileBoardStructure()
  const {refetchBoardData} = useGetAgileBoardData()

  const {toastMessage} = useToastify()
  const [isLoadingTicketOperation, setIsLoadingTicketOperation] = useState(false)

  async function createTicket(moduleId: string, data: any) {
    setIsLoadingTicketOperation(true)
    const res: any = await createCard(projectId, moduleId, data)
    if (res.status === 200) {
      toastMessage('success', 'Issue created successfully')
      setIsLoadingTicketOperation(false)
      refetchBoardStructure()
      refetchBoardData()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoadingTicketOperation(false)
    }
  }

  async function updateTicket(data: any) {
    setIsLoadingTicketOperation(true)
    const res: any = await updateCard(projectId, cardId, data)
    if (res.status === 200) {
      toastMessage('success', 'Ticket updated successfully')
      setIsLoadingTicketOperation(false)
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoadingTicketOperation(false)
    }
  }

  async function updateTicketDescription(data: any) {
    setIsLoadingTicketOperation(true)
    const res: any = await updateCard(projectId, cardId, data)
    if (res.status === 200) {
      toastMessage('success', 'Ticket updated successfully')
      setIsLoadingTicketOperation(false)
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoadingTicketOperation(false)
    }
  }

  return {
    createTicket,
    updateTicket,
    updateTicketDescription,
    isLoadingTicketOperation,
  }
}
