import {KTCard} from '../../../../_metronic/helpers'
import {ProductRuleHeader} from './header/ProductRuleHeader'
import ProductRuleTable from './table/ProductRuleTable'
import useGetProductRules from '../hooks/useGetProductRules'
import useProductRules from '../hooks/useHideProducts'
import { Pagination } from '../../../../utils/pagination'
import { HideProductPagesContext } from '../contexts'

function ProductContent() {
  const {addProductRule, deleteProductRule, updateProductRule,isLoading} = useProductRules()

  const {productRules, onPageChange, pagination, onSearch, onSortingChange, isFetching, filters, refetch} =
    useGetProductRules()

  const contextValue: any = {
    productRules: productRules,
    onPageChange,
    onSearch,
    onSortingChange,
    addProductRule,
    deleteProductRule,
    updateProductRule,
    pagination,
    isFetching,
    filters,
    refetch,
    isLoading
  }

  return (
    <HideProductPagesContext.Provider value={contextValue}>
      <KTCard>
        <ProductRuleHeader productRules={productRules} />
        <ProductRuleTable />
        {/* <ProductRulePagination /> */}
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </KTCard>
    </HideProductPagesContext.Provider>
  )
}

export default ProductContent
