import {Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useContext, useEffect} from 'react'
import {BoardSettingsPageContext} from '../../../../context'

interface AddNewModuleModal {
  show: boolean
  editId?: number | string | undefined | null
  onClose: () => void
  moduleData: any
}

const createSchema = yup.object().shape({
  module_name: yup.string().required('Module Name is required'),
})

const ModuleModal = ({show, onClose, editId, moduleData}: AddNewModuleModal) => {
  const {isLoadingModuleListing, createModule, updateModule, isLoadingModulesOperation} =
    useContext(BoardSettingsPageContext)

  useEffect(() => {
    if (isLoadingModuleListing) {
      onClose()
    }
  }, [isLoadingModuleListing, onClose])

  const defaultValues = {
    module_name: moduleData ? moduleData.moduleName : '',
  }

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(createSchema),
  })

  if (!show) {
    return null
  }

  const onSubmit = async (data: any) => {
    if (editId) {
      await updateModule(`${editId}`, data)
    } else {
      await createModule(data)
    }
    reset(defaultValues)
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_stacked_1'
      data-backdrop='static'
      tabIndex={-1}
      show={show}
      dialogClassName='modal-1'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{editId ? 'Edit' : 'Add'} Module</h4>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => {
              onClose()
              reset(defaultValues)
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-body'>
            <div className=''>
              <label htmlFor={`module-name`} className='required form-label'>
                Module Name
              </label>
              <input
                id={'module-name'}
                type='text'
                className='form-control'
                placeholder='Type Module Name'
                {...register('module_name', {required: true})}
              />
              {errors.module_name && <span className='text-danger'>This field is required</span>}
            </div>
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-outline btn-active-light-primary'
              onClick={() => {
                onClose()
                reset(defaultValues)
              }}
            >
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              {!isLoadingModulesOperation && (
                <span className='indicator-label'>{editId ? 'Update' : 'Save'}</span>
              )}

              {isLoadingModulesOperation && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {editId ? 'Updating...' : 'Saving...'}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ModuleModal
