import Select from 'react-select'
import {Controller, useFormContext} from 'react-hook-form'
import {useContext, useEffect, useState} from 'react'
import {TicketDetailsPageContext} from '../../../context'
import useGetTicketStatus from '../../../hooks/ticketDetails/useGetTicketStatus'
import useTicketOperations from '../../../hooks/agileBoard/useTicketOperations'

function StatusDropdown() {
  const formMethods = useFormContext()
  const {updateTicket, isLoadingTicketOperation} = useTicketOperations()
  const {ticketStatus, isLoadingTicketStatus} = useGetTicketStatus()
  const {ticketDetails, isLoadingTicketDetails}: any = useContext(TicketDetailsPageContext)
  const [defaultOption, setDefaultOption] = useState()
  const [isDefaultOptionLoading, setIsDefaultOptionLoading] = useState(true)
  
  useEffect(() => {
    if (!isLoadingTicketStatus && !isLoadingTicketDetails) {
      const defaultModuleOption =
        ticketStatus?.find((option: any) => option.value === ticketDetails['status']) ?? undefined
      setDefaultOption(defaultModuleOption)
      setIsDefaultOptionLoading(false)
    }
    // eslint-disable-next-line
  }, [isLoadingTicketStatus, isLoadingTicketDetails])

  const handleChange = (selectedOption: any) => {
    if (selectedOption && ticketDetails?.length !== 0) {
      updateTicket({status: selectedOption.value})
    }
  }

  return !isLoadingTicketStatus && !isLoadingTicketDetails && !isDefaultOptionLoading ? (
    <Controller
      name='status'
      defaultValue={defaultOption}
      control={formMethods.control}
      render={({field}) => (
        <Select
          {...field}
          className={`react-select-styled`}
          options={ticketStatus}
          placeholder='Select status'
          isLoading={isLoadingTicketOperation}
          onChange={(selectedOption) => {
            field.onChange(selectedOption)
            handleChange(selectedOption)
          }}
        />
      )}
    />
  ) : (
    <Select
      placeholder='Select status'
      isLoading={!ticketStatus?.length || isLoadingTicketStatus}
    />
  )
}

export default StatusDropdown
