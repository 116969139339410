import {useEffect, useRef, useState} from 'react'
import { convertInputToReadableFormat } from './utils/convertInputToReadableFormat'
import { convertInputToHoursAndMinutes } from './utils/convertInputToHoursAndMinutes'
import { validateInput } from './utils/validateInput'

const CustomTimePicker = ({
  inputFieldClass,
  popupPositionClass,
  days,
  hours,
  hourConversion,
  defaultValue,
  clearBtn,
  handleSelectedTime,
  isPopupDefaultValue,
  isTempAddition,
  isLoading,
}: any) => {

  const defaultTempSelectedTime = (isPopupDefaultValue === undefined && defaultValue) ? !hourConversion ? convertInputToReadableFormat(defaultValue, days, hours) : convertInputToHoursAndMinutes(defaultValue, days, hours) : ''
  const defaultConvertedTime = (defaultValue && !hourConversion) ? !isTempAddition ? convertInputToReadableFormat(defaultValue, days, hours) : convertInputToHoursAndMinutes(defaultValue, days, hours) : ''

  const [tempSelectedTime, setTempSelectedTime] = useState(defaultTempSelectedTime)
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [inputError, setInputError] = useState('')
  const [convertedTime, setConvertedTime] = useState(defaultConvertedTime)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  
  const [tempAddition, setTempAddition] = useState('')
  const [tempConvertedTime, setTempConvertedTime] = useState(convertedTime)
  
  const popupRef = useRef(null)

  useEffect(() => {
    if (defaultValue !== '') {
      setTempAddition(
        hourConversion
          ? convertInputToHoursAndMinutes(defaultValue, days, hours)
          : convertInputToReadableFormat(defaultValue, days, hours)
      );
      setTempConvertedTime(
        hourConversion
          ? convertInputToHoursAndMinutes(defaultValue, days, hours)
          : convertInputToReadableFormat(defaultValue, days, hours)
      );
    }
  }, [defaultValue, hourConversion, days, hours]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !(popupRef.current as any).contains(event.target)) {
        setPopupOpen(false)
        setInputError('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupRef])

  useEffect(() => {
    setIsButtonDisabled(inputError !== '' || tempSelectedTime.trim() === '')
  }, [inputError, tempSelectedTime])

  const handleCancel = () => {
    setPopupOpen(false)
    setInputError('')
    setTempSelectedTime(isPopupDefaultValue? convertedTime : '' )
  }

  const handleApplyChanges = () => {
    if (hourConversion) {
      const convertedValue = convertInputToHoursAndMinutes(tempSelectedTime, days, hours)
      if (!inputError && convertedValue !== '') {
        
          if(isTempAddition){
            const finalValue:any = `${tempSelectedTime}` + tempConvertedTime;
            setTempAddition(convertInputToHoursAndMinutes(finalValue , days, hours))
            setTempConvertedTime (convertInputToHoursAndMinutes(finalValue , days, hours))
          }

        setConvertedTime(convertedValue)
        setPopupOpen(false)
        
          if(isPopupDefaultValue){
            setTempSelectedTime(convertedValue)
          }else{
            setTempSelectedTime('')
          }
        
        handleSelectedTime(convertedValue)
      }
    } else {
      const convertedValue = convertInputToReadableFormat(tempSelectedTime, days, hours)
      if (!inputError && convertedValue !== '') {
        
          if(isTempAddition){
            const finalValue:any = `${tempSelectedTime} ` + tempConvertedTime;
            setTempAddition(convertInputToReadableFormat(finalValue , days, hours))
            setTempConvertedTime (convertInputToReadableFormat(finalValue , days, hours))
          }
        
        setConvertedTime(convertedValue)
        setPopupOpen(false)
        
          if(isPopupDefaultValue){
            setTempSelectedTime(convertedValue)
          }else{
            setTempSelectedTime('')
          }
        
        handleSelectedTime(convertedValue)
      }
    }
  }

  const popupInputRef:any = useRef(null);
  useEffect(() => {
    if (isPopupOpen && popupInputRef.current) {
      popupInputRef.current.focus();
    }
  }, [isPopupOpen]);

  const handleClearInput = () => {
    setTempSelectedTime('')
    setConvertedTime('')
    setTempAddition('')
    setTempConvertedTime('')
  }

  return (
    <div className='position-relative d-flex align-items-center justify-content-end'>
      <input
        type='text'
        value={isTempAddition? tempAddition : convertedTime}
        onChange={(e) => setTempSelectedTime(e.target.value)}
        className={`form-control pe-xxl-5 form-control-solid ${
          inputFieldClass ? inputFieldClass : 'w-100'
        }`}
        placeholder='?'
        onClick={() => setPopupOpen(true)}
        readOnly
      />
      {isLoading && <div className='position-absolute me-3'><span className='text-primary spinner-border spinner-border-sm align-middle mb-1'></span></div>}
      {isPopupOpen && (
        <div
          ref={popupRef}
          className={`mt-2 p-5 d-flex flex-column min-w-100 gap-4 bs-popover-auto top-100 popover  position-absolute bg-white border border-2 z-index-1 rounded-2 ${
            popupPositionClass ? popupPositionClass : 'start-0'
          }`}
        >
          <div className='m-dropdown__content d-flex flex-column gap-1'>
            <input
              type='text'
              className='form-control m-input'
              data-bs-toggle='tooltip'
              ref={popupInputRef}
              data-bs-placement='bottom'
              placeholder='1w 1d 1h 1m'
              value={tempSelectedTime}
              onKeyDown={(e) => e.key === 'Enter' ? handleApplyChanges(): null}
              onChange={(e) => {
                setTempSelectedTime(e.target.value)
                validateInput(e.target.value, setInputError)
              }}
            />
            <span className='text-danger p-1'>{inputError}</span>
          </div>
          <div className='d-flex justify-content-between m-dropdown__footer gap-3'>
            {clearBtn && (
              <button className='btn btn-light w-35px' onClick={handleClearInput}>
                <i className='fa-solid fa-trash'></i>
              </button>
            )}
            <div className='d-flex gap-3'>
              <button className='btn btn-light' onClick={handleCancel}>
                Cancel
              </button>
              <button
                disabled={isButtonDisabled}
                className={`btn btn-primary ${(!tempSelectedTime || inputError) && 'btn-disabled'}`}
                onClick={handleApplyChanges}
              >
                Apply Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomTimePicker
