import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {getAgileBoardData} from '../../service'
import {useParams} from 'react-router-dom'

export const useGetAgileBoardData = () => {
  const params = useParams()
  const projectId = params?.['projectId']

  const {
    data: agileBoardData,
    isFetching,
    refetch,
  } = useQuery(`get-agile-board-tasks`, () => {
    return getAgileBoardData(`/agile/projects/${projectId}/cards`)
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  return {
    agileBoardData: agileBoardData?.['data'] || [],
    isLoadingBoardData: isFetching,
    refetchBoardData: refetch,
  }
}
