import {toAbsoluteUrl} from '../_metronic/helpers'
import {format} from 'date-fns'
import {parseNumber} from './parseNumber'
import {toCamelCase, toSnakeCase} from './string'

export function HasValueOrNot(key: string) {
  return key === '' || !key ? '-' : key
}
export function formatInputPrice(
  n: string | number,
  skipZero: boolean,
  allowNegative: boolean,
  symbol = '$',
  skipSymbol = true
) {
  if (skipZero && !n) {
    return ''
  }

  const strNumber = n?.toString()
  const isNegative = strNumber?.includes('-')
  const hasDecimal = strNumber?.includes('.')

  // Check if the number is an integer or float
  const isInteger = Number.isInteger(Number(n))

  // If it's an integer, format as integer with commas; if float, format normally
  let formattedNumber: string

  if (isInteger) {
    // Format whole number with commas
    formattedNumber = parseCurrencyString(strNumber.replace('-', ''), allowNegative).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
  } else {
    // For floats, use normal formatting
    const fullNumber = hasDecimal ? strNumber.split('.')[0] : `${n}.00`
    const decimal = hasDecimal ? strNumber.split('.')[1].slice(0, 2) : '00'
    const res = parseCurrencyString(fullNumber, allowNegative)
      .replace('-', '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    formattedNumber = `${res}.${decimal}`
  }

  // Add back the negative sign if applicable
  const negativeRes = isNegative ? `-${formattedNumber}` : formattedNumber

  return skipSymbol ? negativeRes : `${isNegative ? '-' : ''}${symbol}${negativeRes}`
}


export function formatImage(value: any) {
  return value?.length > 0
    ? value?.[0]?.['url_thumbnail']
    : toAbsoluteUrl('/media/misc/preview.jpeg')
}

export function parseCurrencyString(str: string | number, allowNegative: boolean) {
  const parsedStr = str || str === 0 ? str?.toString().replace(/[^\d.-]/g, '') : ''

  if (allowNegative) {
    const isNegative = str?.toString().includes('-')
    return isNegative ? `-${parsedStr}` : parsedStr
  }

  return parsedStr
}

export function formatInputNumber(
  n: string,
  skipZero: boolean,
  allowNegative: boolean,
  symbol: string = '$'
) {
  // return format $10,000,00

  if (skipZero && !n) {
    return ''
  }

  const fixedNumber = parseFloat(n)?.toFixed(2)
  const strNumber = fixedNumber?.toString()
  const isNegative = strNumber?.includes('-')
  const hasDecimal = strNumber.includes('.')
  const fullNumber = hasDecimal ? strNumber.split('.')[0] : n
  const decimal = hasDecimal ? strNumber.split('.').slice(0, 1) : ''

  const res = parseCurrencyString(fullNumber, allowNegative)
    .replace('-', '')
    .replace(/\B(?=(d{3})+(?!d))/g, ',')

  const negativeRes = isNegative
    ? `-${symbol}${res.toString().replace('-', '')}`
    : isEmpty(res)
    ? ''
    : symbol + res

  return `${negativeRes}${hasDecimal ? `.${decimal}` : ''}`
}

export function isEmpty(data: any) {
  if (
    data === null ||
    data === undefined ||
    (typeof data === 'string' && data.trim() === '') ||
    (Array.isArray(data) && data.length === 0) ||
    (typeof data === 'object' && !Array.isArray(data) && Object.keys(data).length === 0)
  ) {
    return true
  }

  return false
}

export function formatPrice(
  n: string = '',
  skipZero: boolean,
  skipSymbol: boolean = false,
  returnEmptyString: boolean = false
) {
  if (returnEmptyString && isEmpty(n)) return ''
  if (parseNumber(n) === 0) {
    return '$0'
  }
  return isNaN(Number(n)) ? '-' : formatInputPrice(n, skipZero, true, '$', skipSymbol)
}

export function formateUppperCase(str: string) {
  return str.toUpperCase()
}

// export function formatDate(date: Date, ISO?: boolean) {
//   const newDate = ISO ? new Date(date).toLocaleDateString('en-GB') : new Date(date).toISOString()
//   return newDate
// }
export function formatCustomDate(date: Date, ISO?: boolean) {
  const formattedDate = ISO
    ? format(new Date(date), 'dd MMMM yyyy')
    : format(new Date(date), 'dd MMMM yyyy')
  return formattedDate
}

export function formatDateTime(date: Date, ISO?: boolean) {
  const formattedTime = ISO ? format(new Date(date), 'hh:mm a') : format(new Date(date), 'hh:mm a')
  return formattedTime
}

export const isBrowser = typeof window !== 'undefined'

export const sliceAfterMatch = (str: string, delimiter: string) => {
  if (str !== 'undefined') {
    const index = str.indexOf(delimiter)
    if (index === -1) {
      // If the delimiter is not found, return the original string
      return str
    } else {
      // Otherwise, return the part of the string after the delimiter
      return str.slice(index + delimiter.length)
    }
  }
}

export const setURLSearchParams = (filters: any) => {
  const url = new URL(window.location.href)

  // Get the search parameters
  const searchParams = new URLSearchParams(url.search)

  // Update the values of each search parameter
  for (const [key, value] of Object.entries(filters)) {
    searchParams.set(`${key}`, `${value}`)
  }

  // Update the search string of the URL with the new parameters
  url.search = searchParams.toString()

  // Replace the current URL with the updated URL
  window.history.replaceState(null, '', url)
}

export const getURLSearchParams = () => {
  // Get the current URL's search parameters
  const searchParams = new URLSearchParams(window.location.search)

  // Convert the search parameters into an object
  const queryParams: any = {}

  searchParams.forEach((value, key) => {
    queryParams[key] = value
  })

  return queryParams
}

export function dataToSelectOptions(
  data: any[],
  labelKey: string,
  valueKey: string,
  valueType?: 'string' | 'number'
) {
  if (isEmpty(data)) return []

  const uniqueItems = valueKey
    ? data.filter(
        (item, index, self) => index === self.findIndex((t) => t[valueKey] === item[valueKey])
      )
    : data

  return uniqueItems.map((item: any) => ({
    label: item[labelKey],
    value: valueType === 'number' ? parseNumber(item[valueKey]) : String(item[valueKey]),
  }))
}

export const toggleSortingOrder = (currentOrder: any) => {
  if (currentOrder === 'asc') return 'desc'
  if (currentOrder === 'desc') return 'asc'

  if (!currentOrder) return 'desc'
}

export const dataToSelectList = (data: any) => {
  const res: any = []

  if (!data || data.length === 0) {
    return []
  }

  data.forEach((i: any) => {
    res.push({
      value: i['name'],
      label: i['name'],
    })
  })

  return res
}

export const dataToTagSelectList = (data: any) => {
  // const res: any = []

  if (!data || data.length === 0) {
    return []
  }

  const mappedArray = data.map((item: any) => ({
    label: item.tag,
    value: item.id,
  }))

  return mappedArray
}

export const convertToPoints = (n: string | number) => {
  if (!n) {
    return '-'
  }

  const strNumber = n.toString()
  // const hasDecimal = strNumber.includes('.')
  // const fullNumber = hasDecimal ? strNumber.split('.')[0] : `${n}.00`
  // const decimal = hasDecimal ? strNumber.split('.')[1].slice(0, 2) : ''

  const res = parseCurrencyString(strNumber, false)
    .replace('-', '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `${res}`
}

export const generateRandomID = (length: number): string => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function makeSemicolonSepratedValues(input: any) {
  if (input?.length) {
    return input.map((item: any) => item.value).join(';')
  } else {
    return ''
  }
}

/**
 * Formats the given first name and last name into a full name.
 * If both first name and last name are provided, they are concatenated with a space in between.
 * If only the first name is provided, it is returned as the full name.
 * If only the last name is provided, it is returned as the full name.
 * If neither first name nor last name is provided, an empty string is returned.
 *
 * @param firstName - The first name.
 * @param lastName - The last name.
 * @returns The formatted full name.
 */
export const formatName = (firstName: string, lastName: string) => {
  let fullName = ''

  if (firstName && lastName) {
    fullName = `${firstName} ${lastName}`
  } else if (firstName) {
    fullName = firstName
  } else if (lastName) {
    fullName = lastName
  }

  return fullName
}

/**
 * Converts a date string to a formatted date string.
 * @param input - The date string to be converted.
 * @returns The formatted date string.
 */
export function convertDateFormat(input: string): string {
  if (input) {
    const date = new Date(input)
    return format(date, 'dd MMM yyyy, h:mm a')
  } else {
    return '-'
  }
}

export function convertDateFormatForCsv(input: string): string {
  if (input) {
    const date = new Date(input)
    return format(date, 'dd MMM yyyy - h:mm a')
  } else {
    return '-'
  }
}

export function formatLongName(longName: string, maxLength: number) {
  if (longName?.length > maxLength) {
    // Truncate the string and add ".." at the end
    longName = longName?.substring(0, maxLength - 2) + '..'
    return longName
  } else {
    // Return empty string if LongName is not defined or empty
    return '-'
  }
}

export function dataToSideBarStoreList(data: any, defaultStoreId: number | string) {
  const res: any = []

  if (!data || data.length === 0) {
    return []
  }

  // iterate over the object ...
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const element = data[key]

      res.push({
        storeId: key,
        name: element['name'],
        AdminURL: element['admin_url'],
        isActive: key === defaultStoreId ? true : false,
      })
    }
  }

  return res
}

export function convertSelectedValueType(options: any, selectedValue: any): any {
  if (isEmpty(options)) return

  for (const option of options) {
    // Find the option that matches the selected value
    if (option?.value?.toString() === selectedValue) {
      // Store the type of option value
      const optionValueType = typeof option.value
      // Assign option value type to selected value
      switch (optionValueType) {
        case 'number':
          return Number(selectedValue)
        case 'string':
          return String(selectedValue)
        default:
          return selectedValue
      }
    }
  }
  // If no matching type is found, return the original selected value
  return selectedValue
}

// convert keys of data into snake_case format
export function convertKeysToSnakeCase(data: any): any {
  if (Array.isArray(data)) {
    return data.map((item) => convertKeysToSnakeCase(item))
  } else if (data !== null && typeof data === 'object') {
    return Object.keys(data).reduce((acc, key) => {
      const snakeCaseKey = toSnakeCase(key)
      acc[snakeCaseKey] = convertKeysToSnakeCase(data[key])
      return acc
    }, {} as Record<string, any>)
  } else {
    return data
  }
}

// convert API data into camelCase format
export function dataToCamelCase(data: any): any {
  if (Array.isArray(data)) {
    return data.map(dataToCamelCase)
  } else if (typeof data === 'object' && data !== null) {
    return Object.keys(data).reduce((acc, key) => {
      const camelCaseKey = toCamelCase(key)
      acc[camelCaseKey] = dataToCamelCase(data[key])
      return acc
    }, {} as Record<string, any>)
  }
  return data
}

// Convert uploaded file into json format
export const convertFilesToJson = async (fileList: any, isMultiselect: boolean) => {
  // Function to convert a file to base64
  const fileToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })
  }

  // Convert each file to base64 and create an array of base64 strings
  const base64Files = await Promise.all(Array.from(fileList).map((file: any) => fileToBase64(file)))

  // Create a JSON object
  if (isMultiselect) {
    // Return an array of base64 strings if isMultiselect is true
    return base64Files.map((base64) => base64.split(',')[1])
  } else {
    // Return the base64 string of the first file if isMultiselect is false
    return base64Files[0].split(',')[1]
  }
}
