import {useEffect} from 'react'
import useFilters from '../../../hook/useFilters'
import {getProjects} from '../service'
import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'

export const useGetProjectsList = () => {
  const location = useLocation()

  const initialFilters = {
    page: 1,
    limit: 50,
    search_value: '',
    is_archived: location.pathname === '/projects/active' ? 'false' : 'true',
    sort_by: 'updated_at/-1',
  }

  const {filters, setSingleFilter, setMultipleFilters} = useFilters(initialFilters)

  const onClickSorting = (sortBy: string, sortDirection: any) => {
    const direction = sortDirection === 'asc' ? 1 : -1
    const sortFlag = sortBy + '/' + direction
    setMultipleFilters({
      sort_by: sortFlag,
      page: 1,
    })
  }

  const onSearch = (e: string) => {
    setMultipleFilters({
      search_value: e,
      page: 1,
    })
  }

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  const {
    data: projectsList,
    isFetching,
    refetch,
  } = useQuery(`get-projects-list`, () => {
    return getProjects('/agile/projects', filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  return {
    projectsList: projectsList?.['data'] || [],
    isLoading: isFetching,
    pagination: projectsList?.['meta']?.['pagination'] || {},
    filters,
    onPageChange,
    onSearch,
    refetchProjectsList: refetch,
    onClickSorting,
  }
}
