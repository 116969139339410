import {useQuery} from 'react-query'
import {getBusinessUnits} from '../service'

export const useGetBusinessUnitsList = () => {
  const {data: response, isFetching} = useQuery(`get-business-unit-list`, () => {
    return getBusinessUnits(`agile/businessunits`)
  })

  return {
    businessUnitsList: response,
    isLoadingBusinessUnits: isFetching,
  }
}
