import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {getProjectMembersDropdownList} from '../../service'

export const useGetProjectMembersDropdownList = () => {

  const {
    data: projectMembersList,
    isFetching,
    refetch,
  } = useQuery(`get-project-members-dropdown-list`, () => {
    return getProjectMembersDropdownList(`/agile/projects/members`)
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  return {
    projectMembersDropdownList: projectMembersList?.['data'] || [],
    isLoadingProjectMembersDropdownList: isFetching,
    refetchProjectMembersDropdownList: refetch,
  }
}
