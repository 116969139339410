import {Pagination} from '../../../utils/pagination'
import ArchivedProjectsTable from '../components/table/ArchivedProjectsTable'
import {ProjectsListPageContext} from '../context/index'
import {useGetBusinessUnitsList} from '../hooks/useGetBusinessUnitsList'
import {useGetProjectsList} from '../hooks/useGetProjectsList'
import useUpdateProjectDetails from '../hooks/useUpdateProjectDetails'
import useCreateProject from '../hooks/useCreateProject'
import useAddToFavorites from '../hooks/useAddToFavorites'
import { ArchivePageHeader } from '../components/header/ArchivePageHeader'

function Archived() {
  const {
    projectsList,
    isLoading,
    pagination,
    onSearch,
    onPageChange,
    filters,
    refetchProjectsList,
    onClickSorting,
  } = useGetProjectsList()
  const {businessUnitsList, isLoadingBusinessUnits}: any = useGetBusinessUnitsList()
  const {updateProjectDetails, isUpdating} = useUpdateProjectDetails()
  const {createNewProjectDetails, isCreating} = useCreateProject()
  const {addProjectToFavorite, isAdding} = useAddToFavorites()

  const contextValue = {
    projectsList,
    isLoading,
    isCreating,
    isUpdating,
    filters,
    businessUnitsList,
    isLoadingBusinessUnits,
    onSearch,
    updateProjectDetails,
    createNewProjectDetails,
    refetchProjectsList: refetchProjectsList,
    onClickSorting,
    addProjectToFavorite,
    isAdding,
  }

  return (
    <>
      <ProjectsListPageContext.Provider value={contextValue}>
        <ArchivePageHeader />
        <ArchivedProjectsTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </ProjectsListPageContext.Provider>
    </>
  )
}

export default Archived
