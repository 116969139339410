import {useAppSelector} from '../../../../../redux/useTypedSelector'

function OwnerListDropdown() {
  const {user} = useAppSelector((state) => state.auth)
  return (
    <p>{user['username']}</p>
  )
}

export default OwnerListDropdown
