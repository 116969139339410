import {useContext, useEffect} from 'react'
import useFilters from '../../../../hook/useFilters'
import {useQuery} from 'react-query'
import {getAllModules} from '../../service'
import {dataToModulesListing} from '../../utils/module'
import {ProjectsMainPageContext} from '../../context'

const initialFilters = {}

const useGetModuleListing = () => {
  const {projectId} = useContext(ProjectsMainPageContext)

  const {filters} = useFilters(initialFilters)

  const {
    data: modulesList,
    isFetching,
    refetch,
  } = useQuery(`get-all-modules`, () => {
    return getAllModules(projectId, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  return {
    modulesList: modulesList?.['data'] ? dataToModulesListing(modulesList['data']) : [],
    isLoadingModuleListing: isFetching,
    refetchModules: refetch,
  }
}

export default useGetModuleListing
