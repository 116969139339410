import {useEffect} from 'react'
import {useQuery} from 'react-query'
import { getGlobalProjectsMembersDropdownList } from '../../services'

export const useGetGlobalProjectsMembersDropdownList = () => {

  const {
    data: globalProjectsMembersDropdownList,
    isFetching,
    refetch,
  } = useQuery(`get-global-projects-members-dropdown-list`, () => {
    return getGlobalProjectsMembersDropdownList()
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  return {
    globalProjectsMembersDropdownList: globalProjectsMembersDropdownList?.['data'] || [],
    isLoadingGlobalProjectsMembersDropdownList: isFetching,
    refetchProjectMembersDropdownList: refetch,
  }
}
