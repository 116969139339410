import {Link} from 'react-router-dom'
import {formatForLink} from '../../../../utils/string'
import OverlayModal from '../modal/OverlayModal'
import {formatDate} from '../../../../utils/date'
import {KTSVG} from '../../../../_metronic/helpers'
import {useContext, useState} from 'react'
import {ProjectsListPageContext} from '../../context'
import ProjectMembersListingModal from '../modal/ProjectMembersListingModal'
import usePermission from '../../../../hook/usePermission'

const ActiveProjectsTableRow = ({row}: any) => {
  const {hasPermission} = usePermission()
  const {addProjectToFavorite} = useContext(ProjectsListPageContext)
  const [isHovered, setIsHovered] = useState(row?.['is_fevorite'] === 1 ? true : false)
  const [isFavorite, setIsFavorite] = useState(row?.['is_fevorite'] === 1 ? true : false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [projectId, setProjectId] = useState(null)
  const projectStatus = 'active'
  
  const handleHover = () => {
    setIsHovered(true)
  }

  const handleHoverOver = () => {
    if (!isFavorite) {
      setIsHovered(false)
    }
  }

  const handleFavorite = () => {
    setIsFavorite(!isFavorite)
    addProjectToFavorite(row['id'])
  }

  const handleProjectMemberModal = (projectId: any) => {
    setIsModalOpen(true)
    setProjectId(projectId)
  }

  return (
    <tr>
      <td>
        <div className='project-table-name-col'>
          <div className='d-flex align-items-center'>
            <div
              onMouseEnter={handleHover}
              onMouseLeave={handleHoverOver}
              onClick={handleFavorite}
              className={`rating-label me-2 ${isHovered ? '' : ''} ${isFavorite ? 'checked' : ''}`}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen029.svg'
                className='svg-icon svg-icon-2 me-1'
              />
            </div>
            <Link
              to={`/projects/${projectStatus}/${formatForLink(row['name'])}/${row['id']}`}
              className='text-gray-800'
            >
              {row['name']}
            </Link>
          </div>
        </div>
      </td>

      <td>{row['business_unit']}</td>
      <td
        className='text-center cursor-pointer'
        onClick={() => handleProjectMemberModal(row['id'])}
      >
        {row['members']}
      </td>
      <td>{row['owner_username']}</td>
      <td>{formatDate(row['created_at'], false)}</td>
      <td>{formatDate(row['updated_at'], false)}</td>
      {hasPermission('projects_all projects', 'write') && (
        <td>
          <OverlayModal projectDetails={row} />
        </td>
      )}
      <ProjectMembersListingModal
        modalTitle={'Project Members'}
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        projectId={projectId}
      />
    </tr>
  )
}

export default ActiveProjectsTableRow
