/* eslint-disable react-hooks/exhaustive-deps */
import {Suspense, useEffect} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {I18nProvider} from './i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from './_metronic/layout/core'
import {MasterInit} from './_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {useAppSelector} from './redux/useTypedSelector'
import {setRedirect} from './feature/authSlice'
import store from './redux/store'

const App = () => {
  const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID as string

  const {user} = useAppSelector((state) => state.auth)
  const navigate = useNavigate()
  const location = useLocation()
  const redirect_url: any = location.pathname

  useEffect(() => {
    store.dispatch(setRedirect(redirect_url))
  }, [])

  useEffect(() => {
    if (user === null) {
      navigate('/login')
    }
  }, [user, navigate])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <GoogleOAuthProvider clientId={google_client_id}>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </GoogleOAuthProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
