import {useQuery} from 'react-query'
import {getCommentList} from '../../service'
import {useEffect} from 'react'
import {useParams} from 'react-router-dom'

const useGetCommentsList = () => {
  const params = useParams()
  const cardId = params['cardId'] as string

  const {
    data: commentsListData,
    isFetching,
    refetch,
  } = useQuery('comments-list-data', () => {
    return getCommentList(cardId)
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  return {
    commentsListData: commentsListData?.data ?? [],
    isLoadingCommentsListData: isFetching,
    refetchCommentsListData: refetch,
  }
}

export default useGetCommentsList
