import Accordion from '../../../components/Accordion'
import ColumnsTable from '../components/table/boardSettingTables/ColumnsTable'
import CustomFieldsTable from '../components/table/boardSettingTables/CustomFieldsTable'
import ModulesTable from '../components/table/boardSettingTables/ModulesTable'
import {BoardSettingsPageContext} from '../context'
import useColumnOperations from '../hooks/boardSettings/useColumnOperations'
import useCustomFieldsOperations from '../hooks/boardSettings/useCustomFieldsOperations'
import useGetAllColumns from '../hooks/boardSettings/useGetAllColumns'
import useGetCustomFieldsList from '../hooks/boardSettings/useGetCustomFieldsList'
import useGetModuleListing from '../hooks/boardSettings/useGetModuleListing'
import useModulesOperations from '../hooks/boardSettings/useModulesOperations'

function BoardSetting() {
  const {
    createColumn,
    updateColumn,
    updateColumnTableSorting,
    deleteColumn,
    isLoadingColumnOperation,
  } = useColumnOperations()
  const {
    createCustomField,
    updateCustomField,
    updateCustomFieldTableSorting,
    deleteCustomField,
    isLoadingCustomFieldOperation,
  } = useCustomFieldsOperations()
  const {columns, isLoadingColumns, refetchColumns} = useGetAllColumns()
  const {modulesList, isLoadingModuleListing, refetchModules} = useGetModuleListing()
  const {customFieldsList, isLoadingCustomFieldList, refetchCustomFieldsList} =
    useGetCustomFieldsList()
  const {
    createModule,
    updateModule,
    updateModuleTableSorting,
    deleteModule,
    isLoadingModulesOperation,
  } = useModulesOperations()

  const contextValue = {
    createColumn,
    updateColumn,
    updateColumnTableSorting,
    deleteColumn,
    isLoadingColumnOperation,

    createCustomField,
    updateCustomField,
    updateCustomFieldTableSorting,
    deleteCustomField,
    isLoadingCustomFieldOperation,

    columns,
    isLoadingColumns,
    refetchColumns,

    customFieldsList,
    isLoadingCustomFieldList,
    refetchCustomFieldsList,

    modulesList,
    isLoadingModuleListing,
    refetchModules,

    createModule,
    updateModule,
    updateModuleTableSorting,
    deleteModule,
    isLoadingModulesOperation,
  }

  const items = [
    {title: 'Columns', id: 'project-columns-table', content: <ColumnsTable/>, isLoading: isLoadingColumnOperation},
    {title: 'Modules', id: 'project-modules-table', content: <ModulesTable/>, isLoading: isLoadingModulesOperation},
    {title: 'Custom Fields', id: 'project-cFields-table', content: <CustomFieldsTable/>, isLoading: isLoadingCustomFieldOperation},
  ]

  return (
    <BoardSettingsPageContext.Provider value={contextValue}>
      <div className='agile-accordion-section'>
        <Accordion items={items} defaultOpen='All'/>
      </div>
    </BoardSettingsPageContext.Provider>
  )
}

export default BoardSetting
