export function updateModuleCardsCounter(tasks: any, formatedData: any, setModuleTaskCounts: any) {
  const moduleCounts: any = {}

  tasks.forEach((task: any) => {
    const moduleName = formatedData.find((column: any) => column.columnID === task.columnID)?.moduleName

    if (moduleName) {
      moduleCounts[moduleName] = (moduleCounts[moduleName] || 0) + 1
    }
  })

  setModuleTaskCounts(moduleCounts)
}
