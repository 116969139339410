export function handleDragStart(
  event: any,
  mergedData: any,
  setUpdatedTaskDetails: any,
  setActiveTask: any
) {
  const {active} = event

  if (active.data.current?.type === 'Task') {
    setActiveTask(active.data.current.task)
    const activeColumnId = active.data.current.task.columnID
    const initialColumn = mergedData.find((column: any) => column.columnID === activeColumnId)
    const initialModuleId = initialColumn?.moduleID || ''
    const activeTaskId = active.data.current.task.ticketID

    setUpdatedTaskDetails({
      prevModuleId: initialModuleId,
      activeTaskId: activeTaskId,
    })
  }
}
