export const getModifiedInputFields = (
  initialValues: any,
  currentValues: any,
  modifiedDropdownValue: any
) => {
  const modifiedFields: {[key: string]: any} = {}

  for (const key in currentValues) {
    if (currentValues[key] !== initialValues[key]) {
      modifiedFields[key] = currentValues[key]
    }
  }

  if (modifiedDropdownValue !== initialValues.bu_id) {
    modifiedFields.bu_id = modifiedDropdownValue
  }

  return modifiedFields
}
