// import '../scss/task-card.scss'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {useContext} from 'react'
import {DndBoardPageContext} from '../context'

function TaskCard({task}: any) {
  const {handleTicketLayout} = useContext(DndBoardPageContext)
  const {setNodeRef, attributes, listeners, transform, transition, isDragging} = useSortable({
    id: task.ticketID,
    data: {
      type: 'Task',
      task,
    },
  })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  if (isDragging) {
    return <div ref={setNodeRef} style={style} className='dummy-background-task-container' />
  }

  const ticketData = task

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className='board-card-item text-muted text-hover-primary cursor-pointer border-gray-300 border-2 d-flex border rounded-1 align-items-center px-4 py-3'
    >
      {handleTicketLayout({ticketData})}
    </div>
  )
}

export default TaskCard
