import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useContext, useEffect} from 'react'
import {ProjectsListPageContext} from '../../context'

interface DeleteConfirmationDialog {
  projectDetails: any
  show: boolean
  onClose: () => void
}

const ProjectArchiveConfirmationModal = ({
  projectDetails,
  show,
  onClose,
}: DeleteConfirmationDialog) => {
  const {updateProjectDetails, isUpdating} = useContext(ProjectsListPageContext)

  useEffect(() => {
    if (isUpdating) {
      onClose()
    }
  }, [isUpdating, onClose])

  if (!show) {
    return null
  }

  const handleArchiveproject = () => {
    if (projectDetails) {
      const updatedDetails = {
        is_archived: !projectDetails.is_archived,
      }
      document.body.click()
      updateProjectDetails(updatedDetails, projectDetails.id)
    }
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>
            {projectDetails.is_archived ? 'Unarchive' : 'Archive'} Project
          </h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <p>
            Are you sure you want to {projectDetails.is_archived ? 'unarchive' : 'archive'} the
            project?
          </p>
        </div>

        <div className='modal-footer'>
          <button type='button' className='btn btn-outline btn-active-light-primary' onClick={onClose}>
            No
          </button>
          <button
            type='submit'
            className={`btn btn-${projectDetails.is_archived ? 'primary' : 'danger'}`}
            onClick={handleArchiveproject}
          >
            {!isUpdating && <span className='indicator-label'>Yes</span>}

            {isUpdating && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please Wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ProjectArchiveConfirmationModal
