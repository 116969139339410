import {useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import DeleteProjectsMemberConfirmationModal from '../modal/members/DeleteProjectsMemberConfirmationModal'

const ProjectsMemberOverlayModal = ({projectMemberDetails}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDeleteMember = () => {
    setIsModalOpen(true)
    document.body.click()
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <div className=' d-flex align-items-start flex-column'>
            <a
              href='###'
              onClick={() => handleDeleteMember()}
              className='btn btn-active-text-danger'
            >
              Remove
            </a>
          </div>
        }
      />

      {isModalOpen && (
        <>
          <DeleteProjectsMemberConfirmationModal
            uniqueID={projectMemberDetails.member_id}
            show={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </>
      )}
    </>
  )
}

export default ProjectsMemberOverlayModal
