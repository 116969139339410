import {useEffect} from 'react'
import useFilters from '../../../../hook/useFilters'
import {useQuery} from 'react-query'
import {getAllCustomFields} from '../../service'
import {useParams} from 'react-router-dom'

const initialFilters = {}

const useGetCustomFieldsList = () => {
  const params = useParams()
  const projectId = params?.['projectId'] as string
  const {filters} = useFilters(initialFilters)

  const {
    data: customFieldList,
    isFetching,
    refetch,
  } = useQuery(`get-all-custom-fields`, () => {
    return getAllCustomFields(projectId, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  return {
    customFieldsList: customFieldList?.['data'] ? customFieldList['data'] : [],
    isLoadingCustomFieldList: isFetching,
    refetchCustomFieldsList: refetch,
  }
}

export default useGetCustomFieldsList
