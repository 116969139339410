import Select from 'react-select'
import {Controller, useFormContext} from 'react-hook-form'
import {useContext, useEffect, useState} from 'react'
import {TicketDetailsPageContext} from '../../../context'
import useGetTicketPriorites from '../../../hooks/ticketDetails/useGetTicketPriorities'
import useTicketOperations from '../../../hooks/agileBoard/useTicketOperations'

function PriorityDropdown() {
  const formMethods = useFormContext()
  const {updateTicket, isLoadingTicketOperation} = useTicketOperations()
  const {ticketPriorities, isLoadingTicketPriorities} = useGetTicketPriorites()
  const {ticketDetails, isLoadingTicketDetails}: any = useContext(TicketDetailsPageContext)
  const [defaultOption, setDefaultOption] = useState()
  const [isDefaultOptionLoading, setIsDefaultOptionLoading] = useState(true)
  
  useEffect(() => {
    if (!isLoadingTicketPriorities && !isLoadingTicketDetails) {
      const defaultModuleOption =
        ticketPriorities.find((option: any) => option.value === ticketDetails['priority']) ??
        undefined
      setDefaultOption(defaultModuleOption)
      setIsDefaultOptionLoading(false)
    }
    // eslint-disable-next-line
  }, [isLoadingTicketPriorities, isLoadingTicketDetails])

  const handleChange = (selectedOption: any) => {
    if (selectedOption && ticketDetails?.length !== 0) {
      updateTicket({priority: selectedOption.value})
    }
  }

  return !isLoadingTicketPriorities && !isLoadingTicketDetails && !isDefaultOptionLoading ? (
    <Controller
      name='priority'
      control={formMethods.control}
      defaultValue={defaultOption}
      render={({field}) => (
        <Select
          {...field}
          className={`react-select-styled`}
          options={ticketPriorities}
          placeholder='Select priority'
          isLoading={isLoadingTicketOperation}
          onChange={(selectedOption) => {
            field.onChange(selectedOption)
            handleChange(selectedOption)
          }}
        />
      )}
    />
  ) : (
    <Select
      placeholder='Select priority'
      isLoading={!ticketPriorities?.length || isLoadingTicketPriorities}
    />
  )
}

export default PriorityDropdown
