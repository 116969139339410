import {Controller, useFormContext} from 'react-hook-form'
import CustomTimePicker from '../../../../../components/TimePicker/CustomTimePicker'
import {useContext, useEffect, useState} from 'react'
import {TicketDetailsPageContext} from '../../../context'
import Select from 'react-select'
import useTicketOperations from '../../../hooks/agileBoard/useTicketOperations'

const EstimationTimePicker = () => {
  const formMethods = useFormContext()
  const {updateTicket, isLoadingTicketOperation} = useTicketOperations()
  const {ticketDetails, isLoadingTicketDetails}: any = useContext(TicketDetailsPageContext)
  const [defaultOption, setDefaultOption] = useState('')
  const [isDefaultOptionLoading, setIsDefaultOptionLoading] = useState(true)
  
  useEffect(() => {
    if (!isLoadingTicketDetails) {
      const defaultModuleOption = ticketDetails['estimation']
      setDefaultOption(defaultModuleOption ? `${defaultModuleOption}h` : '')
      setIsDefaultOptionLoading(false)
    }
    // eslint-disable-next-line
  }, [isLoadingTicketDetails])

  const handleChange = (selectedTime: any) => {
    if (selectedTime && ticketDetails?.length !== 0) {
      updateTicket({estimation: selectedTime})
    }
  }

  return !isLoadingTicketDetails && !isDefaultOptionLoading ? (
    <Controller
      name='estimation'
      control={formMethods.control}
      render={({field}) => (
        <CustomTimePicker
          className={`react-select-styled`}
          popupPositionClass='end-0'
          days={5}
          hours={8}
          isPopupDefaultValue={false}
          hourConversion={false}
          isLoading={isLoadingTicketOperation}
          defaultValue={defaultOption}
          handleSelectedTime={(time: any) => {
            field.onChange(time)
            handleChange(time)
          }}
        />
      )}
    />
  ) : (
    <Select placeholder='Loading...' isLoading={!defaultOption?.length || isLoadingTicketDetails} />
  )
}

export default EstimationTimePicker
