import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import {getLastPathSegment} from '../utils/string'
import moment from 'moment'

interface initialFilters {
  search?: string | boolean
  page?: number | string | boolean
  limit?: number | boolean
  status?: string | boolean
  sort_by?: string | boolean
  sort_order?: string | boolean
  filter?: string | boolean
  start_date?: string | boolean
  end_date?: string | boolean
  [key: string]: any
}

interface FilterOptions {
  sortType?: string
}

function useFilters(initialFilters: initialFilters, filterOptions?: FilterOptions) {
  const location = useLocation()
  const searchParams = new URLSearchParams(location?.search)
  const page = searchParams?.get('page')
  const name = getLastPathSegment(location)

  const defaultFilters: Record<string, any> = {
    ...initialFilters,
    ...(initialFilters.search !== undefined && {
      search: initialFilters.search === true ? '' : initialFilters.search,
    }),
    ...(initialFilters.filter !== undefined && {
      filter: initialFilters.filter === true ? '' : initialFilters.filter,
    }),
    ...(initialFilters.page !== undefined && {
      page: initialFilters.page === true ? page || 1 : initialFilters.page,
    }),
    ...(initialFilters.limit !== undefined && {
      limit: initialFilters.limit === true ? 20 : initialFilters.limit,
    }),
    ...(initialFilters.status !== undefined && {
      status: initialFilters.status === true ? (name === 'all' ? '' : name) : initialFilters.status,
    }),
    ...(initialFilters.type !== undefined && {
      type: initialFilters.type === true ? '' : initialFilters.type,
    }),
    ...(initialFilters.sort_by !== undefined && {
      sort_by: initialFilters.sort_by === true ? '' : initialFilters.sort_by,
    }),
    ...(initialFilters.sort_order !== undefined && {
      sort_order: initialFilters.sort_order === true ? 'desc' : initialFilters.sort_order,
    }),
    ...(initialFilters.filter_by !== undefined && {
      filter_by: initialFilters.filter_by === true ? '' : initialFilters.filter_by,
    }),
    ...(initialFilters?.start_date !== undefined && {
      start_date:
        initialFilters.start_date === true
          ? moment().startOf('month').format('YYYY-MM-DD')
          : initialFilters.start_date,
    }),
    ...(initialFilters?.end_date !== undefined && {
      end_date:
        initialFilters.end_date === true ? moment().format('YYYY-MM-DD') : initialFilters.end_date,
    }),
  }

  const [filters, setFilters] = useState(defaultFilters)

  const setSingleFilter = (key: string, value: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [key]: value,
    }))
  }

  const setMultipleFilters = (obj: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      ...obj,
      ...(prevFilters.page && {page: 1}),
    }))
  }

  const onSearch = (searchValue: string) => {
    setMultipleFilters(
      initialFilters.filter !== undefined
        ? {
            filter: searchValue,
            page: 1,
          }
        : {
            search: searchValue,
            page: 1,
          }
    )
  }

  const onPageChange = (pageNumber: number | string) => {
    setSingleFilter('page', pageNumber)
  }

  const onStatusChange = (status: string) => {
    setSingleFilter('status', status)
  }

  const onSortingChange = (sortBy: string, sortDirection: string) => {
    if (filterOptions && filterOptions.sortType && filterOptions.sortType === 'number') {
      const direction = sortDirection === 'asc' ? '1' : '-1'
      const sortFlag = `${sortBy}/${direction}`
      setMultipleFilters({
        sort_by: sortFlag,
        page: 1,
      })
    } else {
      const direction = sortDirection === 'asc' ? 'asc' : 'desc'
      const sortFlag = sortBy + ':' + direction
      if (initialFilters.sort_order !== undefined && initialFilters.sort_by !== undefined) {
        setMultipleFilters({
          sort_by: sortBy,
          sort_order: direction,
          page: 1,
        })
      } else {
        setMultipleFilters({
          sort_by: sortFlag,
          page: 1,
        })
      }
    }
  }

  const onFilterChange = (filterValue: number | string) => {
    setMultipleFilters({
      filter_by: filterValue,
      page: 1,
    })
  }

  const onTypeChange = (type: string) => {
    setMultipleFilters({
      type: type,
      page: 1,
    })
  }

  const onDateChange = (startDate: any, endDate: any) => {
    setFilters((prevFilter) => ({
      ...prevFilter,
      start_date: startDate?.format('YYYY-MM-DD'),
      end_date: endDate?.format('YYYY-MM-DD'),
    }))
  }

  return {
    filters,
    setSingleFilter,
    setMultipleFilters,
    onSearch,
    onPageChange,
    onSortingChange,
    onStatusChange,
    onFilterChange,
    onTypeChange,
    onDateChange,
  }
}

export default useFilters
