import Api from '../../../services/Api'

export const getProjectMembersDropdownList = (url: string) => {
  return Api.get(url).then((d: any) => {
    return d.data
  })
}

export const getMember = (url: string, filters: any) => {
  return Api.get(`${url}`, filters).then((d: any) => {
    return d.data
  })
}

export const getAgileBoardStructure = (url: string) => {
  return Api.get(`${url}`).then((d: any) => {
    return d.data
  })
}

export const getAgileBoardData = (url: string) => {
  return Api.get(`${url}`).then((d: any) => {
    return d.data
  })
}

export async function addNewMember(url: string, data: any) {
  const response = await Api.post(url, data, null, null)
  return response
}

export async function submitProjectMemberDetails(data: any) {
  const url = `/agile/projects`
  const response = await Api.post(url, data, null, null)
  return response
}

export async function updateProjectMember(url: string, data: any) {
  const response = await Api.patch(url, data, null, null)
  return response
}

export async function removeMember(url: string) {
  const response = await Api.delete(url, null, null, null)
  return response
}

export async function submitUpdatedAgileBoardDetails(url: string, data: any) {
  const response = await Api.patch(url, data, null, null)
  return response
}

// columns service ...
export function getAllColumns(projectId:string, filters: any) {
  const url = `/agile/projects/${projectId}/columns`

  return Api.get(url, filters).then((d: any) => {
    return d.data
  })
}

export function addColumn(projectId:string, data: any) {
  const url = `/agile/projects/${projectId}/columns`

  return Api.post(url, data, null, null).then((d: any) => {
    return d
  })
}

export function editColumn(projectId: string, columnId: string, data: any) {
  const url = `/agile/projects/${projectId}/columns/${columnId}`

  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

export function removeColumn(projectId: string, columnId: string) {
  const url = `/agile/projects/${projectId}/columns/${columnId}`

  return Api.delete(url, null, null, null).then((d: any) => {
    return d
  })
}

// columns service ...

// module service ...
export function getAllModules(projectId: string, filters: any) {
  const url = `/agile/projects/${projectId}/modules`

  return Api.get(url, filters).then((d: any) => {
    return d.data
  })
}

export function addModule(projectId: string, data: any) {
  const url = `/agile/projects/${projectId}/modules`

  return Api.post(url, data, null, null).then((d: any) => {
    return d
  })
}

export function editModule(projectId: string, moduleId: string, data: any) {
  const url = `/agile/projects/${projectId}/modules/${moduleId}`

  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

export function removeModule(projectId: string, moduleId: string) {
  const url = `/agile/projects/${projectId}/modules/${moduleId}`

  return Api.delete(url, null, null, null).then((d: any) => {
    return d
  })
}
// module service ...

// custom field service ...

export function getAllCustomFields(projectId: string, filters: any) {
  const url = `/agile/projects/${projectId}/custom-fields`
  return Api.get(url, filters).then((d: any) => {
    return d.data
  })
}

export function getAllCustomFieldDetails(projectId: string, cFieldId: any, filters: any) {
  const url = `/agile/projects/${projectId}/custom-fields/${cFieldId}`
  return Api.get(url, filters).then((d: any) => {
    return d.data
  })
}

export function addCustomField(projectId: string, data: any) {
  const url = `/agile/projects/${projectId}/custom-fields`

  return Api.post(url, data, null, null).then((d: any) => {
    return d
  })
}

export function editCustomField(projectId: string, cFieldId: string, data: any) {
  const url = `/agile/projects/${projectId}/custom-fields/${cFieldId}`

  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

export function removeCustomField(projectId: string, cFieldId: string) {
  const url = `/agile/projects/${projectId}/custom-fields/${cFieldId}`

  return Api.delete(url, null, null, null).then((d: any) => {
    return d
  })
}
// custom field service ...

// Board settings table sorting service ...
export function updateTableSorting(projectId: string, tableName: any, data: any) {
  const url = `/agile/projects/${projectId}/${tableName}`

  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

export function updateBoardColumnTableSorting(projectId:any, tableName: any, data: any) {
  const url = `/agile/projects/${projectId}/${tableName}`

  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

// admin users service ...
export function getAllAdminUsers(filters: any) {
  const url = `/whatsapp/get/vendor/representative/list`

  return Api.get(url, filters).then((d: any) => {
    return d.data
  })
}

// project card service ...
export function createCard(projectId: string, moduleId: string, data: any) {
  const url = `/agile/projects/${projectId}/modules/${moduleId}/cards`

  return Api.post(url, data, null, null).then((d: any) => {
    return d
  })
}

export function getCardDetails(projectId: string, cardId: string) {
  const url = `/agile/projects/${projectId}/cards/${cardId}`

  return Api.get(url, {}).then((d: any) => {
    return d.data
  })
}

export function updateCard(projectId: string, cardId: string, data: any) {
  const url = `/agile/projects/${projectId}/cards/${cardId}`

  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

// kanban service ...
export function getKanbanBoardStages(projectId: string) {
  const url = `/agile/projects/${projectId}/columns`

  return Api.get(url, null).then((d: any) => {
    return d.data
  })
}
// kanban service ...

export function getAllCardStatus() {
  const url = `/agile/projects/card-status`

  return Api.get(url, null).then((d: any) => {
    return d.data
  })
}

export function getTicketPriorities() {
  const url = `/agile/projects/card-priorities`

  return Api.get(url, null).then((d: any) => {
    return d.data
  })
}

//ticket comments services...

export function getCommentList(cardId: string) {
  const url = `/agile/projects/cards/${cardId}/comments`

  return Api.get(url).then((d: any) => {
    return d.data
  })
}

export function addComment(cardId: any, data: any) {
  const url = `/agile/projects/cards/${cardId}/comments`

  return Api.post(url, data, null, null).then((d: any) => {
    return d
  })
}

export function updateComment(cardId: any, commentId: any, data: any) {
  const url = `/agile/projects/cards/${cardId}/comments/${commentId}`

  return Api.put(url, data, null, null).then((d: any) => {
    return d
  })
}

export function removeComment(cardId: any, commentId: any) {
  const url = `/agile/projects/cards/${cardId}/comments/${commentId}`

  return Api.delete(url, null, null, null).then((d: any) => {
    return d
  })
}

//additional information custom fields service...

export function getAllAdditionalInfoCFieldsList(projectId: string, cardId: string) {
  const url = `/agile/projects/${projectId}/${cardId}/custom-fields`

  return Api.get(url, null).then((d: any) => {
    return d.data
  })
}
export function updateTicketCFieldValue(projectId: string, cardId: string, data: any) {
  const url = `/agile/projects/${projectId}/${cardId}/custom-fields`

  return Api.post(url, data, null, null).then((d: any) => {
    return d
  })
}
