import Select from 'react-select'
import {Controller, useFormContext} from 'react-hook-form'
import {useContext, useEffect, useState} from 'react'
import {TicketDetailsPageContext} from '../../../context'
import useGetKanbanStages from '../../../hooks/ticketDetails/useGetKanbanStage'
import useTicketOperations from '../../../hooks/agileBoard/useTicketOperations'

function KanbanStageDropdown() {
  const formMethods = useFormContext()
  const {updateTicket, isLoadingTicketOperation} = useTicketOperations()
  const {kanbanstages, isLoadingKanbanStage} = useGetKanbanStages()
  const {ticketDetails, isLoadingTicketDetails}: any = useContext(TicketDetailsPageContext)
  const [defaultOption, setDefaultOption] = useState()
  const [isDefaultOptionLoading, setIsDefaultOptionLoading] = useState(true)
  
  useEffect(() => {
    if (!isLoadingKanbanStage && !isLoadingTicketDetails) {
      const defaultModuleOption =
        kanbanstages.find((option: any) => option.value === ticketDetails['columnID']) ?? undefined
      setDefaultOption(defaultModuleOption)
      setIsDefaultOptionLoading(false)
    }
    // eslint-disable-next-line
  }, [isLoadingKanbanStage, isLoadingTicketDetails])

  const handleChange = (selectedOption: any) => {
    if (selectedOption && ticketDetails?.length !== 0) {
      updateTicket({current_column_id: selectedOption.value})
    }
  }

  return !isLoadingKanbanStage && !isLoadingTicketDetails && !isDefaultOptionLoading ? (
    <Controller
      name='stage'
      control={formMethods.control}
      defaultValue={defaultOption}
      render={({field}) => (
        <Select
          {...field}
          className={`react-select-styled`}
          options={kanbanstages}
          placeholder='Select stage'
          isLoading={isLoadingTicketOperation}
          onChange={(selectedOption) => {
            field.onChange(selectedOption)
            handleChange(selectedOption)
          }}
        />
      )}
    />
  ) : (
    <Select placeholder='Select stage' isLoading={!kanbanstages?.length || isLoadingKanbanStage} />
  )
}

export default KanbanStageDropdown
