import {useState} from 'react'
import useToastify from '../../../hook/useToastify'
import {addToFavorite} from '../service'
import {useGetProjectsList} from './useGetProjectsList'

export default function useAddToFavorites() {
  const {toastMessage} = useToastify()
  const [isLoading, setLoading] = useState(false)

  const {refetchProjectsList} = useGetProjectsList()

  async function addProjectToFavorite(projectId: string) {
    try {
      setLoading(true)
      const response = await addToFavorite(projectId)
      if (response.status === 200) {
        toastMessage('success', `${response.data.message}`)
        refetchProjectsList()
      } else {
        toastMessage('error', `${response.data.message}`)
      }
      setLoading(false)

      return response
    } catch (error: any) {
      setLoading(false)
    }
  }

  return {
    addProjectToFavorite,
    isAdding: isLoading,
  }
}
