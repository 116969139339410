interface UserActionDropdownProps {
  userName: string
  onEdit: any
  handleModel: any
  onResetPassword: any
}

export default function UserActionDropdown(props: UserActionDropdownProps) {
  const {onEdit, handleModel, onResetPassword} = props

  return (
    <div className=''>
      <div className='px-4 py-2' onClick={onEdit}>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Edit</label>
        </span>
      </div>
      <div className='px-4 py-2' onClick={onResetPassword}>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Reset Password</label>
        </span>
      </div>
      <div className='px-4 py-2'>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label onClick={handleModel}>Delete</label>
        </span>
      </div>
    </div>
  )
}
