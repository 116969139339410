import Api from '../../../services/Api'

export const getProjects = (url: string, filters: any) => {
  return Api.get(`${url}`, filters).then((d: any) => {
    return d.data
  })
}

export function addToFavorite(projectId: string) {
  const url = `/agile/projects/${projectId}/fevorite`

  return Api.post(url, null, null, null).then((d: any) => {
    return d
  })
}

export const getBusinessUnits = (url: string) => {
  return Api.get(`${url}`).then((d: any) => {
    return d.data
  })
}

export async function submitNewProjectDetails(url: string, data: any) {
  const response = await Api.post(url, data, null, null)
  return response
}

export async function submitUpdatedProjectDetails(url: string, data: any) {
  const response = await Api.patch(url, data, null, null)
  return response
}

export const getProjectMembersList = (url: string) => {
  return Api.get(url).then((d: any) => {
    return d
  })
}
