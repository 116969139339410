import {useContext, useEffect, useState} from 'react'
import DeleteTicketCommentsModal from '../../../modal/ticketDetails/DeleteTicketCommentsModal'
import {TicketDetailsPageContext} from '../../../../context'
import {Mention, MentionsInput} from 'react-mentions'
import classNames from '../../../../utils/reactMention.module.css'
import classNamesReadOnly from '../../../../utils/reactMentionReadOnly.module.css'
import {useGetMembersList} from '../../../../hooks/members/useGetMembersList'

export const CommentsTab = () => {
  const {
    ticketDetails,
    createComment,
    editComment,
    isLoadingCommentOperation,
    commentsListData,
    isLoadingCommentsListData,
  }: any = useContext(TicketDetailsPageContext)
  const {projectMembersList, isLoadingMemberDetails} = useGetMembersList()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedCommentId, setSelectedCommentId] = useState(null)
  const [commentValue, setCommentValue] = useState('')
  const [editingCommentValue, setEditingCommentValue] = useState()
  const [editCommentId, setEditCommentId] = useState(null)
  const cardId = ticketDetails['tickedID']

  const handleAddComment = () => {
    const commentData = {comment: commentValue}
    createComment(commentData)
  }

  useEffect(() => {
    if (isLoadingCommentsListData) {
      setCommentValue('')
    }
  }, [isLoadingCommentsListData])

  const handleDeleteComment = (commentId: any) => {
    setSelectedCommentId(commentId)
    setIsModalOpen(true)
  }

  const handleEditCommentBtn = (commentId: any, commentValue: any) => {
    setEditCommentId(commentId)
    setEditingCommentValue(commentValue)
  }

  const handleEditComment = async (commentId: any) => {
    const commentData = {comment: editingCommentValue}
    await editComment(commentId, commentData)
    setEditCommentId(null)
  }

  const commentMentionUserList = projectMembersList.map((user: any) => ({
    id: user.username,
    display: user.name,
  }))

  return (
    <>
      <div className='d-flex flex-column gap-8'>
        <MentionsInput
          placeholder='Add comment here'
          classNames={classNames}
          onChange={(e: any) => setCommentValue(e.target.value)}
          allowSpaceInQuery={true}
          value={commentValue}
        >
          <Mention
            className={classNames.mentions__mention}
            trigger='@'
            data={commentMentionUserList}
            appendSpaceOnAdd={true}
            isLoading={isLoadingMemberDetails}
            markup={`<p>__display__#__id__</p>`}
            displayTransform={(id, display) => `@${display}`}
            renderSuggestion={(entry) => {
              return (
                <div>
                  <span className='fw-bolder   text-gray-700'>{entry.display}</span>{' '}
                  <span className='text-muted '>{`(${entry.id})`}</span>
                </div>
              )
            }}
          />
        </MentionsInput>

        <div className='d-flex justify-content-end gap-3'>
          {commentValue.trim() !== '' && (
            <>
              <button onClick={() => setCommentValue('')} type='button' className='btn btn-light'>
                Cancel
              </button>
              <button onClick={handleAddComment} type='button' className='btn btn-primary'>
                {!isLoadingCommentOperation && !isLoadingCommentsListData && (
                  <span className='indicator-label'>Add Comment</span>
                )}
                {isLoadingCommentOperation && !isLoadingCommentsListData && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </>
          )}
        </div>
        {commentsListData &&
          commentsListData.length !== 0 &&
          commentsListData.map((comment: any) => (
            <div className='d-flex gap-4'>
              <div className='w-35px h-35px bg-light-primary rounded-circle border border-1 border-primary border-opacity-50 overflow-hidden d-flex justify-content-center  align-items-center'>
                <span className='fw-bold text-primary fs-3'>{comment.created_by?.charAt(0)}</span>
              </div>
              <div className='d-flex flex-column gap-1 w-100'>
                <div className='fw-bold'>{comment.created_by}</div>
                <div>
                  {editCommentId === comment.id ? (
                    <MentionsInput
                      placeholder='Add comment here'
                      classNames={classNames}
                      onChange={(e: any) => setEditingCommentValue(e.target.value)}
                      allowSpaceInQuery={true}
                      value={editingCommentValue}
                    >
                      <Mention
                        className={classNames.mentions__mention}
                        trigger='@'
                        data={commentMentionUserList}
                        appendSpaceOnAdd={true}
                        isLoading={isLoadingMemberDetails}
                        markup={`<p>__display__#__id__</p>`}
                        displayTransform={(id, display) => `@${display}`}
                        renderSuggestion={(entry) => {
                          return (
                            <div>
                              <span className='fw-bolder   text-gray-700'>{entry.display}</span>{' '}
                              <span className='text-muted '>{`(${entry.id})`}</span>
                            </div>
                          )
                        }}
                      />
                    </MentionsInput>
                  ) : (
                    <MentionsInput
                      classNames={classNamesReadOnly}
                      value={comment.comment}
                      disabled={true}
                    >
                      <Mention
                        className={classNamesReadOnly.mentions__mention}
                        trigger='@'
                        data={commentMentionUserList}
                        markup={`<p>__display__#__id__</p>`}
                        displayTransform={(id, display) => `@${display}`}
                      />
                    </MentionsInput>
                  )}
                </div>
                <div className='d-flex gap-4'>
                  {editCommentId === comment.id ? (
                    <>
                      <button
                        onClick={(e) => {
                          handleEditComment(comment.id)
                          e.preventDefault()
                        }}
                        className='btn btn-link btn-color-muted btn-active-color-primary'
                      >
                        {!isLoadingCommentOperation && !isLoadingCommentsListData && (
                          <span className='indicator-label'>Save</span>
                        )}
                        {isLoadingCommentOperation && !isLoadingCommentsListData && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Saving...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <span
                        onClick={() => setEditCommentId(null)}
                        className='btn btn-link btn-color-muted btn-active-color-danger'
                      >
                        cancel
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        onClick={() => handleEditCommentBtn(comment.id, comment.comment)}
                        className='btn btn-link btn-color-muted btn-active-color-primary'
                      >
                        Edit
                      </span>

                      <span
                        onClick={() => handleDeleteComment(comment.id)}
                        className='btn btn-link btn-color-muted btn-active-color-danger'
                      >
                        Delete
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>

      {isModalOpen && (
        <>
          <DeleteTicketCommentsModal
            commentId={selectedCommentId}
            show={isModalOpen}
            cardId={cardId}
            onClose={() => {
              setIsModalOpen(false)
              setSelectedCommentId(null)
            }}
          />
        </>
      )}
    </>
  )
}
