import {useEffect} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {hideVariantValidationSchema} from '../../validations'
import {yupResolver} from '@hookform/resolvers/yup'
import useHideProduct from '../../hooks/useHideVariants'
import {variantFormDataToSave} from '../../utils'

const defaultValues = {
  variant_sku: '',
  out_of_stock_days: '',
  hide_classification: '',
  hide_product_name_prefix: '',
}

const VariantRuleForm = ({
  onClose,
  isEdit,
  sku,
  outOfDays,
  hide_classification,
  prefix,
  modalData,
}: any) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    control,
  } = useForm({defaultValues, resolver: yupResolver(hideVariantValidationSchema)})

  useEffect(() => {
    // Update the variant_id and out_of_stock_days inputs when id or outOfDays change
    reset({
      variant_sku: sku || '', // Set id or use an empty string if id is falsy
      out_of_stock_days: outOfDays || '', // Set outOfDays or use an empty string if outOfDays is falsy
      hide_classification: hide_classification || '',
      hide_product_name_prefix: prefix || '',
    })
  }, [sku, outOfDays, hide_classification, prefix, reset])

  const {addVariantRule, updateVariantRule, isLoading} = useHideProduct()

  const onSubmit = async (data: any) => {
    const formatedData = variantFormDataToSave(data)
    if (isEdit) {
      await updateVariantRule(formatedData)
    } else {
      await addVariantRule(formatedData)
    }
    onClose()
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <>
            <div className='mb-8'>
              <label className='form-label required'>Variant SKU</label>
              <input
                type='text'
                id='variant_sku'
                defaultValue={sku || ''}
                disabled={isEdit}
                className={`form-control ${errors?.variant_sku ? 'is-invalid' : ''}`}
                {...register('variant_sku', {required: 'Variant SKU is required'})}
              />
              {errors?.variant_sku && (
                <div className='invalid-feedback'>{errors?.variant_sku?.message}</div>
              )}
            </div>
            <div className='mb-8'>
              <label className='form-label required'>Out of Stock Days</label>
              <input
                type='text'
                id='out_of_stock_days'
                defaultValue={outOfDays || ''}
                className={`form-control ${errors?.out_of_stock_days ? 'is-invalid' : ''}`}
                {...register('out_of_stock_days', {required: 'Out of Stock Days is required'})}
              />
              {errors?.out_of_stock_days && (
                <div className='invalid-feedback'>{errors?.out_of_stock_days?.message}</div>
              )}
            </div>
            <div className='mb-5 mt-12'>
              <h4 className='fw-bold text-dark'>SKU Vault Setting</h4>
            </div>
            <div>
              <div className='mb-8'>
                <label className='form-label'>Classification</label>
                <Controller
                  control={control}
                  name='hide_classification'
                  render={({field: {name, value, onChange}}) => (
                    <select
                      className='form-select'
                      data-kt-select2='true'
                      data-placeholder='Select Classification'
                      data-allow-clear='true'
                      defaultValue={''}
                      name={name}
                      value={value}
                      onChange={(e: any) => {
                        onChange(e?.target?.value)
                      }}
                    >
                      <option value=''>Select Classification</option>
                      {modalData &&
                        modalData?.['classification'] &&
                        modalData?.['classification'].map((classification: any) => (
                          <option value={classification}>{classification}</option>
                        ))}
                    </select>
                  )}
                />
              </div>
            </div>
            <div>
              <div>
                <label className='form-label'>Product Name Prefix</label>
                <input
                  type='text'
                  id='hide_product_name_prefix'
                  className={`form-control ${
                    errors?.hide_product_name_prefix?.message ? 'is-invalid' : ''
                  }`}
                  {...register('hide_product_name_prefix')}
                />
              </div>
            </div>
          </>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-outline btn-active-light-primary'
            onClick={onClose}
          >
            Cancel
          </button>
          <button type='submit' className='btn btn-primary'>
            {isLoading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : isEdit ? (
              <span className='indicator-label'>update</span>
            ) : (
              <span className='indicator-label'>Save</span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default VariantRuleForm
