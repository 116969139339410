import {getFormattedDate} from '../../../utils/date'

export const fromToTicketData = (data: any) => {
  return {
    reqObj: {
      current_column_id: data['stage']['value'],
      status: data['status']['value'] === undefined ? 0 : data['status']['value'],
      priority: data['priority']['value'] === undefined ? 0 : data['priority']['value'],
      title: data['ticket_title'],
      description: data['description'] === undefined ? '' : data['description'],
      assigned_to: data['assignee']['value'] === undefined ? '' : data['assignee']['value'],
      spent_time: data['spent_time'] === undefined ? '' : data['spent_time'],
      estimation: data['estimation'] === undefined ? '' : data['estimation'],
      due_date:
        getFormattedDate(data['due_date']) === '1970-01-01'
          ? ''
          : getFormattedDate(data['due_date']),
      is_archived: false,
    },
    moduleId: data['module']['value'],
  }
}

export const dataToTicketDetails = (data: any) => {
  return {
    title: data['title'],
    description: data['description'],
    status: data['status'],
    priority: data['priority'],
    assignee: data['assignee'],
    spent_time: data['spent_time'],
    estimation: data['estimation'],
    due_date: data['due_date'],
    start_date: data['start_date'],
  }
}

export const dataToKanbanStagesOptions = (data: any) => {
  return data.map((item: any) => {
    return {
      value: item.column_id,
      label: item.name,
    }
  })
}

export const dataToModuleOptions = (data: any) => {
  return data.map((item: any) => {
    return {
      value: item.module_id,
      label: item.name,
    }
  })
}

export const dataToCardStatusOptions = (data: any) => {
  return data.map((item: any) => {
    return {
      value: item.id,
      label: item.label,
    }
  })
}

export const dataToCardPriorityOptions = (data: any) => {
  return data.map((item: any) => {
    return {
      value: item.id,
      label: item.label,
    }
  })
}
