export function updateColumnCardsCounter(tasks: any, formatedData: any, setColumnTaskCounts: any) {
  const columnCounts: any = {}

  tasks.forEach((task: any) => {
    const columnName = formatedData.find((column: any) => column.columnID === task.columnID)?.columnName

    if (columnName) {
      columnCounts[columnName] = (columnCounts[columnName] || 0) + 1
    }
  })

  setColumnTaskCounts(columnCounts)
}
