export function capitalizeFirstLetter(string: string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1)
}

export function formatForLink(inputString: string) {
  return inputString.replace(/\s+/g, '-')
}

export function wordsLimit(title: any, wordsLimit: number) {
  const words = title.split(' ')
  if (words.length > wordsLimit) {
    return words.slice(0, wordsLimit).join(' ') + '...'
  }
  return title
}

export function toCamelCase(str: string) {
  return str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
}
/**
* Extracts a specified number of path segments from the end of a given URL.

* // Extract the last two segments
* extractPath("/admin/api/static/images/banner", 2);
* // Output: "images/banner"
*  */

export function extractPath(url: string, segments = 1) {
  const pathParts = url.split('/').filter(Boolean)
  if (segments <= 0 || segments > pathParts.length) {
    return pathParts.join('/')
  }
  return pathParts.slice(-segments).join('/')
}

export function toSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const getLastPathSegment = (location: any) => {
  return location?.pathname?.split('/').slice(-1)[0]
}

export function toTitleCase(
  input: string | undefined,
  isCapitalizeFirstLetter: boolean = false,
  isToLowerCase: boolean = false
): string {
  if (!input) {
    return '' // Handle undefined, null, or other falsy values
  }

  let formatted = input
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space between camelCase words
    .replace(/[_-]/g, ' ') // Replace dashes and underscores with spaces
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .trim() // Remove leading and trailing spaces

  if (isToLowerCase) {
    formatted = formatted.toLowerCase()
  }

  if (isCapitalizeFirstLetter) {
    formatted = capitalizeFirstLetter(formatted)
  } else if (!isCapitalizeFirstLetter && !isToLowerCase) {
    formatted = formatted.replace(/\b\w/g, (char) => char.toUpperCase())
  }

  return formatted
}

export function convertToNumber(input: string | null | undefined): number | null {
  if (input === null || input === undefined || input === '') {
    return null
  }
  // If the input is already a number, return it as it is
  if (typeof input === 'number') {
    return input
  }

  // Create a copy of the input string for processing
  const originalInput = input?.trim()

  // Regular expression to check if the string is a valid number or not
  const numberPattern = /^-?\d{1,3}(?:,\d{3})*(?:\.\d+)?$|^-?\d+(\.\d+)?$/

  // Check if the input matches the number pattern
  if (!numberPattern?.test(originalInput)) {
    return null
  }
  // Create a sanitized copy of the input by removing commas
  const sanitizedInput = originalInput?.replace(/,/g, '')

  // Parse the sanitized input as a float
  const parsedNumber = parseFloat(sanitizedInput)

  // Check if the parsed number is NaN, which indicates invalid input
  return isNaN(parsedNumber) ? null : parsedNumber
}

export function formatStringNumberType(input: string): number | string {
  // Check if the input string contains only digits or a decimal point
  const isInteger = /^-?\d+$/.test(input)
  const isFloat = /^-?\d*\.\d+$/.test(input)

  // If it's an integer, return as number
  if (isInteger) {
    return parseInt(input, 10)
  }

  // If it's a float, return as number
  if (isFloat) {
    return parseFloat(input)
  }

  // Otherwise, return the input as a string
  return input
}
