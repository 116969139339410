import {createContext} from 'react'

export const ProjectsListPageContext = createContext({
  projectsList: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  onSearch: (e: string) => {},
  filters: {},
  businessUnitsList: [],
  isLoadingBusinessUnits: false,
  updateProjectDetails: (updatedProjectDetails: any, projectId: number) => {},
  createNewProjectDetails: (projectDetails: any) => {},
  refetchProjectsList: () => {},
  onClickSorting: (sortBy: string, sortDirection: any) => {},
  addProjectToFavorite: (projectId: string) => {},
  isAdding: false, 
})