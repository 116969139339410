import {useQuery} from 'react-query'
import {getAllCardStatus} from '../../service'
import {dataToCardStatusOptions} from '../../utils/agile-board'

const useGetTicketStatus = () => {
  const {
    data: ticketStatus,
    isFetching,
    refetch,
  } = useQuery(`get-all-card-status`, () => {
    return getAllCardStatus()
  })

  return {
    ticketStatus: ticketStatus?.['data']?.length
      ? dataToCardStatusOptions(ticketStatus['data'])
      : [],
    isLoadingTicketStatus: isFetching,
    refetchTicketStatus: refetch,
  }
}

export default useGetTicketStatus
