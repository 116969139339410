// Function to convert
// single digit input
// to two digits
const formatData = (input: number) => {
  if (input > 9) {
    return input
  } else return `0${input}`
}

// Function to convert
// 24 Hour to 12 Hour clock
const formatHour = (input: number) => {
  if (input > 12) {
    return input - 12
  }
  return input
}

export const format24Hour = (currDate: any, isDate: boolean) => {
  const date = new Date(currDate)
  const format = {
    dd: formatData(date.getDate()),
    mm: formatData(date.getMonth() + 1),
    yyyy: date.getFullYear(),
    HH: formatData(date.getHours()),
    hh: formatData(formatHour(date.getHours())),
    MM: formatData(date.getMinutes()),
    SS: formatData(date.getSeconds()),
  }

  if (isDate) {
    return `${format?.mm}/${format?.dd}/${format?.yyyy}`
  }

  return `${format?.mm}/${format?.dd}/${format?.yyyy} ${format?.HH}:${format?.MM}`
}

export const format12Hour = (currDate: any, isDate: boolean) => {
  const date = new Date(currDate)
  const format = {
    dd: formatData(date.getDate()),
    mm: formatData(date.getMonth() + 1),
    yyyy: date.getFullYear(),
    HH: formatData(date.getHours()),
    hh: formatData(formatHour(date.getHours())),
    MM: formatData(date.getMinutes()),
    SS: formatData(date.getSeconds()),
  }
  if (isDate) {
    return `${format?.mm}/${format?.dd}/${format?.yyyy}`
  }
  return `${format?.mm}/${format?.dd}/${format?.yyyy} ${format?.hh}:${format?.MM}`
}

export const formatDate = (currDate: string | number, isDateOnly: boolean) => {
  if (!currDate) return '-'
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const isTimeStamp = typeof currDate === 'number'

  if (isTimeStamp) {
    const date = new Date(currDate * 1000)

    if(isDateOnly){
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
      return formattedDate
    }else{

      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      return formattedDate
    }

  } else {
    const date = new Date(typeof currDate === 'number' ? currDate * 1000 : currDate)

    // Convert to CST
    const cstOffset = -6 * 60 // CST is UTC-6
    const cstTimestamp = date.getTime() + cstOffset * 60 * 1000
    const cstDate = new Date(cstTimestamp)

    const format = {
      dd: formatData(cstDate.getDate()),
      month: months[cstDate.getMonth()],
      yyyy: cstDate.getFullYear(),
      hh: formatHourNew(cstDate.getHours()),
      MM: formatDataNew(cstDate.getMinutes()),
      period: formatPeriod(cstDate.getHours()),
    }

    if (isDateOnly) {
      return `${format.dd} ${format.month}  ${format.yyyy}`
    }

    return `${format.month} ${format.dd}, ${format.yyyy}, ${format.hh}:${format.MM} ${format.period}`
  }
}

const formatDataNew = (value: number) => {
  return value.toString().padStart(2, '0')
}

const formatHourNew = (value: number) => {
  return value % 12 === 0 ? '12' : formatData(value % 12)
}

const formatPeriod = (value: number) => {
  return value < 12 ? 'AM' : 'PM'
}

// export const convertTimeStampToDate = (timestamp: number) => {
//   const date = new Date(timestamp * 1000)

//   const formattedDate = date.toLocaleString('en-US', {
//     year: 'numeric',
//     month: 'short',
//     day: 'numeric',
//     hour: 'numeric',
//     minute: 'numeric',
//     hour12: true,
//   })

//   return formattedDate
// }

export const MONTHS_LONG = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const getCurrentDate = () => {
  const currentDate = new Date()

  const year = currentDate.getFullYear()

  // Months are zero-based
  const month = currentDate.getMonth() + 1

  const day = currentDate.getDate()

  return `${day}-${month}-${year}`
}


export function getFormattedDate(dateString:any) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // Adding zero-padding if needed
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${year}-${formattedMonth}-${formattedDay}`;
}