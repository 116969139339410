import {createContext} from 'react'
export const HideVariantPagesContext = createContext({
  variantRules: [],
  onSearch: (searchValue: string) => {},
  pagination: {},
  onPageChange: (e: string) => {},
  addVariantRule: (e: any) => {},
  deleteVariantRule: (e: string) => {},
  updateVariantRule: (e: any) => {},  
  refetch: () => {},
  onSortingChange: (key: string, value: string) => {},
  filters: {},
  isLoading: false,
  isFetching: false,
})


export const HideProductPagesContext = createContext({
  productRules: [],
  onSearch: (searchValue: string) => {},
  pagination: {},
  onPageChange: (e: string) => {},
  addProductRule: (e: any) => {},
  deleteProductRule: (e: string) => {},
  updateProductRule: (e: any) => {},
  refetch: () => {},
  onSortingChange: (key: string, value: string) => {},
  filters: {},
  isLoading: false,
  isFetching: false,
})
