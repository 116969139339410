import {useContext, useEffect, useState} from 'react'
import {ProjectsListPageContext} from '../../context'
import ActiveProjectsTableRow from './ActiveProjectsTableRow'
import Loading from '../../../loading'
import {toggleSortingOrder} from '../../../../utils/common'
import {RenderBothArrow} from '../../../../utils/renderBothArrow'
import NoDataFound from '../../../../components/NoDataFound'
import usePermission from '../../../../hook/usePermission'

export default function ActiveProjectsTable() {
  const {hasPermission} = usePermission()
  const {projectsList, isLoading, isCreating, isUpdating, filters, onClickSorting}: any =
    useContext(ProjectsListPageContext)

  const [direction, setDirection] = useState('')
  const [sortBy, setSortBy] = useState('')

  useEffect(() => {
    if (filters) {
      const array = filters['sort_by'].split('/')
      setSortBy(array[0])
      if (array[1] === '1') {
        setDirection('asc')
      } else {
        setDirection('desc')
      }
    }
  }, [filters])

  return (
    <div className='card-body p-0'>
      <div className='table-responsive position-relative'>
        <div className='table-loader-wrapper'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-15'>
            <thead className='table-row-bordered'>
              <tr className='fs-6 fw-semibold text-muted'>
                <th
                  className='min-w-250px'
                  onClick={() => onClickSorting('name', toggleSortingOrder(direction))}
                >
                  <div className='d-flex align-items-center cursor-pointer'>
                    <span className='me-3'>{'NAME'}</span>

                    {direction === 'asc' && sortBy === 'name' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'name' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className='min-w-175px'
                  onClick={() => onClickSorting('business_unit', toggleSortingOrder(direction))}
                >
                  <div className='d-flex align-items-center cursor-pointer'>
                    <span className='me-3'>{'BUSINESS UNIT'}</span>

                    {direction === 'asc' && sortBy === 'business_unit' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'business_unit' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th className='min-w-125px text-center'>{'MEMBERS'}</th>
                <th
                  className='min-w-200px'
                  onClick={() => onClickSorting('owner_username', toggleSortingOrder(direction))}
                >
                  <div className='d-flex align-items-center cursor-pointer'>
                    <span className='me-3'>{'OWNER'}</span>

                    {direction === 'asc' && sortBy === 'owner_username' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'owner_username' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className='min-w-200px'
                  onClick={() => onClickSorting('created_at', toggleSortingOrder(direction))}
                >
                  <div className='d-flex align-items-center cursor-pointer'>
                    <span className='me-3'>{'CREATED DATE'}</span>

                    {direction === 'asc' && sortBy === 'created_at' ? (
                      <i className='bi text-danger  bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'created_at' ? (
                      <i className='bi text-muted bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className='min-w-200px'
                  onClick={() => onClickSorting('updated_at', toggleSortingOrder(direction))}
                >
                  <div className='d-flex align-items-center cursor-pointer'>
                    <span className='me-3'>{'MODIFIED AT'}</span>

                    {direction === 'asc' && sortBy === 'updated_at' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'updated_at' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                {hasPermission('projects_all projects', 'write') && (
                  <th className='w-80px'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <span>{'ACTION'}</span>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {projectsList.map((row: any) => (
                <ActiveProjectsTableRow key={row['id']} row={row} />
              ))}
              {!isLoading && projectsList.length === 0 && <NoDataFound colspan={7} />}
            </tbody>
          </table>
          {(isCreating || isUpdating || isLoading) && <Loading />}
        </div>
      </div>
    </div>
  )
}
