import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useContext} from 'react'
import {BoardSettingsPageContext} from '../../../../context'

interface DeleteColumnModalTypes {
  columnId: any
  show: boolean
  onClose: () => void
}

const DeleteColumnModal = ({columnId, show, onClose}: DeleteColumnModalTypes) => {
  const { deleteColumn, isLoadingColumnOperation} =
    useContext(BoardSettingsPageContext)

  if (!show) {
    return null
  }

  const onClickDelete = async () => {
    await deleteColumn(columnId)
    onClose()
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>Are you sure ?</h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <p>
            Do you really want to remove this column? <br></br>This action is irreversible.
          </p>
        </div>

        <div className='modal-footer'>
          <button type='button' className='btn btn-outline btn-active-light-primary' onClick={onClose}>
            No
          </button>
          <button type='submit' className='btn btn-danger' onClick={onClickDelete}>
            {!isLoadingColumnOperation && <span className='indicator-label'>Yes, Remove</span>}

            {isLoadingColumnOperation && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Removing...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteColumnModal
