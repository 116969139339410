import {useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import CustomFieldsModal from '../modal/customFields/CustomFieldsModal'
import DeleteCustomFieldsModal from '../modal/customFields/DeleteCustomFieldsModal'

const CustomFieldActionsOverlay = ({customFieldId, customFieldData}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rowId, setRowId] = useState(customFieldId || null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleEditClick = () => {
    setRowId(customFieldId)
    setIsModalOpen(true)
    document.body.click()
  }

  const handleDeleteClick = () => {
    setRowId(customFieldId)
    setIsDeleteModalOpen(true)
    document.body.click()
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <div className=' d-flex align-items-start flex-column'>
            <span className='btn btn-active-text-primary' onClick={() => handleEditClick()}>
              Edit
            </span>
            <span onClick={() => handleDeleteClick()} className='btn btn-active-text-danger'>
              Delete
            </span>
          </div>
        }
      />

      <CustomFieldsModal
        show={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setRowId(null)
        }}
        editId={rowId}
        customFieldData={customFieldData}
      />

      <DeleteCustomFieldsModal
        show={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false)
          setRowId(null)
        }}
        deleteCustomFieldId={rowId}
      />
    </>
  )
}

export default CustomFieldActionsOverlay
