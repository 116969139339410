import {Link} from 'react-router-dom'
import {EnableSidebar, PageTitle} from '../../_metronic/layout/core'
import useMeta from '../../hook/useMeta'

const DashboardPage = () => {
  return (
    <>
      <div className='row g-xl-custom-7 d-flex flex-row justify-content-between mb-7'>
        <div className='col-md-4 mt-0'>
          <Link to='/analytics/replenishment' className='text-dark'>
            <div className='card card-flush h-xl-100 bg-light-success bg-light-danger'>
              <div className='card-body p-7'>
                <p className='fs-1 fw-bold text-uppercase mb-0 text-center h-100px d-flex align-items-center justify-content-center'>
                  Replenishment
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-md-4 mt-0'>
          <Link to='/analytics/sold-products/dashboard' className='text-dark'>
            <div className='card card-flush h-xl-100 bg-light-success bg-light-warning'>
              <div className='card-body p-7'>
                <p className='fs-1 fw-bold text-uppercase mb-0 text-center h-100px d-flex align-items-center justify-content-center'>
                  Sold Products
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div className='col-md-4 mt-0'>
          <Link to='/analytics/consignments/dashboard' className='text-dark'>
            <div className='card card-flush h-xl-100 bg-light-success'>
              <div className='card-body p-7'>
                <p className='fs-1 fw-bold text-uppercase mb-0 text-center h-100px d-flex align-items-center justify-content-center'>
                  Consignments
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}
const DashboardWrapper = () => {
  useMeta('Dashboard')

  return (
    <EnableSidebar>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <DashboardPage />
    </EnableSidebar>
  )
}

export {DashboardWrapper}
