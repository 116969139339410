import {Pagination} from '../../../utils/pagination'
import ProjectsDefaultColumnsHeader from '../components/header/ProjectsDefaultColumnsHeader'
import { ProjectsDefaultColumnsPageContext } from '../context'
import useGetGlobalDefaultColumnsList from '../hooks/defaultColumns/useGetGlobalDefaultColumnsList'
import useGlobalDefaultColumnsOperations from '../hooks/defaultColumns/useGlobalDefaultColumnsOperations'
import DefaultColumnsTable from '../components/tables/defaultColumns/DefaultColumnsTable'

export default function ProjectsDefaultColumns() {

  const {globalDefaultColumnsList, isLoadingGlobalDefaultColumnsList, refetchGlobalDefaultColumnsList, pagination, onSearch, onPageChange } = useGetGlobalDefaultColumnsList()
  const {createGlobalDefaultColumn, updateGlobalDefaultColumn, updateGlobalDefaultColumnsTableSorting, deleteGlobalDefaultColumn, isLoadingGlobalDefaultColumnOperation} = useGlobalDefaultColumnsOperations()

  const contextValue = {
    globalDefaultColumnsList: globalDefaultColumnsList,
    isLoadingGlobalDefaultColumnsList: isLoadingGlobalDefaultColumnsList,
    refetchGlobalDefaultColumnsList: refetchGlobalDefaultColumnsList,
    pagination: pagination,
    onSearch: onSearch,
    onPageChange: onPageChange,

    createGlobalDefaultColumn: createGlobalDefaultColumn,
    updateGlobalDefaultColumn: updateGlobalDefaultColumn,
    updateGlobalDefaultColumnsTableSorting: updateGlobalDefaultColumnsTableSorting,
    deleteGlobalDefaultColumn: deleteGlobalDefaultColumn,
    isLoadingGlobalDefaultColumnOperation: isLoadingGlobalDefaultColumnOperation,
  }

  return (
    <>
      <ProjectsDefaultColumnsPageContext.Provider value={contextValue}>
        <ProjectsDefaultColumnsHeader />
        <DefaultColumnsTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </ProjectsDefaultColumnsPageContext.Provider>
    </>
  )
}
