import {useContext, useState} from 'react'
import useToastify from '../../../../hook/useToastify'
import {addNewMember} from '../../service'
import {ProjectsMainPageContext} from '../../context'
import {useGetMembersList} from './useGetMembersList'

export default function useAddNewProjectMember() {
  const {projectId} = useContext(ProjectsMainPageContext)

  const {toastMessage} = useToastify()
  const [isLoading, setLoading] = useState(false)
  const {refetchMembersList} = useGetMembersList()

  async function AddNewProjectMember(memberDetails: any) {
    try {
      setLoading(true)
      const response = await addNewMember(`/agile/projects/${projectId}/members`, memberDetails)
      if (response.status === 200) {
        toastMessage('success', `Member added successfully`)
        refetchMembersList()
      } else {
        toastMessage('error', `${response.data.message}`)
      }
      setLoading(false)

      return response
    } catch (error: any) {
      setLoading(false)
    }
  }

  return {
    AddNewProjectMember,
    isAddingProjectMember: isLoading,
  }
}
