import {Modal} from 'react-bootstrap'
import {useForm, Controller} from 'react-hook-form'
import Select from 'react-select'
import {KTSVG} from '../../../../../../_metronic/helpers'
import useGetGlobalCustomFieldsList from '../../../../../projectsSettings/hooks/customFields/useGetGlobalCustomFieldsList'
import {BoardSettingsPageContext} from '../../../../context'
import {useContext, useEffect} from 'react'

interface AddNewCustomFieldModalTypes {
  show: boolean
  editId?: any
  onClose: () => void
  customFieldData: any
}

const CustomFieldsModal = ({
  show,
  onClose,
  editId,
  customFieldData,
}: AddNewCustomFieldModalTypes) => {
  const {globalCustomFieldsList} = useGetGlobalCustomFieldsList()
  const {
    createCustomField,
    updateCustomField,
    isLoadingCustomFieldList,
    isLoadingCustomFieldOperation,
  } = useContext(BoardSettingsPageContext)
  const {handleSubmit, control, register} = useForm<any>()

  useEffect(() => {
    if (isLoadingCustomFieldList) {
      onClose()
    }
  }, [isLoadingCustomFieldList, onClose])

  const onSubmit = (data: any) => {
    if (customFieldData.length === 0) {
      const createCustomFieldData = {
        name: data.customFields.label,
        customfield_meta_id: data.customFields.value,
      }
      createCustomField(createCustomFieldData)
    } else {
      const cFieldId = customFieldData.id
      const updatedCustomFieldData = {
        name: data.custom_field_name,
      }
      updateCustomField(cFieldId, updatedCustomFieldData)
    }
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_stacked_1'
      data-backdrop='static'
      tabIndex={-1}
      show={show}
      dialogClassName='modal-1'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{editId ? 'Edit' : 'Add'} Field</h4>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-body'>
            {customFieldData && (
              <div className='mb-8'>
                <label htmlFor={`custom_field_name`} className='required form-label'>
                  New Text Field
                </label>
                <input
                  type='text'
                  id={`custom_field_name`}
                  className={`form-control`}
                  placeholder='Enter new name'
                  defaultValue={customFieldData.custom_field_name}
                  {...register('custom_field_name', {required: true})}
                />
              </div>
            )}

            <div className='mb-8'>
              <label htmlFor={`customFields`} className='required form-label'>
                Custom Field
              </label>
              <Controller
                name='customFields'
                control={control}
                render={({field, fieldState}) => (
                  <>
                    <Select
                      {...field}
                      className={`react-select-styled ${fieldState.invalid ? 'is-invalid' : ''}`}
                      placeholder='Select field type'
                      isDisabled={customFieldData ? true : false}
                      options={globalCustomFieldsList.map((item: any) => ({
                        label: item.custom_field_name,
                        value: item.custom_field_id,
                      }))}
                      defaultValue={
                        customFieldData
                          ? {
                              label: customFieldData.custom_field_name,
                              value: customFieldData.id,
                            }
                          : null
                      }
                    />
                    {fieldState.invalid && (
                      <div className='invalid-feedback'>Custom field name is required.</div>
                    )}
                  </>
                )}
                rules={{required: 'Custom field name is required'}}
              />
            </div>
          </div>

          <div className='modal-footer'>
            <button type='button' className='btn btn-outline btn-active-light-primary' onClick={onClose}>
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              {!isLoadingCustomFieldOperation && (
                <span className='indicator-label'>{customFieldData ? 'Update' : 'Add'}</span>
              )}

              {isLoadingCustomFieldOperation && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {customFieldData ? 'Updating...' : 'Adding...'}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default CustomFieldsModal
