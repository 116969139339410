import { DragEndEvent } from '@dnd-kit/core';

export function handleDragEnd(event: DragEndEvent, handleUpdatedTableRows: (tableData: any) => void, tableData: any, setActiveRow: (activeRow: any) => void) {
  handleUpdatedTableRows(tableData);
  setActiveRow(null);

  const { active, over } = event;
  if (!over) return;

  const activeId = active.id;
  const overId = over.id;

  if (activeId === overId) return;

  const isActiveAColumn = active.data.current?.type === 'Column';
  if (!isActiveAColumn) return;
}