import {useContext} from 'react'
import {ProjectsMembersPageContext} from '../../../context'
import GlobalProjectsMemberPageTableRow from './GlobalProjectsMemberPageTableRow'
import Loading from '../../../../loading'
import NoDataFound from '../../../../../components/NoDataFound'
import usePermission from '../../../../../hook/usePermission'

export default function GlobalProjectsMemberPageTable() {
  const {hasPermission} = usePermission()
  const {globalProjectsMembersList, isLoadingGlobalProjectsMembersList} = useContext(
    ProjectsMembersPageContext
  )

  return (
    <div className='card-body p-0'>
      <div className='table-responsive position-relative'>
        <div className='table-loader-wrapper'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-15'>
            <thead className='table-row-bordered'>
              <tr className='fs-6 fw-semibold text-muted'>
                <th className='min-w-180px'>{'NAME'}</th>
                <th className='w-125px'>{'PROJECTS'}</th>
                <th className='w-225px'>{'REGISTRATION DATE'}</th>
                <th className='w-150px text-center'>{'STATUS'}</th>
                {hasPermission('projects_settings', 'write') && (
                  <th className='w-80px'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <span>{'ACTION'}</span>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {globalProjectsMembersList.map((row: any) => (
                <GlobalProjectsMemberPageTableRow key={row['member_id']} row={row} />
              ))}
              {!isLoadingGlobalProjectsMembersList && globalProjectsMembersList.length === 0 && (
                <NoDataFound colspan={5} />
              )}
            </tbody>
          </table>
          {isLoadingGlobalProjectsMembersList && <Loading />}
        </div>
      </div>
    </div>
  )
}
