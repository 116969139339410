import useToastify from '../../../../hook/useToastify'
import {addColumn, editColumn, removeColumn, updateBoardColumnTableSorting} from '../../service'
import {useAppSelector} from '../../../../redux/useTypedSelector'
import {ProjectsMainPageContext} from '../../context'
import {useContext, useState} from 'react'
import useGetAllColumns from './useGetAllColumns'

function useColumnOperations() {
  const {toastMessage} = useToastify()
  const {user} = useAppSelector((state) => state.auth)
  const {projectId} = useContext(ProjectsMainPageContext)
  const {refetchColumns} = useGetAllColumns()
  const [isLoading, setIsLoading] = useState(false)

  async function createColumn(data: any) {
    setIsLoading(true)
    const res: any = await addColumn(projectId, {
      name: data['column_name'],
      description: data['description'],
      is_archived: false,
    })
    if (res.status === 200) {
      toastMessage('success', `Column created successfully`)
      setIsLoading(false)
      refetchColumns()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function updateColumn(columnId: string, data: any) {
    setIsLoading(true)
    const res: any = await editColumn(projectId, columnId, {
      name: data['column_name'],
      description: data['description'],
      updated_by: user['username'],
    })
    if (res.status === 200) {
      toastMessage('success', `Column updated successfully`)
      setIsLoading(false)
      refetchColumns()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function updateColumnTableSorting(columnTableRowOrder: any) {
    setIsLoading(true)
    const tableName = 'columns'
    const res: any = await updateBoardColumnTableSorting(projectId, tableName, columnTableRowOrder)
    if (res.status === 200) {
      toastMessage('success', `Updated successfully`)
      setIsLoading(false)
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function deleteColumn(columnId: string) {
    setIsLoading(true)
    const res: any = await removeColumn(projectId, columnId)
    if (res.status === 200) {
      toastMessage('success', `Column deleted successfully`)
      setIsLoading(false)
      refetchColumns()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  return {
    createColumn,
    updateColumn,
    updateColumnTableSorting,
    deleteColumn,
    isLoadingColumnOperation: isLoading,
  }
}

export default useColumnOperations
