/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import isEmpty from 'lodash/isEmpty'
import { addStaticMenuItems } from './addStaticMenuItems'
import usePermission from '../../../../hook/usePermission'

const icon_base_url = '/media/ad-theme-icons/'

type Props = {
  isToggleActive: boolean
}

export function AsideMenuMain({isToggleActive}: Props) {
  const intl = useIntl()
  const {menu} = usePermission()

  // const staticMenuItems = [
  //   {
  //     path: ['orders', 'bulk orders'],
  //     position: 1,
  //     staticObj: {
  //       label: 'Dashboard',
  //       route: '/dashboard',
  //       icon: '',
  //       children: {},
  //     },
  //   },
  // ]

  // Adds a static menu object to the specified path within Aside Menu structure
  const updatedMenuData = addStaticMenuItems(menu)

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        icon={`${icon_base_url}dashboard.svg`}
      />

      {Object.entries(updatedMenuData).map(([, item]: any) => {
        if (!isEmpty(item.children)) {
          const firstChildKey = Object.keys(item.children)[0] // Get the first child's key
          const firstChild = item.children[firstChildKey]
          return (
            <AsideMenuItemWithSub
              to={item.route}
              title={item.label}
              icon={`${icon_base_url}${item.icon}`}
              key={item.label}
              isToggleActive={isToggleActive}
              firstChildRoute={firstChild?.route}
            >
              {Object.entries(item.children).map(([, child_item]: any) => {
                if (child_item.label === 'Components') {
                  return null
                } else {
                  if (!isEmpty(child_item.children)) {
                    return (
                      <AsideMenuItemWithSub
                        to={child_item.route}
                        title={child_item.label}
                        key={child_item.label}
                        isToggleActive={isToggleActive}
                        firstChildRoute={firstChild?.route}
                      >
                        {Object.entries(child_item.children).map(([, second_child_item]: any) => {
                          if (second_child_item.label === 'Components') {
                            return null
                          } else {
                            if (!isEmpty(second_child_item.children)) {
                              return (
                                <AsideMenuItemWithSub
                                  to={second_child_item.route}
                                  title={second_child_item.label}
                                  key={second_child_item.label}
                                  isToggleActive={isToggleActive}
                                  firstChildRoute={firstChild?.route}
                                >
                                  {Object.entries(second_child_item.children).map(
                                    ([, third_child_item]: any) => {
                                      if (third_child_item.label === 'Components') {
                                        return null
                                      } else {
                                        return (
                                          <div>
                                            <AsideMenuItem
                                              to={`${child_item.route}${second_child_item.route}${third_child_item.route}`}
                                              title={third_child_item.label}
                                              key={third_child_item['label']}
                                            />
                                          </div>
                                        )
                                      }
                                    }
                                  )}
                                </AsideMenuItemWithSub>
                              )
                            } else {
                              return (
                                <div>
                                  <AsideMenuItem
                                    to={`${child_item.route}${second_child_item.route}`}
                                    title={second_child_item.label}
                                    key={second_child_item['label']}
                                  />
                                </div>
                              )
                            }
                          }
                        })}
                      </AsideMenuItemWithSub>
                    )
                  } else {
                    return (
                      <AsideMenuItem
                        to={child_item.route}
                        title={child_item.label}
                        key={child_item['label']}
                      />
                    )
                  }
                }
              })}
            </AsideMenuItemWithSub>
          )
        } else {
          return (
            <AsideMenuItem
              to={item.route}
              title={item.label}
              icon={`${icon_base_url}${item.icon}`}
              key={item.label}
            />
          )
        }
      })}

      {/* <div className='menu-item menu-item-parent'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div> */}
    </>
  )
}
