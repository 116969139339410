import {useEffect} from 'react'
import useFilters from '../../../../hook/useFilters'
import {useQuery} from 'react-query'
import { getGlobalProjectsMembersList } from '../../services'

const initialFilters = {
  page: 1,
  limit: 10,
  search_value: '',
}

export const useGetGlobalProjectsMembersList = () => {

  const {filters, setSingleFilter, setMultipleFilters} = useFilters(initialFilters)

  const {
    data: globalProjectsMembersList,
    isFetching,
    refetch,
  } = useQuery(`get-global-projects-members-list`, () => {
    return getGlobalProjectsMembersList(filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onSearch = (e: string) => {
    setMultipleFilters({
      search_value: e,
      page: 1,
    })
  }

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  return {
    globalProjectsMembersList: globalProjectsMembersList?.['data'] || [],
    isLoadingGlobalProjectsMembersList: isFetching,
    pagination: globalProjectsMembersList?.['meta']?.['pagination'] || {},
    onPageChange,
    onSearch,
    refetchGlobalProjectsMembersList: refetch,
  }
}
