import {useContext, useState} from 'react'
import AddGlobalProjectMembersModal from '../modal/members/AddGlobalProjectMembersModal'
import ProjectsSettingsSearchComponent from './ProjectsSettingsSearchComponent'
import { ProjectsMembersPageContext } from '../../context'
import usePermission from '../../../../hook/usePermission'

const ProjectsMembersPageHeader = () => {
  const {hasPermission} = usePermission()
  const [openMemberModal, setOpenMemberModal] = useState(false)
  const {onSearch} = useContext(ProjectsMembersPageContext)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <ProjectsSettingsSearchComponent onSearch={onSearch} />
      {hasPermission('projects_settings', 'write') && (
        <button type='button' onClick={() => setOpenMemberModal(true)} className='btn btn-primary'>
          <i className='fa-solid fa-plus'></i>Add Member
        </button>
      )}

      <AddGlobalProjectMembersModal
        projectMemberModalDetails={null}
        show={openMemberModal}
        onClose={() => setOpenMemberModal(false)}
      />
    </div>
  )
}

export default ProjectsMembersPageHeader
