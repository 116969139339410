import {DatePicker} from 'rsuite'
import {Controller, useFormContext} from 'react-hook-form'
import {useContext, useEffect, useState} from 'react'
import {TicketDetailsPageContext} from '../../../context'
import Select from 'react-select'
import {getFormattedDate} from '../../../../../utils/date'
import useTicketOperations from '../../../hooks/agileBoard/useTicketOperations'

const CustomDatePicker = () => {
  const formMethods = useFormContext()
  const {updateTicket, isLoadingTicketOperation} = useTicketOperations()
  const {ticketDetails, isLoadingTicketDetails}: any = useContext(TicketDetailsPageContext)
  const [defaultOption, setDefaultOption] = useState('')
  const [isDefaultOptionLoading, setIsDefaultOptionLoading] = useState(true)
  
  useEffect(() => {
    if (!isLoadingTicketDetails) {
      const defaultModuleOption = ticketDetails['due_date']
      setDefaultOption(defaultModuleOption ? defaultModuleOption : null)
      setIsDefaultOptionLoading(false)
    }
    // eslint-disable-next-line
  }, [isLoadingTicketDetails])

  const handleChange = (selectedDate: any) => {
    if (selectedDate && ticketDetails?.length !== 0) {
      updateTicket({due_date: getFormattedDate(selectedDate)})
    }
  }

  return !isLoadingTicketDetails && !isDefaultOptionLoading ? (
    <Controller
      control={formMethods.control}
      defaultValue={defaultOption === 'None'? null : new Date(defaultOption)}
      name='due_date'
      render={({field}) => (
        <div className='d-flex w-100 justify-content-end align-items-center position-relative'>
          <DatePicker
            {...field}
            className={`d-flex w-100 z-index-0 position-relative`}
            placement='auto'
            menuClassName='agile-board-datePicker'
            format='yyyy-MM-dd'
            cleanable={false}
            onChange={(selectedDate) => {
              field.onChange(selectedDate)
              handleChange(selectedDate)
            }}
          />
          {isLoadingTicketOperation && <div className='position-absolute me-11'><span className='text-primary spinner-border spinner-border-sm align-middle mb-1'></span></div>}
        </div>
      )}
    />
  ) : (
    <Select placeholder='Loading...' isLoading={!defaultOption?.length || isLoadingTicketDetails} />
  )
}

export default CustomDatePicker
