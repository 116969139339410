import {FormProvider, useForm} from 'react-hook-form'
import CommentSectionNavigation from '../components/projectDetails/commentSectionNavigation/CommentSectionNavigation'
import {EditorComponent} from '../components/projectDetails/htmleditor/EditorComponent'
// import SubTasksTable from '../components/projectDetails/table/SubTasksTable'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import useGetTicketDetails from '../hooks/ticketDetails/useGetTicketDetails'
import {TicketDetailsPageContext} from '../context'
import useGetAllModuleOptions from '../hooks/ticketDetails/useGetAllModuleOptions'
import useGetKanbanStages from '../hooks/ticketDetails/useGetKanbanStage'
import useGetTicketStatus from '../hooks/ticketDetails/useGetTicketStatus'
import useGetTicketPriorities from '../hooks/ticketDetails/useGetTicketPriorities'
import useTicketCommentOperations from '../hooks/ticketDetails/useTicketCommentOperations'
import useGetAdditionalInfoCFieldsList from '../hooks/ticketDetails/useGetAdditionalInfoCFieldsList'
import {useEffect, useState} from 'react'
import useTicketOperations from '../hooks/agileBoard/useTicketOperations'
import useGetCommentsList from '../hooks/ticketDetails/useGetCommentsList'
import useAdditionalInfoCFiendOperations from '../hooks/ticketDetails/useAdditionalInfoCFiendOperations'
import Details from '../components/projectDetails/accordions/Details'
import Accordion from '../../../components/Accordion'
import AdditionalInformation from '../components/projectDetails/accordions/AdditionalInformation'
import Loading from '../../loading'
import usePermission from '../../../hook/usePermission'

export default function TicketDetails() {
  const {hasPermission} = usePermission()
  const methods = useForm()
  const params = useParams<any>()
  const projectStatus: any = params['projectStatus']
  const projectName: any = params['projectName']
  const projectId: any = params['projectId']
  const moduleId: any = params['moduleId']
  const cardId: any = params['cardId']

  const ticketDetailsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Projects',
      path: `/projects/`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `${projectName}`,
      path: `/projects/${projectStatus}/${projectName}/${projectId}`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: `agile-board`,
      path: `/projects/${projectStatus}/${projectName}/${projectId}`,
      isSeparator: false,
      isActive: true,
    },
  ]

  const {modulesOptions, isLoadingAllModuleOptions, refetchAllModuleOptions} =
    useGetAllModuleOptions()
  const {ticketDetails, isLoadingTicketDetails, refetchTicketDetails} = useGetTicketDetails()
  const {kanbanstages, isLoadingKanbanStage, refetchKanbanStage} = useGetKanbanStages()
  const {ticketPriorities, isLoadingTicketPriorities, refetchTicketPriorities} =
    useGetTicketPriorities()
  const {ticketStatus, isLoadingTicketStatus, refetchTicketStatus} = useGetTicketStatus()
  const {createComment, editComment, deleteComment, isLoadingCommentOperation} =
    useTicketCommentOperations()
  const {
    additionalInfoCFieldsList,
    isLoadingAdditionalInfoCFieldsList,
    refetchAdditionalInfoCFieldsList,
  } = useGetAdditionalInfoCFieldsList()
  const {commentsListData, isLoadingCommentsListData, refetchCommentsListData} =
    useGetCommentsList()
  const {editTicketCFieldValue, isLoadingTicketCFieldOperation} =
    useAdditionalInfoCFiendOperations()

  const contextValue = {
    projectStatus: projectStatus,
    projectName: projectName,
    projectId: projectId,
    moduleId: moduleId,
    cardId: cardId,

    modulesOptions: modulesOptions,
    isLoadingAllModuleOptions: isLoadingAllModuleOptions,
    refetchAllModuleOptions: refetchAllModuleOptions,

    kanbanstages: kanbanstages,
    isLoadingKanbanStage: isLoadingKanbanStage,
    refetchKanbanStage: refetchKanbanStage,

    ticketDetails: ticketDetails,
    isLoadingTicketDetails: isLoadingTicketDetails,
    refetchTicketDetails: refetchTicketDetails,

    ticketPriorities: ticketPriorities,
    isLoadingTicketPriorities: isLoadingTicketPriorities,
    refetchTicketPriorities: refetchTicketPriorities,

    ticketStatus: ticketStatus,
    isLoadingTicketStatus: isLoadingTicketStatus,
    refetchTicketStatus: refetchTicketStatus,

    createComment: createComment,
    editComment: editComment,
    deleteComment: deleteComment,
    isLoadingCommentOperation: isLoadingCommentOperation,

    additionalInfoCFieldsList: additionalInfoCFieldsList,
    isLoadingAdditionalInfoCFieldsList: isLoadingAdditionalInfoCFieldsList,
    refetchAdditionalInfoCFieldsList: refetchAdditionalInfoCFieldsList,

    commentsListData: commentsListData,
    isLoadingCommentsListData: isLoadingCommentsListData,
    refetchCommentsListData: refetchCommentsListData,

    editTicketCFieldValue: editTicketCFieldValue,
    isLoadingTicketCFieldOperation: isLoadingTicketCFieldOperation,
  }

  const {updateTicket, isLoadingTicketOperation} = useTicketOperations()
  const [titleTitleValue, setTicketTitleValue] = useState(ticketDetails?.['title'])
  const [isEditableTitle, setIsEditableTitle] = useState(false)

  const handleSaveTicketTitle = async () => {
    await updateTicket({title: titleTitleValue})
    setIsEditableTitle(false)
  }

  useEffect(() => {
    setTicketTitleValue(ticketDetails?.['title'])
  }, [ticketDetails])

  const accordionItems = [
    {
      id: 'details',
      title: 'Details',
      content: <Details />,
    },
    {
      id: 'additionalInfo',
      title: 'Additional Information',
      content: <AdditionalInformation />,
      isLoading: isLoadingTicketCFieldOperation,
    },
  ]

  return (
    <TicketDetailsPageContext.Provider value={contextValue}>
      <PageTitle breadcrumbs={ticketDetailsBreadCrumbs}>
        {ticketDetails.length !== 0 ? `#${ticketDetails['card_identifier']}` : ''}
      </PageTitle>
      <div className='position-relative min-h-350px' style={{ pointerEvents: hasPermission('projects_all projects', 'write') ? 'auto' : 'none' }}>
        {isLoadingTicketDetails? <Loading/> : (
          <div className='d-flex flex-column gap-7'>
            <div className='d-flex flex-column gap-1' style={{maxWidth: '60%', minWidth: '60%'}}>
              <div className='text-primary fw-bold'>
                {ticketDetails.length !== 0 ? `#${ticketDetails['card_identifier']}` : ''}
              </div>
              <div className='d-flex mb-8 row'>
                {isEditableTitle ? (
                  <div className='task-edit-area col-10'>
                    <textarea
                      onChange={(e) => setTicketTitleValue(e.target.value)}
                      className='form-control fs-3 fw-bold p-4'
                    >
                      {titleTitleValue}
                    </textarea>
                  </div>
                ) : (
                  <div className='col col-10'>
                    <div className='me-4'>
                      <h3 className='mb-0'>{titleTitleValue}</h3>
                    </div>
                  </div>
                )}

                {isEditableTitle ? (
                  <div className='task-action-button col-2 d-flex justify-content-end align-items-center'>
                    <span
                      onClick={() => {
                        setIsEditableTitle(false)
                        setTicketTitleValue(ticketDetails?.['title'])
                      }}
                      className='bg-light-danger p-2 h-30px w-30px d-flex justify-content-center cursor-pointer align-items-center me-3 border rounded-2 border-danger'
                    >
                      <i className="fa-solid fa-xmark fs-3 text-danger"></i>
                    </span>

                    <span
                      className='bg-light-success p-2 h-30px w-30px d-flex justify-content-center cursor-pointer align-items-center border rounded-2 border-success'
                      onClick={handleSaveTicketTitle}
                    >
                      {!isLoadingTicketOperation && (
                        <i className="fa-solid fa-check fs-3 text-success"></i>
                      )}

                      {isLoadingTicketOperation && (
                        <span className='text-success spinner-border spinner-border-sm align-middle'></span>
                      )}
                    </span>
                  </div>
                ) : (
                  <div className='task-action-button col-2 d-flex justify-content-end'>
                    <span
                      className='bg-light-primary p-2 h-30px w-30px d-flex justify-content-center cursor-pointer align-items-center border rounded-2 border-primary'
                      onClick={() => setIsEditableTitle(true)}
                    >
                      <i className="fa-solid fa-pen fs-7 text-primary"></i>
                    </span>
                  </div>
                )}

                {/* {isEditableTitle && (
                  // button edit save
                  // <button
                  //   className='p-3 border-0 outline-0 h-100 d-flex justify-content-center align-items-center bg-light rounded rounded-2'
                  //   onClick={handleSaveTicketTitle}
                  // >
                  //   {!isLoadingTicketOperation && (
                  //     <i className='text-primary fs-2 fa-solid fa-circle-check'></i>
                  //   )}

                  //   {isLoadingTicketOperation && (
                  //     <span className='text-primary spinner-border spinner-border-sm align-middle'></span>
                  //   )}
                  // </button>
                )} */}
              </div>
            </div>
            <div className='project-ticket-form'>
              <FormProvider {...methods}>
                <form className='d-flex gap-4'>
                  <div
                    className='d-flex flex-column gap-6 min-w-70'
                    style={{maxWidth: '60%', minWidth: '60%'}}
                  >
                    <EditorComponent />
                    {/* <SubTasksTable /> */}
                    <CommentSectionNavigation />
                  </div>
                  <div className='w-100'>
                    <Accordion items={accordionItems} defaultOpen='All' />
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        )}
      </div>
    </TicketDetailsPageContext.Provider>
  )
}
