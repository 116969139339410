export function handleDragEnd(
  event: any,
  mergedData: any,
  setUpdatedTaskDetails: any,
  setTasks: any
) {
  const {active, over} = event

  if (!over) return

  const overId = over.id

  const isActiveATask = active.data.current?.type === 'Task'
  const isOverATask = over.data.current?.type === 'Task'
  const isOverAColumn = over.data.current?.type === 'Column'

  if (isActiveATask && isOverATask) {
    const columnID = over.data.current?.task.columnID
    const cId = mergedData.find((column: any) => column.columnID === columnID)?.cID
    const moduleID = mergedData.find((column: any) => column.columnID === columnID)?.moduleID
    setUpdatedTaskDetails((existingDetails: any) => ({
      ...existingDetails,
      newColumnId: cId || null,
      newModuleId: moduleID || null,
    }))
  }

  if (isActiveATask && isOverAColumn) {
    const columnID = overId
    const cId = mergedData.find((column: any) => column.columnID === columnID)?.cID
    const moduleID = mergedData.find((column: any) => column.columnID === columnID)?.moduleID

    setUpdatedTaskDetails((existingDetails: any) => ({
      ...existingDetails,
      newColumnId: cId || null,
      newModuleId: moduleID || null,
    }))
  }
}
