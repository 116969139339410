import {useContext, useState} from 'react'
import AgileBoardModal from '../modal/agileBoard/AgileBoardModal'
import {AgileBoardPageContext} from '../../context'
import usePermission from '../../../../hook/usePermission'

const AgileBoardPageHeader = ({isShowButton}: any) => {
  const {hasPermission} = usePermission()
  const [openAgileBoardModal, setOpenAgileBoardModal] = useState(false)
  const {isUpdatingAgileBoard} = useContext(AgileBoardPageContext)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div>
        {isUpdatingAgileBoard && (
          <button className='p-3 border-0 outline-0 h-100 d-flex justify-content-center align-items-center bg-light rounded rounded-2'>
            <span className='text-primary spinner-border spinner-border-sm align-middle'></span>
          </button>
        )}
      </div>
      {hasPermission('projects_all projects', 'write') && isShowButton && (
        <button
          type='button'
          onClick={() => setOpenAgileBoardModal(true)}
          className='btn btn-primary'
        >
          <i className='fa-solid fa-plus'></i>Create Issue
        </button>
      )}
      <AgileBoardModal
        show={openAgileBoardModal}
        onClose={() => setOpenAgileBoardModal(false)}
        boardId={null}
      />
    </div>
  )
}

export {AgileBoardPageHeader}
