import {AgileBoardPageContext} from '../context'
import {useGetAgileBoardStructure} from '../hooks/agileBoard/useGetAgileBoardStructure'
import {useGetAgileBoardData} from '../hooks/agileBoard/useGetAgileBoardData'
import useUpdateAgileBoard from '../hooks/agileBoard/useUpdateAgileBoard'
import useTicketOperations from '../hooks/agileBoard/useTicketOperations'
import AgileBoardComponent from '../components/agileBoard/AgileBoardComponent'
import { AgileBoardPageHeader } from '../components/header/AgileBoardPageHeader'

function AgileBoard() {
  const {agileBoardData, isLoadingBoardData, refetchBoardData} = useGetAgileBoardData()
  const {agileBoardStructure, isLoadingBoardStructure, refetchBoardStructure} =
    useGetAgileBoardStructure()
  const {createTicket, updateTicket, updateTicketDescription, isLoadingTicketOperation} =
    useTicketOperations()
  const {updateAgileBoard, isUpdatingAgileBoard} = useUpdateAgileBoard()

  const contextValue = {
    agileBoardData,
    isLoadingBoardData,
    refetchBoardData: refetchBoardData,

    agileBoardStructure,
    isLoadingBoardStructure: isLoadingBoardStructure,
    refetchBoardStructure,

    createTicket,
    updateTicket,
    updateTicketDescription,
    isLoadingTicketOperation,

    updateAgileBoard,
    isUpdatingAgileBoard: isUpdatingAgileBoard,
  }

  return (
    <>
      <AgileBoardPageContext.Provider value={contextValue}>
        <AgileBoardPageHeader isShowButton={Boolean(agileBoardStructure?.length)} />
        <AgileBoardComponent />
      </AgileBoardPageContext.Provider>
    </>
  )
}

export default AgileBoard
