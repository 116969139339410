import {useEffect} from 'react'
import useFilters from '../../../../hook/useFilters'
import {useQuery} from 'react-query'
import {getCardDetails} from '../../service'
import {useParams} from 'react-router-dom'

const initialFilters = {}

const useGetTicketDetails = () => {
  const params = useParams<any>()
  const projectId: any = params['projectId']
  const cardId: any = params['cardId']

  const {filters} = useFilters(initialFilters)

  const {
    data: ticketDetails,
    isFetching,
    refetch,
  } = useQuery(`get-ticket-details`, () => {
    return getCardDetails(projectId, cardId)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  return {
    ticketDetails: ticketDetails?.['data'] ? ticketDetails['data']?.[0] : [],
    isLoadingTicketDetails: isFetching,
    refetchTicketDetails: refetch,
  }
}

export default useGetTicketDetails
