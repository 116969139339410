import useToastify from '../../../../hook/useToastify'
import {addCustomField, editCustomField, removeCustomField, updateTableSorting} from '../../service'
import {useContext, useState} from 'react'
import {ProjectsMainPageContext} from '../../context'
import useGetCustomFieldsList from './useGetCustomFieldsList'

function useCustomFieldsOperations() {
  const {toastMessage} = useToastify()
  const {projectId} = useContext(ProjectsMainPageContext)
  const {refetchCustomFieldsList} = useGetCustomFieldsList()
  const [isLoading, setIsLoading] = useState(false)

  async function createCustomField(customFieldDetails: any) {
    setIsLoading(true)
    const res: any = await addCustomField(projectId, customFieldDetails)
    if (res.status === 200) {
      toastMessage('success', `Custom Field created successfully`)
      refetchCustomFieldsList()
      setIsLoading(false)
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function updateCustomField(cFieldId: string, customFieldDetails: any) {
    setIsLoading(true)
    const res: any = await editCustomField(projectId, cFieldId, customFieldDetails)
    if (res.status === 200) {
      toastMessage('success', `Custom Field updated successfully`)
      setIsLoading(false)
      refetchCustomFieldsList()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function updateCustomFieldTableSorting(customFieldsTableRowOrder: any) {
    setIsLoading(true)
    const tableName = 'custom-fields'
    const res: any = await updateTableSorting(projectId, tableName, customFieldsTableRowOrder)
    if (res.status === 200) {
      toastMessage('success', `Updated successfully`)
      setIsLoading(false)
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  async function deleteCustomField(cFieldId: string) {
    setIsLoading(true)
    const res: any = await removeCustomField(projectId, cFieldId)
    if (res.status === 200) {
      toastMessage('success', `Custom Field deleted successfully`)
      setIsLoading(false)
      refetchCustomFieldsList()
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  return {
    createCustomField,
    updateCustomField,
    updateCustomFieldTableSorting,
    deleteCustomField,
    isLoadingCustomFieldOperation: isLoading,
  }
}

export default useCustomFieldsOperations
