import {Modal} from 'react-bootstrap'
import {useForm, Controller} from 'react-hook-form'
// import {useFieldArray} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import Select from 'react-select'
import * as yup from 'yup'
import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ProjectsCustomFieldsPageContext} from '../../../context'

interface AddNewCustomFieldModalTypes {
  show: boolean
  editId?: any
  onClose: () => void
  customFieldData: any
}

const createSchema = yup.object().shape({
  customField_name: yup.string().required('Custom Field Name is required'),
})

const customField_type = [
  {label: 'String', value: 'string'},
  {label: 'Integer', value: 'integer'},
  {label: 'Float', value: 'float'},
  {label: 'Date', value: 'date'},
  {label: 'Group', value: 'group'},
  {label: 'Multi Group', value: 'multi-group'},
]

const CustomFieldsModal = ({
  show,
  onClose,
  editId,
  customFieldData,
}: AddNewCustomFieldModalTypes) => {
  const defaultValues: any = {
    customField_name: customFieldData ? customFieldData.custom_field_name : '',
    customFieldArray: customFieldData ? customFieldData.group_values?.split(',') : [],
    customField_type: customField_type.find(
      (option: any) => option.value === customFieldData['field_type']
    ),
  }

  const {
    createGlobalCustomField,
    updateGlobalCustomField,
    isLoadingGlobalCustomFieldOperation,
    isLoadingGlobalCustomFieldList,
  } = useContext(ProjectsCustomFieldsPageContext)

  const {
    register,
    formState: {errors},
    handleSubmit,
    control,
    // unregister,
    setValue,
    reset,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(createSchema),
  })

  useEffect(() => {
    if (isLoadingGlobalCustomFieldList) {
      onClose()
    }
  }, [isLoadingGlobalCustomFieldList, onClose])

  // const {remove} = useFieldArray({
  //   control,
  //   name: 'customFieldArray',
  // });

  const [fieldOptions, setFieldOptions] = useState<any>([])
  const [selectedFieldType, setSelectedFieldType] = useState<any>(defaultValues.customField_type)

  useEffect(() => {
    if (!show && defaultValues.customField_type === undefined) {
      reset({customField_type: undefined, customField_name: '', customFieldArray: []})
      setSelectedFieldType(null)
      setFieldOptions([])
    }
    //eslint-disable-next-line
  }, [show, reset])

  useEffect(() => {
    if (defaultValues.customFieldArray) {
      let counter = 1
      setFieldOptions(defaultValues.customFieldArray.map((value: any) => ({id: counter++, value})))
      setSelectedFieldType(defaultValues.customField_type)
    }
    // eslint-disable-next-line
  }, [customFieldData])

  const handleFieldTypeChange = (selectedOption: any) => {
    setSelectedFieldType(selectedOption)
    if (selectedOption.value === 'group' || selectedOption.value === 'multi-group') {
      setFieldOptions([{id: 1, value: ''}])
    } else {
      setFieldOptions([])
    }
    setValue('customFieldArray', [])
  }

  const handleAddField = () => {
    setFieldOptions([...fieldOptions, {id: Date.now(), value: ''}])
  }

  // const handleRemoveField = (id: number) => {
  //   if (fieldOptions.length > 1) {
  //     const updatedFields = fieldOptions.filter((field: any) => field.id !== id);
  //     setFieldOptions(updatedFields);
  //     const fieldName:any = `customFieldArray.${fieldOptions.findIndex((field: any) => field.id === id)}`;
  //     unregister(fieldName);
  //     remove(fieldOptions.findIndex((field: any) => field.id === id));
  //   }
  // };

  const onSubmit = (data: any) => {
    const is_multi_Fields =
      data.customField_type.value === 'group' || data.customField_type.value === 'multi-group'
        ? true
        : false
    const customFieldDetails: any = {
      name: data.customField_name,
      datatype:
        data.customField_type.value === 'multi-group' ? 'group' : data.customField_type.value,
      is_multiselect_group: data.customField_type.value === 'multi-group',
      ...(is_multi_Fields ? {group_values: data.customFieldArray.join(',')} : {}),
    }
    if (customFieldData.length === 0) {
      createGlobalCustomField(customFieldDetails)
    } else {
      const modifiedFields: any = {}
      Object.keys(customFieldDetails).forEach((key) => {
        const customFieldDataKey = key === 'datatype' ? 'field_type' : key
        if (customFieldData[customFieldDataKey] !== customFieldDetails[key]) {
          modifiedFields[key] = customFieldDetails[key]
        }
      })
      updateGlobalCustomField(editId, modifiedFields)
    }
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_stacked_1'
      data-backdrop='static'
      tabIndex={-1}
      show={show}
      dialogClassName='modal-1'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{editId ? 'Edit' : 'Add'} Field </h4>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => {
              onClose()
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-body'>
            <div className='mb-8'>
              <label htmlFor={`customField_name`} className='required form-label'>
                Field Name
              </label>
              <input
                id={'customField_name'}
                type='text'
                className='form-control'
                placeholder='Type Field Name'
                {...register('customField_name', {required: true})}
              />
              {errors.customField_name && (
                <span className='text-danger'>This field is required</span>
              )}
            </div>
            <div className='mb-8'>
              <label htmlFor={`customField_type`} className='required form-label'>
                Field Type
              </label>
              <Controller
                name='customField_type'
                control={control}
                defaultValue={selectedFieldType}
                render={({field}) => (
                  <Select
                    {...field}
                    className={`react-select-styled`}
                    placeholder='Select field type'
                    options={customField_type}
                    isDisabled={!!customFieldData}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption)
                      handleFieldTypeChange(selectedOption)
                    }}
                  />
                )}
              />
            </div>
            <div className='mb-8'>
              {selectedFieldType &&
                (selectedFieldType.value === 'group' ||
                  selectedFieldType.value === 'multi-group') && (
                  <label className='required form-label mb-5'>Fields Name</label>
                )}
              {fieldOptions.map((field: any, index: any) => (
                <div key={field.id} className='mb-8'>
                  <div className='d-flex gap-3'>
                    <input
                      id={`field-${field.id}`}
                      type='text'
                      className='form-control bg-light '
                      placeholder='Type Field Name'
                      value={field.value}
                      {...register(`customFieldArray.${index}`, {required: true})}
                      onChange={(e) => {
                        const updatedFields = [...fieldOptions]
                        updatedFields[index].value = e.target.value
                        setFieldOptions(updatedFields)
                      }}
                    />
                    {/* <button 
                    type='button'
                    className='btn btn-link'
                    onClick={() => handleRemoveField(field.id)}
                  >
                    <i className="fa-solid fa-circle-minus text-danger fs-3 "></i>
                  </button> */}
                  </div>
                </div>
              ))}
            </div>
            {selectedFieldType &&
              (selectedFieldType.value === 'group' ||
                selectedFieldType.value === 'multi-group') && (
                <div className='mb-8'>
                  <button
                    type='button'
                    className='btn border border-2 border-secondary '
                    onClick={handleAddField}
                  >
                    <i className='fa-solid fa-plus'></i> Add
                  </button>
                </div>
              )}
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-outline btn-active-light-primary'
              onClick={() => {
                onClose()
              }}
            >
              Cancel
            </button>
            <button type='submit' disabled={!selectedFieldType} className='btn btn-primary'>
              {!isLoadingGlobalCustomFieldOperation && (
                <span className='indicator-label'>{customFieldData ? 'Update' : 'Create'}</span>
              )}

              {isLoadingGlobalCustomFieldOperation && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {customFieldData ? 'Updating...' : 'Creating...'}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default CustomFieldsModal
