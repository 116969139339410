import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import Loading from '../../../loading'
import useGetProjectMembersList from '../../hooks/useGetProjectMembersList'

interface CreateProjectDialog {
  show: boolean
  onClose: () => void
  modalTitle: string
  projectId: any
}

const ProjectMembersListingModal = ({
  show,
  onClose,
  modalTitle,
  projectId,
}: CreateProjectDialog) => {
  const {projectMembersList, isLoadingMemberDetails} = useGetProjectMembersList(projectId)

  if (!show) {
    return null
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_stacked_1'
      data-backdrop='static'
      tabIndex={-1}
      show={show}
      dialogClassName='modal-1'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{modalTitle}</h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body position-relative min-h-300px mh-600px overflow-auto'>
            {isLoadingMemberDetails && <Loading/>}
          <table className='w-100 d-flex flex-column gap-5'>
            {projectMembersList.map((member: any) => (
              <tr className='d-flex gap-5 justify-content-between align-items-center '>
                <td className='d-flex gap-4 align-items-center w-100'>
                  <div className='d-flex justify-content-center align-items-center bg-primary bg-opacity-15 fw-bold fs-4 w-35px h-35px rounded rounded-circle '>
                    {member.name?.charAt(0)?.toUpperCase()}
                  </div>
                  <div className='align-items-center'>{member.name}</div>
                </td>

                <td className='w-100 d-flex align-content-center justify-content-end'>
                  <div className='align-items-center'>{member.role}</div>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </Modal>
  )
}

export default ProjectMembersListingModal
