import ProjectsMembersPageHeader from '../components/header/ProjectsMembersPageHeader'
import {Pagination} from '../../../utils/pagination'
import { ProjectsMembersPageContext } from '../context'
import GlobalProjectsMemberPageTable from '../components/tables/members/GlobalProjectsMemberPageTable'
import { useGetGlobalProjectsMembersList } from '../hooks/members/useGetGlobalProjectsMembersList'
import useGlobalProjectsMembersOperations from '../hooks/members/useGlobalProjectsMembersOperations'
import { useGetGlobalProjectsMembersDropdownList } from '../hooks/members/useGetGlobalProjectsMembersDropdownList'

export default function ProjectsMembers() {

  const { globalProjectsMembersDropdownList, isLoadingGlobalProjectsMembersDropdownList, refetchProjectMembersDropdownList } = useGetGlobalProjectsMembersDropdownList()
  const { globalProjectsMembersList, isLoadingGlobalProjectsMembersList, pagination, onPageChange, onSearch, refetchGlobalProjectsMembersList } = useGetGlobalProjectsMembersList()
  const {addGlobalProjectMember, removeGlobalProjectMember, isLoadingGlobalProjectsMembersOperation} = useGlobalProjectsMembersOperations()

  const contextValue = {
    globalProjectsMembersDropdownList: globalProjectsMembersDropdownList,
    isLoadingGlobalProjectsMembersDropdownList: isLoadingGlobalProjectsMembersDropdownList,
    refetchProjectMembersDropdownList: refetchProjectMembersDropdownList,

    globalProjectsMembersList: globalProjectsMembersList,
    isLoadingGlobalProjectsMembersList: isLoadingGlobalProjectsMembersList,
    onSearch: onSearch,
    refetchGlobalProjectsMembersList: refetchGlobalProjectsMembersList,

    addGlobalProjectMember: addGlobalProjectMember,
    removeGlobalProjectMember: removeGlobalProjectMember,
    isLoadingGlobalProjectsMembersOperation: isLoadingGlobalProjectsMembersOperation,
  }

  return (
    <ProjectsMembersPageContext.Provider value={contextValue}>
      <ProjectsMembersPageHeader />
      <GlobalProjectsMemberPageTable/>
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </ProjectsMembersPageContext.Provider>
  )
}