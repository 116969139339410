import {Route, Routes, Outlet, Navigate, useParams} from 'react-router-dom'
import PurchasingPageNavigation from './components/PurchasingPageNavigation'
import AgileBoard from './pages/AgileBoard'
import useMeta from '../../hook/useMeta'
import BoardSetting from './pages/BoardSetting'
import Members from './pages/Members'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import TicketDetails from './pages/TicketDetails'
import {ProjectsMainPageContext} from './context'

function PurchasingPage() {
  useMeta('Agile Board')

  const params: any = useParams<any>()
  const projectId: any = params?.projectId
  const projectName: any = params?.projectName
  const projectStatus: any = params?.projectStatus

  const contextValue = {
    projectStatus,
    projectName,
    projectId: projectId,
  }

  const analyticsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Projects',
      path: `/projects/`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `${projectName}`,
      path: `/projects/${projectStatus}/${projectName}/${projectId}`,
      isSeparator: false,
      isActive: true,
    },
  ]

  return (
    <ProjectsMainPageContext.Provider value={contextValue}>
      <Routes>
        <Route
          element={
            <>
              <PurchasingPageNavigation />
              <Outlet />
            </>
          }
        >
          <Route
            path='/agile-board'
            element={
              <>
                <PageTitle breadcrumbs={analyticsBreadCrumbs}>Agile Board</PageTitle>
                <AgileBoard />
              </>
            }
          />

          <Route
            path='/members'
            element={
              <>
                <PageTitle breadcrumbs={analyticsBreadCrumbs}>Members</PageTitle>
                <Members />
              </>
            }
          />

          <Route
            path='/board-setting'
            element={
              <>
                <PageTitle breadcrumbs={analyticsBreadCrumbs}>Board Settings</PageTitle>
                <BoardSetting />
              </>
            }
          />

          <Route
            path='/project-details'
            element={
              <>
                <PageTitle breadcrumbs={analyticsBreadCrumbs}>Project Details</PageTitle>
                <TicketDetails />
              </>
            }
          />

          <Route
            index
            element={
              <Navigate to={`/projects/${projectStatus}/${projectName}/${projectId}/agile-board`} />
            }
          />
        </Route>
      </Routes>
    </ProjectsMainPageContext.Provider>
  )
}

export default PurchasingPage
