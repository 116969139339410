import Search from '../../../../components/Search'

const ProjectsSettingsSearchComponent = ({onSearch}:any) => {
 
  return (
    <div>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch && onSearch(searchTerm)
        }}
      />
    </div>
  )
}

export default ProjectsSettingsSearchComponent
