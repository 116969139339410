import {useState} from 'react'
import useToastify from '../../../hook/useToastify'
import {submitNewProjectDetails} from '../service'
import {useGetProjectsList} from './useGetProjectsList'

export default function useCreateProject() {
  const {toastMessage} = useToastify()
  const [isLoading, setLoading] = useState(false)
  const {refetchProjectsList} = useGetProjectsList()

  async function createNewProjectDetails(projectDetails: any) {
    projectDetails = {
      ...projectDetails,
      is_archived: false,
      board_id: 1,
    }

    try {
      setLoading(true)
      const response = await submitNewProjectDetails('/agile/projects', projectDetails)
      if (response.status === 200) {
        toastMessage('success', `Project created successfully`)
        setLoading(false)
        refetchProjectsList()
      } else {
        toastMessage('error', `${response.data.message}`)
        setLoading(false)
      }
      return response
    } catch (error: any) {
      setLoading(false)
    }
  }

  return {
    createNewProjectDetails,
    isCreating: isLoading,
  }
}
