import {Controller, FormProvider, useForm} from 'react-hook-form'
import {EditorComponent} from '../../projectDetails/htmleditor/EditorComponent'
import {Modal} from 'react-bootstrap'
import {fromToTicketData} from '../../../utils/agile-board'
import useTicketOperations from '../../../hooks/agileBoard/useTicketOperations'
import {useEffect} from 'react'
import useGetKanbanStages from '../../../hooks/ticketDetails/useGetKanbanStage'
import useGetAllModuleOptions from '../../../hooks/ticketDetails/useGetAllModuleOptions'
import Details from '../../projectDetails/accordions/Details'
import Accordion from '../../../../../components/Accordion'
import {KTSVG} from '../../../../../_metronic/helpers'

function AgileBoardModal({boardId, show, onClose, columnId, moduleId}: any) {
  const {kanbanstages, isLoadingKanbanStage}: any = useGetKanbanStages()
  const {modulesOptions, isLoadingAllModuleOptions}: any = useGetAllModuleOptions()

  const ticketColumnId = kanbanstages.find((option: any) => option.value === columnId)
  const ticketModuleId = modulesOptions.find((option: any) => option.value === moduleId)

  const methods = useForm({
    defaultValues: {
      ticket_title: '',
      assignee: '',
      estimation: '',
      stage: '',
      module: '',
      priority: 0,
      spent_time: '',
      status: 0,
      due_date: null,
      description: '',
    },
  })

  useEffect(() => {
    if (!isLoadingKanbanStage && !isLoadingAllModuleOptions && kanbanstages.length > 0) {
      methods.reset({
        ...methods.getValues(),
        stage: ticketColumnId || kanbanstages[0],
        module: ticketModuleId || modulesOptions[0],
      })
    }
    //eslint-disable-next-line
  }, [isLoadingKanbanStage, isLoadingAllModuleOptions])

  const {createTicket, isLoadingTicketOperation} = useTicketOperations()

  const onSubmit = async (data: any) => {
    const {reqObj, moduleId} = fromToTicketData(data)
    await createTicket(moduleId, reqObj)
    methods.reset()
    onClose()
  }

  const handleModalClose = () => {
    onClose()
    methods.reset()
  }

  const accordionItems = [
    {
      id: 'details',
      title: 'Details',
      content: <Details />,
    },
  ]

  return (
    <Modal
      className='modal fade'
      id='kt_modal_stacked_1'
      data-backdrop='static'
      tabIndex={-1}
      show={show}
      dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{boardId ? 'Edit' : 'Create'} Issue</h4>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={handleModalClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
      </div>

      <div className='modal-body'>
        <div className='project-modal-wrapper'>
          <FormProvider {...methods}>
            <div>
              <Controller
                name='ticket_title'
                control={methods.control}
                render={({field, fieldState}) => (
                  <div className='mb-10'>
                    <label htmlFor={`ticket-title`} className='required form-label'>
                      Issue name:
                    </label>
                    <input
                      {...field}
                      id={`ticket-title`}
                      type='text'
                      className={`form-control ${fieldState.invalid ? 'is-invalid' : ''}`}
                      placeholder='Issue name'
                    />
                    {fieldState.invalid && (
                      <div className='invalid-feedback'>Issue name is required.</div>
                    )}
                  </div>
                )}
                rules={{required: 'Issue name is required'}}
              />
            </div>

            <div className='row d-flex'>
              <div className='col-8'>
                <EditorComponent />
              </div>
              <div className='col-4'>
                <Accordion items={accordionItems} defaultOpen='All' />
              </div>
            </div>
          </FormProvider>
        </div>
      </div>

      <div className='modal-footer'>
        <button
          type='button'
          className='btn btn-outline btn-active-light-primary'
          onClick={handleModalClose}
        >
          Close
        </button>
        <button
          type='submit'
          className='btn btn-primary spinner spinner-white spinner-right'
          onClick={methods.handleSubmit(onSubmit)}
        >
          {!isLoadingTicketOperation && <span className='indicator-label'>Create</span>}

          {isLoadingTicketOperation && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Creating...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </Modal>
  )
}

export default AgileBoardModal
