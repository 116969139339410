import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {getAgileBoardStructure} from '../../service'
import {useParams} from 'react-router-dom'

export const useGetAgileBoardStructure = () => {
  const params = useParams()
  const projectId = params?.['projectId']

  const {
    data: agileBoardStructure,
    isFetching,
    refetch,
  } = useQuery(`get-agile-board-structure`, () => {
    return getAgileBoardStructure(`/agile/projects/${projectId}/structure`)
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  return {
    agileBoardStructure: agileBoardStructure?.['data'] || [],
    isLoadingBoardStructure: isFetching,
    refetchBoardStructure: refetch,
  }
}
