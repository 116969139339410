import AssigneeListDropdown from '../dropdowns/AssigneeListDropdown'
import CustomDatePicker from '../dropdowns/DatePicker'
import EstimationTimePicker from '../dropdowns/EstimationTimePicker'
import KanbanStageDropdown from '../dropdowns/KanbanStageDropdown'
import ModuleDropdown from '../dropdowns/ModuleDropdown'
import OwnerListDropdown from '../dropdowns/OwnerListDropdown'
import PriorityDropdown from '../dropdowns/PriorityDropdown'
import SpendTimePicker from '../dropdowns/SpendTimePicker'
import StatusDropdown from '../dropdowns/StatusDropdown'

const Details = () => {
  return (
    <div className='w-100'>
      <div className=''>
        <div className='d-flex mb-4'>
          <div className='d-flex align-items-center min-w-120px fw-semibold'>
            Owner
          </div>
          <div className='w-100'>
            <OwnerListDropdown />
          </div>
        </div>

        <div className='d-flex mb-4'>
          <div className='d-flex align-items-center min-w-120px fw-semibold'>
            Assignee
          </div>
          <div className='w-100'>
            <AssigneeListDropdown />
          </div>
        </div>

        <div className='d-flex mb-4'>
          <div className='d-flex align-items-center min-w-120px fw-semibold'>
            Kanban Stage
          </div>
          <div className='w-100'>
            <KanbanStageDropdown />
          </div>
        </div>

        <div className='d-flex mb-4'>
          <div className='d-flex align-items-center min-w-120px fw-semibold'>
            Module
          </div>
          <div className='w-100'>
            <ModuleDropdown />
          </div>
        </div>

        <div className='d-flex mb-4'>
          <div className='d-flex align-items-center min-w-120px fw-semibold'>
            Status
          </div>
          <div className='w-100'>
            <StatusDropdown />
          </div>
        </div>

        <div className='d-flex mb-4'>
          <div className='d-flex align-items-center min-w-120px fw-semibold'>
            Priority
          </div>
          <div className='w-100'>
            <PriorityDropdown />
          </div>
        </div>

        <div className='d-flex mb-4'>
          <div className='d-flex align-items-center min-w-120px fw-semibold'>
            Estimation
          </div>
          <div className='w-100'>
            <EstimationTimePicker />
          </div>
        </div>

        <div className='d-flex mb-4'>
          <div className='d-flex align-items-center min-w-120px fw-semibold'>
            Spent Time
          </div>
          <div className='w-100'>
            <SpendTimePicker />
          </div>
        </div>

        <div className='d-flex mb-4'>
          <div className='d-flex align-items-center min-w-120px fw-semibold'>
            Due Date
          </div>
          <div className='w-100 position-relative '>
            <CustomDatePicker />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Details
