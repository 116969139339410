import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'

function RowDontainer({row, isDraggable}: any) {
  const rowData = row?.tableData

  const {setNodeRef, attributes, listeners, transform, transition, isDragging} = useSortable({
    id: row.id,
    data: {
      type: 'Row',
      row,
    },
  })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    outline: 0,
    cursor: 'grab',
  }

  if (isDragging) {
    return (
      <tr
        ref={setNodeRef}
        style={style}
        className={`border border-2 border-primary border-opacity-25 opacity-10 bg-light`}
      >
        {rowData.map((item: any) => (
          <td className='border-0'>{item.columnData}</td>
        ))}
      </tr>
    )
  }

  return (
    <tr ref={setNodeRef} style={style} {...attributes} {...listeners} className='cursor-grab'>
      {rowData.map((item: any, index: number) => (
        <td key={index}>
          {index === 0 && isDraggable && (
            <img src='/media/svg/general/Group556.svg' className='me-3' alt='img' />
          )}
          {item.columnData}
        </td>
      ))}
    </tr>
  )
}

export default RowDontainer
