import {useContext, useState} from 'react'
import ProjectsSettingsSearchComponent from './ProjectsSettingsSearchComponent'
import DefaultColumnsModal from '../modal/defaultColumn/DefaultColumnsModal'
import { ProjectsDefaultColumnsPageContext } from '../../context'
import usePermission from '../../../../hook/usePermission'

const ProjectsDefaultColumnsHeader = () => {
  const {hasPermission} = usePermission()
  const [openDefaultColumnsModal, setOpenDefaultColumnsModal] = useState(false)
  const {onSearch, isLoadingGlobalDefaultColumnOperation} = useContext(ProjectsDefaultColumnsPageContext)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div className='d-flex align-items-center'>
        <ProjectsSettingsSearchComponent onSearch={onSearch} />
        {isLoadingGlobalDefaultColumnOperation && (
          <button className='p-3 border-0 outline-0 h-100 d-flex justify-content-center align-items-center bg-light rounded rounded-2'>
            <span className='text-primary spinner-border spinner-border-sm align-middle'></span>
          </button>
        )}
      </div>
      {hasPermission('projects_settings', 'write') && (
        <button
          type='button'
          onClick={() => setOpenDefaultColumnsModal(true)}
          className='btn btn-primary'
        >
          <i className='fa-solid fa-plus'></i>Add Column
        </button>
      )}
      <DefaultColumnsModal
        show={openDefaultColumnsModal}
        defaultColumnDetails={''}
        onClose={() => setOpenDefaultColumnsModal(false)}
      />
    </div>
  )
}

export default ProjectsDefaultColumnsHeader
