import usePermission from "../../../../../hook/usePermission"
import { formatDate } from "../../../../../utils/date"
import MemberOverlayModal from "../../modal/member/MemberOverlayModal"

const MemberPageTableRow = ({row}: any) => {
  const {hasPermission} = usePermission()

  return (
    <tr>
      <td>
        <div className='align-items-center'>{row['name']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['role']}</div>
      </td>
      <td>
        <div className='align-items-center'>{formatDate(row['created_at'], false)}</div>
      </td>
      {hasPermission('projects_all projects', 'write') && (
        <td>
          <div className='align-items-center'>
            <MemberOverlayModal projectMemberDetails={row} />
          </div>
        </td>
      )}
    </tr>
  )
}

export default MemberPageTableRow
