import { DragOverEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

export function handleDragOver(event: DragOverEvent, tableData: any, setTableData: (data: any) => void) {
  const { active, over } = event;
  if (!over) return;

  const activeId = active.id;
  const overId = over.id;

  if (activeId === overId) return;

  const isActiveARow = active.data.current?.type === 'Row';
  const isOverARow = over.data.current?.type === 'Row';

  if (!isActiveARow) return;

  // Dropping a Row over another Row
  if (isActiveARow && isOverARow) {
    setTableData((rows: any) => {
      const activeIndex = rows.findIndex((t: any) => t.id === activeId);
      const overIndex = rows.findIndex((t: any) => t.id === overId);

      if (rows[activeIndex].columnId !== rows[overIndex].columnId) {
        rows[activeIndex].columnId = rows[overIndex].columnId;
        return arrayMove(rows, activeIndex, overIndex - 1);
      }

      return arrayMove(rows, activeIndex, overIndex);
    });
  }
}