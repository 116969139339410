import {useContext, useState} from 'react'
import {TicketLayout} from './TicketLayout'
import {AgileBoardPageContext} from '../../context'
import DndBoard from '../../../../components/agileBoard/DndBoard'
import AgileBoardModal from '../modal/agileBoard/AgileBoardModal'
import usePermission from '../../../../hook/usePermission'

export default function AgileBoardComponent() {
  const {hasPermission} = usePermission()
  const [openAgileBoardModal, setOpenAgileBoardModal] = useState(false)
  const [columnId, setColumnId] = useState(null)
  const [moduleId, setModuleId] = useState(null)
  const {
    agileBoardStructure,
    agileBoardData,
    isLoadingBoardStructure,
    isLoadingBoardData,
    updateAgileBoard,
  } = useContext(AgileBoardPageContext)

  const handleTicketLayout = ({ticketData}: any) => {
    return <TicketLayout ticket={ticketData} />
  }

  const handleAddCardFromColumn = ({columnData}: any) => {
    setColumnId(columnData.cID)
    setModuleId(columnData.moduleID)
    setOpenAgileBoardModal(true)
  }

  const dndBoardData = {
    agileBoardStructure,
    agileBoardData,
    isLoadingBoardStructure,
    isLoadingBoardData,
    updateAgileBoard,
  }

  return (
    <>
      <DndBoard
        dndBoardData={dndBoardData}
        handleTicketLayout={handleTicketLayout}
        isCreateCardFromColumn={true}
        handleAddCardFromColumn={handleAddCardFromColumn}
        hasPermission={hasPermission('projects_all projects', 'write')}
        isDraggable={hasPermission('projects_all projects', 'write')}
      />
      <AgileBoardModal
        show={openAgileBoardModal}
        onClose={() => setOpenAgileBoardModal(false)}
        boardId={null}
        columnId={columnId}
        moduleId={moduleId}
      />
    </>
  )
}
