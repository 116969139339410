import {useState} from 'react'
import useApi from '../../../services/useApi'

export const useGetDataForCSV = () => {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)
  const initialFilters = {
    page: 0,
    limit: 0,
    sort_by: '',
  }

  // Function to fetch CSV data
  const fetchDataForCSV = async (url: string) => {
    try {
      const response = await getCsvData(url, initialFilters)
      return response || {}
    } catch (error) {
      console.error('Error fetching CSV data:', error)
      throw error
    }
  }

  const getCsvData = async (url: string, filters: any) => {
    return await Api.get(url, {
      setLoading: setIsLoading,
      filters: filters,
    })
  }

  return {
    isLoading,
    fetchDataForCSV,
  }
}
