import JoditEditor from 'jodit-react'
import {useContext, useEffect, useState} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {TicketDetailsPageContext} from '../../../context'
import Loading from '../../../../loading'
import useTicketOperations from '../../../hooks/agileBoard/useTicketOperations'
import usePermission from '../../../../../hook/usePermission'

function EditorComponent() {
  const {hasPermission} = usePermission()
  const {control} = useFormContext()
  const {updateTicketDescription, isLoadingTicketOperation} = useTicketOperations()
  const {ticketDetails, isLoadingTicketDetails}: any = useContext(TicketDetailsPageContext)
  const [defaultValue, setDefaultValue] = useState('')
  const [isDefaultEditorLoading, setIsDefaultEditorLoading] = useState(true)
  const [isEnableSaveBtn, setIsEnableSaveBtn] = useState(false)
  const [editorData, setEditorData] = useState()
  const [isActiveSaveBtn, setIsActiveSaveBtn] = useState(false)

  // const config = {
  //   buttons: [
  //     'bold',
  //     'italic',
  //     'underline',
  //     '|',
  //     'ul',
  //     'ol',
  //     '|',
  //     'fontsize',
  //     'paragraph',
  //     '|',
  //     'image',
  //     'video',
  //     'table',
  //     'link',
  //     'brush',
  //     '|',
  //     'align',
  //     '|',
  //     'hr',
  //     'eraser',
  //     'copyformat',
  //     '|',
  //     'fullsize',
  //     'print',
  //     'source',
  //   ],
  // }

  useEffect(() => {
    if (!isLoadingTicketDetails && isDefaultEditorLoading) {
      const defaultModuleOption = ticketDetails['description']
      setDefaultValue(defaultModuleOption)
      setIsDefaultEditorLoading(false)
      setIsEnableSaveBtn(true)
    }
    // eslint-disable-next-line
  }, [isLoadingTicketDetails])

  const handleSaveEditor = async () => {
    await updateTicketDescription({description: editorData})
    setIsActiveSaveBtn(false)
  }

  useEffect(() => {
    if (editorData === defaultValue && isEnableSaveBtn) {
      setIsActiveSaveBtn(false)
    }
    //eslint-disable-next-line
  }, [editorData, defaultValue])

  return !isLoadingTicketDetails && !isDefaultEditorLoading ? (
    <>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name='description'
        render={({field: {value, onChange, ref}}) => (
          <JoditEditor
            ref={ref}
            // config={config}
            value={value}
            onChange={(value: any) => {
              setEditorData(value)
              onChange(value)
              setIsActiveSaveBtn(true)
            }}
          />
        )}
      />

      {hasPermission('projects_all projects', 'write') && (
        <>
          {ticketDetails.length !== 0 && isActiveSaveBtn && (
            <div className='d-flex gap-3 justify-content-end '>
              <button
                onClick={() => setIsActiveSaveBtn(false)}
                type='button'
                className='btn btn-light'
              >
                Cancel
              </button>
              {!isLoadingTicketOperation && (
                <span onClick={handleSaveEditor} className='btn btn-primary indicator-label'>
                  Save
                </span>
              )}
              {isLoadingTicketOperation && (
                <button className='btn btn-primary indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </button>
              )}
            </div>
          )}
        </>
      )}
    </>
  ) : (
    <div className='min-h-550px position-relative'>
      <Loading />
    </div>
  )
}

export {EditorComponent}
