import React  from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'
import { Link } from 'react-router-dom'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isToggleActive?: boolean
  firstChildRoute: string
}

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  isToggleActive,
  firstChildRoute
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config      
  
  return (    
      <>
        {isToggleActive ? (
        <>          
          <div                    
          className={`menu-item test-happy  menu-accordion ${isActive ? 'here show hiding' : ''}`}          
          >
          <span className='menu-link test-happy'>
          <Link  to={firstChildRoute} >
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && aside.menuIcon === 'svg' && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && aside.menuIcon === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
          </Link>
          </span>
          <div className={`menu-sub menu-sub-accordion test-happy ms-14 ${isActive ? 'menu-active-bg' : ''}`}>
            {children}
          </div>
          </div>
        </>
        ) : (
          <div
            className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
            data-kt-menu-trigger='click'
          >
          <span className='menu-link'>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && aside.menuIcon === 'svg' && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && aside.menuIcon === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
            <span className='menu-title'>{title}</span>
          </span>
           <div className={clsx('menu-sub menu-sub-accordion ms-14', {'menu-active-bg': isActive})}>
              {children}
            </div>
          </div>
        )}        
    </>
  )
}

export {AsideMenuItemWithSub}
