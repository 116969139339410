import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useContext, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {ProjectsMembersPageContext} from '../../../context'
import MultipleSelect from '../../../../../components/MultiSelect'

interface CreateProjectDialog {
  show: boolean
  onClose: () => void
  projectMemberModalDetails: any
}

const AddGlobalProjectMembersModal = ({
  show,
  onClose,
  projectMemberModalDetails,
}: CreateProjectDialog) => {
  const {handleSubmit, reset} = useForm()
  const {addGlobalProjectMember, isLoadingGlobalProjectsMembersOperation, isLoadingGlobalProjectsMembersList} = useContext(ProjectsMembersPageContext)
  const [selectedOption, setSelectedOption] = useState([])
  const {globalProjectsMembersDropdownList} = useContext(ProjectsMembersPageContext)

  useEffect(() => {
    if (isLoadingGlobalProjectsMembersList) {
      onClose()
    }
  }, [isLoadingGlobalProjectsMembersList, onClose])

  useEffect(() => {
    setSelectedOption(projectMemberModalDetails ? projectMemberModalDetails.member_id : '')
  }, [show, projectMemberModalDetails])

  if (!show) {
    return null
  }

  const handleOptionChange = (selectedMembers: any) => {
    setSelectedOption(selectedMembers)
  }

  const handleUpdateDetails = () => {
    const modifiedFields = {
      usernames: selectedOption.map((member:any)=> member.value),
    }
    addGlobalProjectMember(modifiedFields)
  }

  const isSaveButtonDisabled = selectedOption.length === 0

  return (
    <Modal
      className='modal fade'
      id='kt_modal_stacked_1'
      data-backdrop='static'
      tabIndex={-1}
      show={show}
      dialogClassName='modal-1'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>Add Member</h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <form onSubmit={handleSubmit(handleUpdateDetails)} className='modal-body'>
          <div className=''>
            <label htmlFor={`default-column-name`} className='required form-label'>
              Add members
            </label>
            <MultipleSelect
              options={[
                ...globalProjectsMembersDropdownList.map((user: any) => ({label: user.name, value: user.username})),
              ]}
              handleSelected={handleOptionChange}
            />
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-outline btn-active-light-primary'
              onClick={() => {
                onClose()
                reset()
              }}
            >
              Cancel
            </button>

            <button type='submit' className='btn btn-primary' disabled={isSaveButtonDisabled}>
              {!isLoadingGlobalProjectsMembersOperation && <span className='indicator-label'>Add</span>}

              {isLoadingGlobalProjectsMembersOperation && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default AddGlobalProjectMembersModal
