import {useContext, useState} from 'react'
import useToastify from '../../../../hook/useToastify'
import {removeMember} from '../../service'
import {ProjectsMainPageContext} from '../../context'
import {useGetMembersList} from './useGetMembersList'

export default function useRemoveProjectMember() {
  const {projectId} = useContext(ProjectsMainPageContext)
  const {toastMessage} = useToastify()
  const [isLoading, setLoading] = useState(false)
  const {refetchMembersList} = useGetMembersList()

  async function removeProjectMember(memberID: number) {
    try {
      setLoading(true)
      const response = await removeMember(`/agile/projects/${projectId}/members/${memberID}`)
      if (response.status === 200) {
        toastMessage('success', `Removed successfully`)
        refetchMembersList()
      } else {
        toastMessage('error', `${response.data.message}`)
      }
      setLoading(false)

      return response
    } catch (error: any) {
      setLoading(false)
    }
  }

  return {
    removeProjectMember,
    isRemovingProjectMember: isLoading,
  }
}
