import {useContext, useState} from 'react'
import Loading from '../../../../loading'
import DndTable from '../../../../../components/dndTable/DndTable'
import CustomFieldActionsOverlay from '../../actionsOverlayDropdowns/CustomFieldActionsOverlay'
import CustomFieldsModal from '../../modal/boardSettings/customFields/CustomFieldsModal'
import {BoardSettingsPageContext} from '../../../context'
import usePermission from '../../../../../hook/usePermission'

export default function CustomFieldsTable() {
  const {hasPermission} = usePermission()
  const {updateCustomFieldTableSorting, customFieldsList, isLoadingCustomFieldList} =
    useContext(BoardSettingsPageContext)

  const [openCustomFieldModal, setOpenCustomFieldModal] = useState(false)

  const handleUpdatedTableRows = (tableData: any) => {
    const customFieldsTableRowOrder = {
      project_custom_field_ids: tableData.map((item: any) => item.id),
    }
    updateCustomFieldTableSorting(customFieldsTableRowOrder)
  }

  const formattedData = customFieldsList?.map((cField: any) => {
    const tableData = [
      {
        columnName: 'NAME',
        headerClass: 'min-w-200px',
        columnData: cField.custom_field_name
      },
      {
        columnName: 'FIELD TYPE',
        headerClass: 'w-150px',
        columnData: cField.field_type,
      },
      {
        columnName: 'USED IN CARDS',
        headerClass: 'w-150px text-center',
        columnData: <div className='text-center'>{cField.card_counts}</div>,
      },
    ]

    if (hasPermission('projects_all projects', 'write')) {
      tableData.push({
        columnName: 'ACTION',
        headerClass: 'w-80px text-center',
        columnData: <CustomFieldActionsOverlay customFieldId={cField.id} customFieldData={cField} />
      });
    }

    return {
      id: cField.id,
      tableData: tableData,
    };
  })

  return (
    <div className='position-relative'>
      {isLoadingCustomFieldList && <Loading />}
      <DndTable
        data={formattedData}
        isLoading={isLoadingCustomFieldList}
        handleUpdatedTableRows={handleUpdatedTableRows}
        isDraggable= {hasPermission('projects_all projects', 'write')}
      />
      {hasPermission('projects_all projects', 'write') && (
        <div className='d-flex justify-content-end'>
          {!isLoadingCustomFieldList && (
            <button
              type='button'
              onClick={() => setOpenCustomFieldModal(true)}
              className='btn btn-primary'
            >
              <i className='fa-solid fa-plus'></i>Add Custom Field
            </button>
          )}
          <CustomFieldsModal
            show={openCustomFieldModal}
            customFieldData={''}
            onClose={() => setOpenCustomFieldModal(false)}
          />
        </div>
      )}
    </div>
  )
}
