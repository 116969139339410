import {useContext, useEffect, useState} from 'react'
import {TicketDetailsPageContext} from '../../../context'
import Loading from '../../../../loading'
import Select from 'react-select'
import {useForm, Controller} from 'react-hook-form'
import {DatePicker} from 'rsuite'
import {getFormattedDate} from '../../../../../utils/date'
import MultipleSelect from '../../../../../components/MultiSelect'

export default function AdditionalInformation() {
  const {
    additionalInfoCFieldsList,
    isLoadingAdditionalInfoCFieldsList,
    editTicketCFieldValue,
  }: any = useContext(TicketDetailsPageContext)

  const {setValue, control} = useForm()
  const [isFieldVisible, setIsFieldVisible] = useState(false)

  useEffect(() => {
    if (!isLoadingAdditionalInfoCFieldsList) {
      setIsFieldVisible(true)
    }
  }, [isLoadingAdditionalInfoCFieldsList, additionalInfoCFieldsList])

  const defaultValue: any = {}

  additionalInfoCFieldsList.forEach((field: any) => {
    switch (field.datatype) {
      case 'string':
        defaultValue[field.name] = field.str_value || ''
        break
      case 'group':
        if (field.is_multiselect_group) {
          defaultValue[field.name] = field.str_value
            ? field.str_value
                ?.split(',')
                .map((val: any) => ({label: val.trim(), value: val.trim()}))
            : []
        } else {
          defaultValue[field.name] = field.str_value || ''
        }
        break
      case 'integer':
        defaultValue[field.name] = field.int_value !== null ? field.int_value : ''
        break
      case 'float':
        defaultValue[field.name] = field.float_value !== null ? field.float_value : ''
        break
      default:
        break
    }
  })

  const onSubmit = (
    fieldData: any,
    fieldName: string,
    fieldId: string,
    dataType: string,
    isMultiGroup: any
  ) => {
    const formattedPayloadData: {[key: string]: any} = {}

    if (fieldData === undefined) {
      fieldData = defaultValue[fieldName]
    }

    if (dataType === 'string') {
      formattedPayloadData.str_value = fieldData
    } else if (dataType === 'integer') {
      formattedPayloadData.int_value = fieldData
    } else if (dataType === 'float') {
      formattedPayloadData.float_value = fieldData
    } else if (dataType === 'date') {
      if (getFormattedDate(fieldData) === '1970-01-01') {
        formattedPayloadData.str_value = ''
      } else {
        formattedPayloadData.str_value = getFormattedDate(fieldData)
      }
    } else if (dataType === 'group' && isMultiGroup) {
      formattedPayloadData.str_value = fieldData
        ?.map((item: {label: string; value: string}) => item.value)
        ?.join(',')
    } else if (dataType === 'group' && !isMultiGroup) {
      formattedPayloadData.str_value = fieldData
    }

    const payload = {
      project_customfield_id: fieldId,
      ...formattedPayloadData,
    }

    editTicketCFieldValue(payload)
  }

  function getFieldInput(field:any) {
    
    switch (field.datatype) {
      case 'string':
        return (
          <Controller
            key={field.name}
            control={control}
            defaultValue={field.str_value || ''}
            name={field.name}
            rules={{
              validate: (value) => typeof value === 'string',
            }}
            render={({field: {value}, fieldState}) => (
              <>
                <input
                  type='text'
                  className={`form-control ${fieldState.invalid ? 'is-invalid' : ''}`}
                  value={value}
                  onChange={(e) => setValue(field.name, e.target.value)}
                  onBlur={(e:any)=>{
                    if(e.target.value !== field.str_value){
                      onSubmit(
                        e.target.value,
                        field.name,
                        field.id,
                        field.datatype,
                        field.is_multiselect_group
                      )
                    }
                  }
                  }
                  onKeyDown={(e:any) => {
                    if (e.key === 'Enter') {
                      e.target.blur();
                    }
                  }}
                />
                {fieldState.invalid && <div className='invalid-feedback'>Invalid string value</div>}
              </>
            )
          }
          />
        )
      case 'group':
        if (field.is_multiselect_group) {
          const options = field.group_values?.split(',').map((name: any) => ({
            label: name.trim(),
            value: name.trim(),
          }))

          const defaultOption = options.filter((option: any) =>
            field.str_value
              ?.split(',')
              .map((val: any) => val.trim())
              .includes(option.value)
          )

          return (
            <Controller
              key={field.name}
              control={control}
              name={field.name}
              defaultValue={defaultOption}
              render={({field:{value}}) => (
                <MultipleSelect
                  options={options}
                  defaultValues={value}
                  handleSelected={(selectedOption: any) => {
                    setValue(field.name, selectedOption)
                    if(selectedOption !== defaultOption ){
                      onSubmit(
                        selectedOption,
                        field.name,
                        field.id,
                        field.datatype,
                        field.is_multiselect_group
                      )
                    }
                  }}
                />
              )}
            />
          )
        } else {
          const options = field.group_values?.split(',').map((name: any) => ({
            label: name.trim(),
            value: name.trim(),
          }))

          const defaultOption = options.find((option: any) => option.value === field.str_value)

          return (
            <Controller
              key={field.name}
              control={control}
              name={field.name}
              defaultValue={defaultOption}
              render={({field:{value}}) => (
                <Select
                  className={`react-select-styled`}
                  options={options}
                  defaultValue={value}
                  placeholder='Select group value'
                  onChange={(selectedOption: any) => {
                    setValue(field.name, selectedOption?.value)
                    onSubmit(
                      selectedOption?.value,
                      field.name,
                      field.id,
                      field.datatype,
                      field.is_multiselect_group
                    )
                  }}
                />
              )}
            />
          )
        }
      case 'integer':
        return (
          <Controller
            key={field.name}
            control={control}
            defaultValue={field.int_value !== null ? field.int_value : ''}
            name={field.name}
            rules={{
              validate: (value) => Number.isInteger(Number(value)) && Number(value) >= 0,
            }}
            render={({field: {value}, fieldState}) => (
              <>
                <input
                  className={`form-control ${fieldState.invalid ? 'is-invalid' : ''}`}
                  type='number'
                  value={value}
                  onChange={(e) =>
                    setValue(
                      field.name,
                      e.target.value === '' ? '' : Math.max(0, parseInt(e.target.value))
                    )
                  }
                  onBlur={(e:any)=>{
                    if(parseInt(e.target.value) !== field.int_value){
                      onSubmit(
                        e.target.value,
                        field.name,
                        field.id,
                        field.datatype,
                        field.is_multiselect_group
                      )
                    }
                  }
                  }
                  onKeyDown={(e:any) => {
                    if (e.key === '-' || e.key === 'e' || e.key === 'E' || e.key === '+') {
                      e.preventDefault()
                    }
                    if (e.key === 'Enter') {
                      e.target.blur();
                    }
                  }}
                />
                {fieldState.invalid && (
                  <div className='invalid-feedback'>Invalid integer value</div>
                )}
              </>
            )}
          />
        )
      case 'float':
        return (
          <Controller
            key={field.name}
            control={control}
            defaultValue={field.float_value !== null ? String(field.float_value) : ''}
            name={field.name}
            rules={{
              validate: (value) => {
                return (
                  value === '' ||
                  (!isNaN(parseFloat(value)) &&
                    value >= 0 &&
                    typeof value === 'string' &&
                    value.includes('.'))
                )
              },
            }}
            render={({field: {value}, fieldState}) => (
              <>
                <input
                  className={`form-control ${fieldState.invalid ? 'is-invalid' : ''}`}
                  type='number'
                  value={value}
                  onChange={(e) => {
                    const inputValue =
                      e.target.value === '' ? '' : Math.max(0, parseFloat(e.target.value))
                    if (inputValue === '' || (!isNaN(inputValue) && inputValue >= 0)) {
                      setValue(field.name, String(inputValue))
                    }
                  }}
                  onBlur={(e:any)=>{
                    if (fieldState.invalid) {
                      return;
                    }
                    if(e.target.value !== String(field.float_value)){
                      onSubmit(
                        e.target.value,
                        field.name,
                        field.id,
                        field.datatype,
                        field.is_multiselect_group
                      )
                    }
                  }
                  }
                  onKeyDown={(e:any) => {
                    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                      e.preventDefault()
                    }
                    if (e.key === 'Enter') {
                      e.target.blur();
                    }
                  }}
                />
                {fieldState.invalid && <div className='invalid-feedback'>Invalid float value</div>}
              </>
            )}
          />
        )
      case 'date':
        return (
          <Controller
            key={field.name}
            control={control}
            defaultValue={field.str_value !== null ? new Date(field.str_value) : null}
            name={field.name}
            rules={{
              validate: (value) => !isNaN(new Date(value).getTime()),
            }}
            render={({field:{value}, fieldState}) => (
              <>
                <DatePicker
                  className={`d-flex z-index-0 position-relative ${
                    fieldState.invalid ? 'is-invalid' : ''
                  }`}
                  placement='auto'
                  menuClassName='agile-board-datePicker'
                  format='yyyy-MM-dd'
                  defaultValue={value}
                  onChange={(selectedDate)=>{
                    onSubmit(
                      selectedDate,
                      field.name,
                      field.id,
                      field.datatype,
                      field.is_multiselect_group
                    )
                  }}
                />
                {fieldState.invalid && <div className='invalid-feedback'>Invalid date value</div>}
              </>
            )}
          />
        )
      default:
        return null
    }
  }

  return (
    <form className='position-relative'>
      {!isFieldVisible ? (
        <Loading />
      ) : (
        <div className='w-100'>
          {additionalInfoCFieldsList.map((field: any) => (
            <div className='d-flex mb-4' key={field.id}>
              <div className='min-w-180px d-flex align-items-center mw-150px fw-semibold'>
                <label htmlFor={field.name}>{field.name}</label>
              </div>
              <div className='w-100'>
                <div className='w-100'>{getFieldInput(field)}</div>         
              </div>
            </div>
          ))}
          {additionalInfoCFieldsList.length === 0 && <div>No Data Found...</div>}
        </div>
      )}
    </form>
  )
}
