import {useQuery} from 'react-query'
import {getAllColumns} from '../../service'
import {dataToKanbanStagesOptions} from '../../utils/agile-board'
import { useParams } from 'react-router-dom'

const useGetKanbanStages = () => {

  const params = useParams()
  const projectId = params?.['projectId'] as string


  const {
    data: kanbanstages,
    isFetching,
    refetch,
  } = useQuery(`get-all-kanban-stages`, () => {
    return getAllColumns(projectId, {})
  })

  return {
    kanbanstages: kanbanstages?.['data']?.length
      ? dataToKanbanStagesOptions(kanbanstages['data'])
      : [],
    isLoadingKanbanStage: isFetching,
    refetchKanbanStage: refetch,
  }
}

export default useGetKanbanStages
