import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import useMeta from '../../hook/useMeta'
import ProjectsSettingsPageNavigation from './components/ProjectsSettingsPageNavigation'
// import ProjectsMembers from './pages/ProjectsMembers'
import ProjectsCustomFields from './pages/ProjectsCustomFields'
import ProjectsDefaultColumns from './pages/ProjectsDefaultColumns'
import ProjectsMembers from './pages/ProjectsMembers'
// import ProjectsSystemFields from './pages/ProjectsSystemFields'

const ProjectSettingsPageCrumbs: Array<PageLink> = [
  {
    title: 'Projects',
    path: '/projects',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Settings',
    path: 'projects/settings',
    isSeparator: false,
    isActive: false,
  },
]

function ProjectsSettingsPage() {
  useMeta('Projects Settings')

  return (
    <Routes>
      <Route
        element={
          <>
            <ProjectsSettingsPageNavigation />
            <Outlet />
          </>
        }
      >
        <Route
          path='/members'
          element={
            <>
              <PageTitle breadcrumbs={ProjectSettingsPageCrumbs}>Members</PageTitle>
              <ProjectsMembers/>
            </>
          }
        />

        <Route
          path='/custom-fields'
          element={
            <>
              <PageTitle breadcrumbs={ProjectSettingsPageCrumbs}>Custom Fields</PageTitle>
              <ProjectsCustomFields />
            </>
          }
        />

        <Route
          path='/default-columns'
          element={
            <>
              <PageTitle breadcrumbs={ProjectSettingsPageCrumbs}>Default Columns</PageTitle>
              <ProjectsDefaultColumns/>
            </>
          }
        />

        {/* <Route
          path='/system-fields'
          element={
            <>
              <PageTitle breadcrumbs={ProjectSettingsPageCrumbs}>Ststem Fields</PageTitle>
              <ProjectsSystemFields/>
            </>
          }
        /> */}

        <Route index element={<Navigate to='/projects/settings/members' />} />
      </Route>
    </Routes>
  )
}

export default ProjectsSettingsPage
