import {useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import DeleteModuleModal from '../modal/boardSettings/modules/DeleteModuleModal'
import ModuleModal from '../modal/boardSettings/modules/ModuleModal'

const ModuleActionsOverlay = ({moduleId, moduleData}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rowId, setRowId] = useState(moduleId || null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleEditClick = () => {
    setRowId(moduleId)
    setIsModalOpen(true)
    document.body.click()
  }

  const handleDeleteClick = () => {
    setRowId(moduleId)
    setIsDeleteModalOpen(true)
    document.body.click()
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <div className=' d-flex align-items-start flex-column'>
            <span className='btn btn-active-text-primary' onClick={() => handleEditClick()}>
              Edit
            </span>
            <span onClick={() => handleDeleteClick()} className='btn btn-active-text-danger'>
              Delete
            </span>
          </div>
        }
      />

      <ModuleModal
        show={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setRowId(null)
        }}
        editId={rowId}
        moduleData={moduleData}
      />

      <DeleteModuleModal
        show={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false)
          setRowId(null)
        }}
        deleteModuleId={rowId}
      />
    </>
  )
}

export default ModuleActionsOverlay
