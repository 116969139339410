import { useState, useEffect } from 'react';

const Accordion = ({ items, defaultOpen }: any) => {
  const [activeIds, setActiveIds] = useState<string[]>([]);

  useEffect(() => {
    if (!items || items.length === 0) {
      return;
    }

    if (defaultOpen === 'All') {
      setActiveIds(items.map((item:any) => item.id));
    } else if (defaultOpen === false) {
      setActiveIds([]);
    } else if (Array.isArray(defaultOpen)) {
      setActiveIds(defaultOpen.map(String));
    }
  }, [defaultOpen, items]);

  const handleToggle = (id: string) => {
    if (activeIds.includes(id)) {
      setActiveIds(activeIds.filter((i) => i !== id));
    } else {
      setActiveIds([...activeIds, id]);
    }
  };

  return (
    <div className='accordion'>
      {!items || items.length === 0 ? (
        <div className='text-danger'>No data found...</div>
      ) : (
        items.map((item:any) => (
          <div key={item.id} className='accordion-loop-item'>
            <h2 className='accordion-header'>
              <button
                className={`d-flex align-items-center accordion-button p-5 text-dark fw-bold fs-4 ${
                  activeIds.includes(item.id) ? '' : 'collapsed'
                }`}
                type='button'
                onClick={() => handleToggle(item.id)}
              >
                <span>{item.title}</span> 
                {item.isLoading && <span className='text-primary spinner-border spinner-border-sm align-middle ms-2'></span>}
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${activeIds.includes(item.id) ? 'show' : ''}`}
            >
              <div className='accordion-body'>
                <div>{item.content}</div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Accordion;
