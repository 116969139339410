import usePermission from "../../../../../hook/usePermission"
import { formatDate } from "../../../../../utils/date"
import ProjectsMemberOverlayModal from "../../actionOverlayDropdown/ProjectsMemberOverlayModal"

const ProjectsMemberPageTableRow = ({row}: any) => {
  const {hasPermission} = usePermission()

  return (
    <tr>
      <td>{row['name']}</td>
      <td>{row['usage_count']}</td>
      <td>{formatDate(row['created_at'], false)}</td>
      <td className='text-center'>{'-'}</td>
      {hasPermission('projects_settings', 'write') && (
        <td>
          <div className='align-items-center'>
            <ProjectsMemberOverlayModal projectMemberDetails={row} />
          </div>
        </td>
      )}
    </tr>
  )
}

export default ProjectsMemberPageTableRow
