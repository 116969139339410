import {useEffect} from 'react'
import useFilters from '../../../../hook/useFilters'
import {useQuery} from 'react-query'
import {getAllGlobalCustomFields} from '../../services'

const initialFilters = {
  page: 1,
  limit: 10,
  search_value: '',
}

const useGetGlobalCustomFieldsList = () => {
  const {filters, setSingleFilter, setMultipleFilters} = useFilters(initialFilters)

  const {
    data: globalCustomFieldList,
    isFetching,
    refetch,
  } = useQuery(`get-all-global-custom-fields`, () => {
    return getAllGlobalCustomFields(filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onSearch = (e: string) => {
    setMultipleFilters({
      search_value: e,
      page: 1,
    })
  }

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  return {
    globalCustomFieldsList: globalCustomFieldList?.['data'] ? globalCustomFieldList['data'] : [],
    isLoadingGlobalCustomFieldList: isFetching,
    refetchGlobalCustomFieldsList: refetch,
    pagination: globalCustomFieldList?.['meta']?.['pagination'] || {},
    onSearch,
    onPageChange,
  }
}

export default useGetGlobalCustomFieldsList
