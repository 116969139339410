import {useState} from 'react'
import useToastify from '../../../hook/useToastify'
import {useAppSelector} from '../../../redux/useTypedSelector'
import {submitUpdatedProjectDetails} from '../service'
import {useGetProjectsList} from './useGetProjectsList'

export default function useUpdateProjectDetails() {
  const {user} = useAppSelector((state: any) => state.auth)
  const {toastMessage} = useToastify()
  const [isLoading, setLoading] = useState(false)
  const {refetchProjectsList} = useGetProjectsList()

  async function updateProjectDetails(updatedProjectDetails: any, projectId: number) {
    updatedProjectDetails = {
      ...updatedProjectDetails,
      updated_by: user['username'],
    }

    try {
      setLoading(true)
      const response = await submitUpdatedProjectDetails(
        `/agile/projects/${projectId}`,
        updatedProjectDetails
      )
      if (response.status === 200) {
        toastMessage('success', `Updated successfully`)
        setLoading(false)
        refetchProjectsList()
      } else {
        toastMessage('error', `${response.data.message}`)
        setLoading(false)
      }
      return response
    } catch (error: any) {
      setLoading(false)
    }
  }

  return {
    updateProjectDetails,
    isUpdating: isLoading,
  }
}
