import {useEffect} from 'react'
import useFilters from '../../../../hook/useFilters'
import {useQuery} from 'react-query'
import {getMember} from '../../service'
import { useParams } from 'react-router-dom'

export const useGetMembersList = () => {

  const params = useParams()
  const projectId = params?.['projectId']

  const initialFilters = {
    page: 1,
    limit: 10,
    search_value: '',
  }

  const {filters, setSingleFilter} = useFilters(initialFilters)

  const {
    data: projectMembersList,
    isFetching,
    refetch,
  } = useQuery(`get-project-members-list`, () => {
    return getMember(`agile/projects/${projectId}/members`, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onSearch = (e: string) => {
    setSingleFilter('search_value', e)
  }

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  return {
    projectMembersList: projectMembersList?.['data'] || [],
    isLoadingMemberDetails: isFetching,
    pagination: projectMembersList?.['meta']?.['pagination'] || {},
    onPageChange,
    filters,
    onSearchMember: onSearch,
    refetchMembersList: refetch,
  }
}
