import {useState} from 'react'
import {MemberPageSearchComponent} from './MemberPageSearchComponent'
import ProjectsMembersModal from '../modal/member/AddProjectMembersModal'
import usePermission from '../../../../hook/usePermission'

const MemberPageHeader = () => {
  const {hasPermission} = usePermission()
  const [openMemberModal, setOpenMemberModal] = useState(false)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <MemberPageSearchComponent />
      {hasPermission('projects_all projects', 'write') && (
        <button type='button' onClick={() => setOpenMemberModal(true)} className='btn btn-primary'>
          <i className='fa-solid fa-plus'></i>Add Member
        </button>
      )}

      <ProjectsMembersModal
        projectMemberModalDetails={null}
        show={openMemberModal}
        onClose={() => setOpenMemberModal(false)}
      />
    </div>
  )
}

export {MemberPageHeader}
