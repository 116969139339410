import * as yup from 'yup'

export const addUserValidationSchema = yup.object().shape({
  name: yup.string().required('Please provide a Name'),
  username: yup.string().email('Invalid email address').required('Please Provide an Email'),
  password: yup.string().min(6).required('Please Provide a Password'),
  confirm_password: yup
    .string()
    .required('Please confirm you Password')
    .oneOf([yup.ref('password')], 'Passwords must match'),
  role_id: yup.string().required('Please Select a Role'),
  status: yup.string().required('Please Select a Status'),
})

export const editUserValidationSchema = yup.object().shape({
  name: yup.string().required('Please provide a Name'),
  username: yup.string().email('Invalid email address').required('Please Provide an Email'), 
  role_id: yup.string().required('Please Select a Role'),
  status: yup.string().required('Please Select a Status'),
})

export const roleValidationSchema = yup.object().shape({
  role: yup.string().required('Please provide a role name.').min(3),
  permissions: yup
    .array()
    .of(
      yup.object().shape({
        feature_id: yup.string().required('Feature ID is required.'),
        operation: yup.array().of(yup.string()).min(1, 'Please select at least one operation.'),
      })
    )
    .test('at-least-one-permission', 'Please select at least one Permission', (permissions: any) =>
      permissions?.some((permission: any) => permission.operation.length > 0)
    ),
})


export const resetPasswordValidationSchema = yup.object().shape({
  password: yup.string().min(6).required('Please Provide a Password'),
  confirm_password: yup
    .string()
    .required('Please confirm you Password')
    .oneOf([yup.ref('password')], 'Passwords must match'),
})
