import {Pagination} from '../../../utils/pagination'
import {ActivePageHeader} from '../components/header/ActivePageHeader'
import ActiveProjectsTable from '../components/table/ActiveProjectsTable'
import {ProjectsListPageContext} from '../context/index'
import {useGetBusinessUnitsList} from '../hooks/useGetBusinessUnitsList'
import {useGetProjectsList} from '../hooks/useGetProjectsList'
import useUpdateProjectDetails from '../hooks/useUpdateProjectDetails'
import useCreateProject from '../hooks/useCreateProject'
import useAddToFavorites from '../hooks/useAddToFavorites'

function Active() {
  const {
    projectsList,
    isLoading,
    pagination,
    onSearch,
    onPageChange,
    filters,
    refetchProjectsList,
    onClickSorting,
  } = useGetProjectsList()
  const {businessUnitsList, isLoadingBusinessUnits} = useGetBusinessUnitsList()
  const {createNewProjectDetails, isCreating} = useCreateProject()
  const {updateProjectDetails, isUpdating} = useUpdateProjectDetails()
  const {addProjectToFavorite, isAdding} = useAddToFavorites()

  const contextValue = {
    projectsList,
    isLoading,
    isCreating,
    isUpdating,
    filters,
    businessUnitsList,
    isLoadingBusinessUnits,
    onSearch,
    updateProjectDetails,
    createNewProjectDetails,
    refetchProjectsList: refetchProjectsList,
    onClickSorting,
    addProjectToFavorite,
    isAdding,
  }

  return (
    <>
      <ProjectsListPageContext.Provider value={contextValue}>
        <ActivePageHeader />
        <ActiveProjectsTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </ProjectsListPageContext.Provider>
    </>
  )
}

export default Active
