import {useContext, useState} from 'react'
import ProjectsDetailsModal from '../modal/ProjectsDetailsModal'
import {ActiveProjectsSearchComponent} from './ActiveProjectsSearchComponent'
import { ProjectsListPageContext } from '../../context'
import usePermission from '../../../../hook/usePermission'

const ActivePageHeader = () => {
  const {hasPermission} = usePermission()
  const [createProjectModal, setCreateProjectModal] = useState(false)
  const {isAdding} = useContext(ProjectsListPageContext)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div className='d-flex align-items-center'>
        <ActiveProjectsSearchComponent />
        {isAdding && (
          <button className='p-3 border-0 outline-0 h-100 d-flex justify-content-center align-items-center bg-light rounded rounded-2'>
            <span className='text-primary spinner-border spinner-border-sm align-middle'></span>
          </button>
        )}
      </div>
      {hasPermission('projects_all projects', 'write') && (
        <button
          type='button'
          onClick={() => setCreateProjectModal(true)}
          className='btn btn-primary'
        >
          <i className='fa-solid fa-plus'></i>Create Projects
        </button>
      )}

      <ProjectsDetailsModal
        projectModalDetails={null}
        modalTitle={'Create Project'}
        show={createProjectModal}
        onClose={() => setCreateProjectModal(false)}
      />
    </div>
  )
}

export {ActivePageHeader}
